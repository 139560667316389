import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../../shared/auth/auth.service';
import {ToastrService} from 'ngx-toastr';
import {LocalStorageService} from '../../../../shared/storage/local-storage.service';

const moment = require('moment');

@Component({
  selector: 'app-mails-from-pharmacy',
  templateUrl: './mails-from-pharmacy.component.html',
  styleUrls: ['./mails-from-pharmacy.component.scss']
})
export class MailsFromPharmacyComponent implements OnInit {

  messages: any = [];
  pharmacyList: any = [];
  userData: any;
  platform: any;
  selectedPharmacy: any;
  notes: any = [];

  constructor(private authService: AuthService, private toastr: ToastrService, private storage: LocalStorageService) {
  }

  async ngOnInit(): Promise<void> {
    this.storage.getData('script-capture').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.platform = JSON.parse(sessionStorage.getItem('platform'));
    this.authService.fetch_pharmacy_platformId(this.userData, this.platform).then((resp: any) => {
      this.pharmacyList = resp.result
    })
  }

  SelectPharmacy(event: any) {
    this.selectedPharmacy = event.target.value;
    this.authService.fetch_verification_note(this.userData, this.selectedPharmacy).then((resp: any) => {
      if (resp.status === 404) {
        this.toastr.clear();
        this.toastr.error('No messages available', 'Message', {
          positionClass: 'toast-top-center'
        });
        this.notes = [];
        return;
      }
      if (resp.activeNotes.length === 0) {
        this.toastr.clear();
        this.toastr.error('No messages available', 'Messagel', {
          positionClass: 'toast-top-center'
        });
        this.notes = [];
        return;
      }
      this.toastr.clear();
      this.notes = resp.activeNotes.filter((data) => {
        data.convertedTimeStamp = moment(data.timeStamp).format('LT');
        return data;
      });
    })
  }
}
