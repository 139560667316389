import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'monthFormat'
})
export class MonthFormatPipe implements PipeTransform {

  transform(value: any): string {
    // {year: 2023, month: 3, day: 23}
    const wholeDate = value;
    wholeDate.month = wholeDate?.month - 1;
    const newDate = wholeDate;
    return newDate;
  }

  reduceMonth(value: any): string {
    // 2023-05-25T13:00:00.000Z
    const wholeDate = value.split('-');
    wholeDate[1] = wholeDate[1] - 1;
    if (wholeDate[1] < 10) {
      wholeDate[1] = '0' + wholeDate[1].toString();
    }
    const newDate = wholeDate[0] + '-' + wholeDate[1] + '-' + wholeDate[2];
    return newDate;

  }
}
