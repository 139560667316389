import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from '../../../../shared/auth/auth.service';
import {ToastrService} from 'ngx-toastr';
import {LocalStorageService} from '../../../../shared/storage/local-storage.service';

@Component({
  selector: 'app-product-page',
  templateUrl: './product-page.component.html',
  styleUrls: ['./product-page.component.scss']
})
export class ProductPageComponent implements OnInit {


  globalProdselected: any;
  userData: any;
  platform: any;
  showBox = false;
  category = [];
  labelNames = [];
  properties = [];
  error = '';
  //filteredResponse = '';
  externalLabel: any;
  productId = '';
  internalLabels = [
    //'price_per_mg',
    'originalName',
    'brandName',
    'size_measure', 'originalCost',
    'productType', 'TGAProductCategory', 'THCLabel', 'CBDLabel',
    'productSchedule', 'category', 'productSpecies', 'aromas', 'description', 'originalData', 'uploadProductInfo',
    'uploadProductInfo', 'productImage'
  ];


  constructor(private activatedRoute: ActivatedRoute,
              private storage: LocalStorageService,
              private auth: AuthService, private toastr: ToastrService) {
  }

  async ngOnInit(): Promise<void> {
    this.storage.getData('script-capture').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.platform = JSON.parse(sessionStorage.getItem('platform'));
    this.activatedRoute.queryParams.subscribe((data: any) => {
      this.productId = JSON.parse(data.data);
      this.auth.fetch_particularglobalProdcut(this.userData, JSON.parse(data.data), this.platform, false)
        .then(async (response: any) => {
          if (response.status === 400) {
            this.error = response.error.message;
            return;
          }
          this.error = '';
          this.globalProdselected = response.normalResponse[0];
          console.log(this.globalProdselected)
          for (const property in this.globalProdselected) {
            if (!this.internalLabels.includes(property)) {
              this.externalLabel = {...this.externalLabel, [property]: this.globalProdselected[property].trim()}
            }
            if (!this.internalLabels.includes('uploadProductInfo')) {
              this.externalLabel = {...this.externalLabel, ['Additional Resources']: this.globalProdselected['uploadProductInfo'].trim()}
            }
          }
        })
    })
  }

  downloadFiles(file, index) {
    const download = document.createElement('a') as HTMLAnchorElement;
    // Set the href and download attributes
    download.setAttribute('href', file.url);
    download.setAttribute('download', file.fileName); // Optional: specify a filename
    // Trigger the download by clicking the anchor tag programmatically
    download.click();
  }

  deleteFile(url: any) {
    this.auth.deleteFile(this.userData, url, {_id: this.productId}).then(async (response: any) => {
      this.toastr.clear();
      this.toastr.error('File deleted',
        'Error', {
          positionClass: 'toast-top-center'
        });
      await this.auth.fetch_particularglobalProdcut(this.userData, this.productId, this.platform, false).then((resp: any) => {
        if (resp.status === 400) {
          this.error = resp.error.message;
          return;
        }
        this.error = '';
        this.globalProdselected = resp.normalResponse[0];
      })
    })
  }

  hasProductType(property): boolean {
    return this.globalProdselected && property in this.globalProdselected;
  }

  checkExternal_label_length() {
    return Object.keys(this.externalLabel).length > 0;
  }

}
