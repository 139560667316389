import {Component, ViewContainerRef, OnInit, OnDestroy, HostListener} from '@angular/core';
import {Subscription} from 'rxjs';
import {Router, NavigationEnd} from '@angular/router';
import {filter} from 'rxjs/operators';
import {AuthService} from './shared/auth/auth.service';
import {TimeActivityService} from './shared/storage/time-activity.service';
import {BrowserUrl} from './BrowserUrl';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

  subscription: Subscription;

  constructor(private router: Router, private authService: AuthService,
              private inactivityTimer: TimeActivityService) {
  }


  ngOnInit() {

    this.subscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => window.scrollTo(0, 0));
    if (JSON.parse(sessionStorage.getItem('platform'))){
      const root = document.documentElement;
      root.style.setProperty('--primary-color', JSON.parse(sessionStorage.getItem('platform')).primaryColour);
      root.style.setProperty('--secondary-color', JSON.parse(sessionStorage.getItem('platform')).secondaryColour);
    }

  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  @HostListener('window:mousemove') onMouseMove() {
    this.inactivityTimer.resetTimer();
  }

  @HostListener('window:click') onClick() {
    this.inactivityTimer.resetTimer();
  }

  @HostListener('window:keypress') onKeyPress() {
    this.inactivityTimer.resetTimer();
  }

}
