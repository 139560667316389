<div class="row">
  <div class="col-sm-12 col-12 col-md-12 col-lg-12">
    <div class="content-header">Place Order</div>
  </div>
</div>
<section id="ngx">
  <div class="card">
    <div class="card-body">
      <aw-wizard #wizard [navBarLayout]="'large-empty-symbols'">
        <aw-wizard-step [stepTitle]="'Step 1'" [navigationSymbol]="{ symbol: '&#xf19c;', fontFamily: 'FontAwesome' }">
          <form>
            <h4 class="head text-center">Select Pharmacy </h4>
            <p class="font-italic font-small-4 text-center">Once selected, our system will verify if the pharmacy
              carries a product matching your script. <br>
              Delivery and Pickup Options Available</p>
            <br/>
            <div class="row" *ngIf="orderBy === 'Customer'">
              <div class="col-12 col-lg-12 col-ms-13 col-sm-12">
                <div class="row mt-2">
                  <div class="col-md-4 col-lg-4 col-sm-12 col-12">
                    <ul class="list-unstyled">
                      <li class="d-inline-block mr-2">
                        <div class="checkbox">
                          <input type="checkbox" [checked]="searchByPharmacty" id="verified">
                          <label for="verified"><span>VERIFIED PHARMACIES</span>
                          </label> <span class="cursor-pointer" (click)="collapse2 = !collapse2"
                                         [attr.aria-expanded]="!collapse2" aria-controls="infoPart">
                      <i class="ft-info text-primary ml-1"></i>
                    </span>
                        </div>
                      </li>
                    </ul>
                    <p class="font-italic font-small-4" id="infoPart"
                       [ngbCollapse]="collapse2">
                      To select a new pharmacy for ID verification use the State Search function.
                    </p>
                    <ng-select class="mt-1" [items]="pharmacyList" [clearable]="false"
                               [(ngModel)]="pharmacySelected" [ngModelOptions]="{standalone: true}"
                               (change)="getPharmacy($event)"
                               bindLabel="pharmacy_name" bindValue="pharmacyId" placeholder="Select Pharmacy">
                    </ng-select>
                  </div>
                  <div class="col-md-4 col-lg-4 col-sm-12 col-12 text-center pt-3">
                    <p class="font-weight-bold">OR</p>
                  </div>
                  <div class="col-md-4 col-lg-4 col-sm-12 col-12">
                    <label><span>SEARCH BY STATE</span></label>
                    <ng-select class="mt-1" (change)="OnChangeState($event)" [(ngModel)]="selectedState"
                               [ngModelOptions]="{standalone: true}" [clearable]="false"
                               placeholder="Select State">
                      <ng-option *ngFor="let state of states" [value]="state">{{ state }}</ng-option>
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>


            <div class="table-responsive d-none d-xl-block d-lg-block d-md-block d-sm-none pt-2"
                 *ngIf="fetchedPharmacies.length > 0">
              <table class="table m-0">
                <thead class="thead-light">
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Address</th>
                  <th>Delivery Type</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let pharmacy of fetchedPharmacies;let i = index;">
                  <th scope="row">{{ i + 1 }}</th>
                  <td class="fixed-width">
                    {{ pharmacy.pharmacy_name }}
                    <span class="badge float-right mr-2"
                          [ngClass]="{'badge-success' : pharmacy.colorCode === 'success','badge-danger' : pharmacy.colorCode === 'danger',
                            'badge-warning' : pharmacy.colorCode === 'warning'}">
                        {{ pharmacy.availableProducts }}</span>
                    <div id="{{pharmacy.pharmacyId}}" [ngbCollapse]="pharmacy.collapse" class="collapse-content">
                      <div class="table-responsive">
                        <table class="table table-bordered mt-2">
                          <tbody>
                          <tr *ngFor="let medication of pharmacy.medicationAvailable;let i = index;">
                            <td *ngIf="medication?.matchedScript?.showRow">
                                 <span
                                   class="ft-trash cursor-pointer text-danger float-right"
                                   ngbTooltip="Remove Product" placement="top"
                                   (click)="removeProductSelected(medication.matchedScript.resetDropdown,medication.matchedScript)"></span>
                              <div class="row">
                                <div class="col-md-7 col-lg-6 col-12">
                                  <div class="text-capitalize">
                                    <b>Script: </b> {{
                                      medication?.matchedScript?.product_name === '' ? medication?.matchedScript?.product_brand_name :
                                        medication?.matchedScript?.product_name.includes(medication?.matchedScript?.medication_strength)
                                          ? medication?.matchedScript?.product_name : medication?.matchedScript?.product_name + ' ' + medication?.matchedScript?.medication_strength
                                    }}
                                    <span class="badge cursor-pointer bg-light-info"
                                          *ngIf="!medication?.matchedScript?.showdropdown"
                                    >Partial Match</span>
                                    <i class="ft-info text-primary cursor-pointer ml-1"
                                       *ngIf="!medication?.matchedScript?.showdropdown" (click)="collapse = !collapse"
                                       [attr.aria-expanded]="!collapse" aria-controls="partialMatch"></i><br>

                                    <p class="font-italic font-small-3" id="partialMatch"
                                       [ngbCollapse]="collapse && !medication?.matchedScript?.showdropdown">
                                      {{
                                        !medication?.matchedScript?.showdropdown ? 'No exact match found for your medication, but there\'s a potential match. Please review and confirm.' : ''
                                      }}
                                    </p>
                                    <span *ngIf="medication?.matchedScript?.showdropdown"
                                          class="badge bg-light-success">Match</span>
                                  </div>
                                </div>
                                <div class="col-md-5 col-lg-6 col-12">
                                  <p
                                    *ngIf="!medication?.matchedScript?.showdropdown && medication.matchedScript.resetDropdown">
                                    <b>
                                      Selected Product: </b> {{ medication.matchedScript.resetDropdown }}
                                    ${{ medication.matchedScript.prodPrice }}
                                  </p>
                                  <p
                                    *ngIf="medication?.matchedScript?.showdropdown">
                                    <b>
                                      Selected Product : </b> {{
                                      medication?.matchedScript?.product_name === '' ? medication?.matchedScript?.product_brand_name :
                                        medication?.matchedScript?.product_name.includes(medication?.matchedScript?.medication_strength)
                                          ? medication?.matchedScript?.product_name : medication?.matchedScript?.product_name + ' ' + medication?.matchedScript?.medication_strength
                                    }}
                                  </p>
                                  <p
                                    *ngIf="medication?.matchingResponseOfMedicationFound[0].availableStock <= 0 && medication?.matchingResponseOfMedicationFound.length === 1">
                                    {{ medication?.matchingResponseOfMedicationFound[0].pharmacyProductName }}
                                    <span class="badge"
                                          [ngClass]="medication?.matchingResponseOfMedicationFound[0].availableStock <= 0 && pharmacy.pharmacyDetails?.acceptBackOrders ? 'bg-light-warning' : 'bg-light-danger'"
                                    >
                                  {{ medication?.matchingResponseOfMedicationFound[0].availableStock <= 0 && pharmacy.pharmacyDetails?.acceptBackOrders ? 'Back order' : 'Out of Stock' }}

                                </span>
                                  </p>
                                </div>
                              </div>

                              <ng-select
                                *ngIf="medication?.matchingResponseOfMedicationFound.length > 0 && !medication?.matchedScript?.showdropdown && medication?.matchedScript?.showRow"
                                class="mt-2" [items]="medication?.matchingResponseOfMedicationFound"
                                [(ngModel)]="medication.matchedScript.resetDropdownId" [clearable]="false"
                                [ngModelOptions]="{standalone: true}"
                                (change)="OnProductChange($event,medication?.matchedScript,medication?.matchingResponseOfMedicationFound,pharmacy.medicationNotAvailable,i,pharmacy)"
                                bindLabel="pharmacyProductName" bindValue="pharmacyProductId"
                                placeholder="Select product">
                                <ng-template ng-option-tmp let-item="item" let-index="index">
                                  <p>{{ item.pharmacyProductName }}
                                    <span class="float-right font-weight-bold">{{ '$' + item.productCost }}</span>
                                    <span class="badge mr-3 float-right" *ngIf="!item.isDiscontinued"
                                          [ngClass]="item.availableStock <= 0 && pharmacy.pharmacyDetails?.acceptBackOrders ? 'bg-light-warning' :
                                          item.availableStock <= 0 && !pharmacy.pharmacyDetails?.acceptBackOrders ?
                                            'bg-light-danger' : 'bg-light-success' ">
                                      {{
                                        item.availableStock <= 0 && pharmacy.pharmacyDetails?.acceptBackOrders ? 'Back order' :
                                          item.availableStock <= 0 && !pharmacy.pharmacyDetails?.acceptBackOrders ?
                                            'Out of stock' : 'In-stock'
                                      }}
                                    </span>
                                    <span class="badge mr-3 float-right bg-light-secondary" *ngIf="item.isDiscontinued">
                                      Discontinued
                                    </span>
                                  </p>
                                </ng-template>
                              </ng-select>
                            </td>
                          </tr>
                          <tr *ngFor="let medication of pharmacy.medicationNotAvailable">
                            <td *ngIf="medication.showRow">
                               <span
                                 class="ft-trash cursor-pointer text-danger float-right"
                                 (click)="removeUnmatchedScript(medication,pharmacy)"
                                 ngbTooltip="Remove Product" placement="top"></span>
                              <div class="row">
                                <div class="col-md-12 col-lg-12 col-12 col-sm-12">
                                  <div class="text-capitalize">
                                    <b>Script
                                      : </b>
                                    {{
                                      medication.product_name === '' ? medication.product_brand_name :
                                        medication.product_name.includes(medication?.medication_strength)
                                          ? medication.product_name : medication.product_name + ' ' + medication?.medication_strength
                                    }}
                                    <br>
                                    <span class="badge bg-light-danger cursor-pointer">Unmatched</span><i
                                    class="ft-info text-primary cursor-pointer ml-1"
                                    *ngIf="!medication?.matchedScript?.showdropdown" (click)="collapse1 = !collapse1"
                                    [attr.aria-expanded]="!collapse1" aria-controls="unMatch1"></i><br>

                                    <p class="font-italic font-small-3" id="unMatch1"
                                       [ngbCollapse]="collapse1">
                                      We couldn’t match a medication. The pharmacy will review your script, select the
                                      correct option, and seek your approval before dispensing.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </td>
                  <td>{{ pharmacy.pharmacyDetails?.address + ' ' + pharmacy.pharmacyDetails?.suburb + ' ' + pharmacy.pharmacyDetails?.state + ' ' + pharmacy.pharmacyDetails?.postcode }}</td>
                  <td>
                    <span *ngIf="pharmacy.pharmacy_delivery_type === 'delivery'">Delivery</span>
                    <span *ngIf="pharmacy.pharmacy_delivery_type === 'pickup'">Pickup</span>
                    <div class="flex" *ngIf="pharmacy.pharmacy_delivery_type === 'both'">
                      <span>Delivery & Pickup</span></div>
                  </td>
                  <td>
                    <button class="btn" (click)="SelectedPharmacy(pharmacy,i)"
                            [ngClass]="this.index === i ? 'btn secondary-btn' : 'btn primary-btn'"
                    >Select
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <!--              Mobile view-->
            <div class="card-container d-sm-block d-lg-none d-xl-none d-md-none row pt-2"
                 *ngIf="fetchedPharmacies.length > 0">
              <div
                class="card mb-3 col-sm-12 col-12"
                style="box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.05);"
                *ngFor="let pharmacy of fetchedPharmacies; let i = index">
                <div class="card-header d-flex justify-content-between align-items-center">
                  <h5>{{ pharmacy.pharmacy_name }}</h5>
                </div>
                <div class="card-body">
                    <span class="badge float-right mr-2"
                          [ngClass]="{'badge-success' : pharmacy.colorCode === 'success','badge-danger' : pharmacy.colorCode === 'danger',
                            'badge-warning' : pharmacy.colorCode === 'warning'}">
                        {{ pharmacy.availableProducts }}</span>
                  <p>
                    <strong>Address:</strong>
                    {{ pharmacy.pharmacyDetails?.address + ' ' + pharmacy.pharmacyDetails?.suburb + ' ' + pharmacy.pharmacyDetails?.state + ' ' + pharmacy.pharmacyDetails?.postcode }}
                  </p>
                  <p>
                    <strong>Delivery Type: </strong>
                    <span *ngIf="pharmacy.pharmacy_delivery_type === 'delivery'">Delivery</span>
                    <span *ngIf="pharmacy.pharmacy_delivery_type === 'pickup'">Pickup</span>
                    <span *ngIf="pharmacy.pharmacy_delivery_type === 'both'">Delivery & Pickup</span>
                  </p>
                  <button
                    class="btn btn-block"
                    (click)="SelectedPharmacy(pharmacy, i)"
                    [ngClass]="this.index === i ? 'btn secondary-btn' : 'btn primary-btn'">
                    Select
                  </button>

                  <div id="{{pharmacy.pharmacyId}}" [ngbCollapse]="pharmacy.collapse" class="collapse-content">
                    <div class="row" *ngFor="let medication of pharmacy.medicationAvailable;let i = index;">
                      <div class="col-sm-12 col-12 pt-1" *ngIf="medication?.matchedScript?.showRow">
                            <span class="ft-trash cursor-pointer text-danger float-right" ngbTooltip="Remove Product"
                                  placement="top"
                                  (click)="removeProductSelected(medication.matchedScript.resetDropdown,medication.matchedScript)"></span>
                        <div class="text-capitalize">
                          <b>Script
                            : </b> {{
                            medication?.matchedScript?.product_name === '' ? medication?.matchedScript?.product_brand_name :
                              medication?.matchedScript?.product_name.includes(medication?.matchedScript?.medication_strength)
                                ? medication?.matchedScript?.product_name : medication?.matchedScript?.product_name + ' ' + medication?.matchedScript?.medication_strength
                          }}
                          <span class="badge cursor-pointer bg-light-info"
                                *ngIf="!medication?.matchedScript?.showdropdown"
                          >Partial Match</span>
                          <i class="ft-info text-primary cursor-pointer ml-1"
                             *ngIf="!medication?.matchedScript?.showdropdown" (click)="collapse = !collapse"
                             [attr.aria-expanded]="!collapse" aria-controls="partialMatch1"></i><br>

                          <p class="font-italic font-small-3" id="partialMatch1"
                             [ngbCollapse]="collapse && !medication?.matchedScript?.showdropdown">
                            {{
                              !medication?.matchedScript?.showdropdown ? 'No exact match found for your medication, but there\'s a potential match. Please review and confirm.' : ''
                            }}
                          </p>
                          <span *ngIf="medication?.matchedScript?.showdropdown"
                                class="badge bg-light-success">Match</span>
                        </div>
                        <p
                          *ngIf="!medication?.matchedScript?.showdropdown && medication.matchedScript.resetDropdown">
                          <b>
                            Selected Product : </b> {{ medication.matchedScript.resetDropdown }}
                          ${{ medication.matchedScript.prodPrice }}
                        </p>
                        <p
                          *ngIf="medication?.matchedScript?.showdropdown">
                          <b>
                            Selected Product : </b> {{
                            medication?.matchedScript?.product_name === '' ? medication?.matchedScript?.product_brand_name :
                              medication?.matchedScript?.product_name.includes(medication?.matchedScript?.medication_strength)
                                ? medication?.matchedScript?.product_name : medication?.matchedScript?.product_name + ' ' + medication?.matchedScript?.medication_strength
                          }}
                        </p>
                        <p
                          *ngIf="medication?.matchingResponseOfMedicationFound[0].availableStock <= 0 && medication?.matchingResponseOfMedicationFound.length === 1">
                          {{ medication?.matchingResponseOfMedicationFound[0].pharmacyProductName }}
                          <span class="badge"
                                [ngClass]="medication?.matchingResponseOfMedicationFound[0].availableStock <= 0 && pharmacy.pharmacyDetails?.acceptBackOrders ? 'bg-light-warning' : 'bg-light-danger'"
                          >
                                  {{ medication?.matchingResponseOfMedicationFound[0].availableStock <= 0 && pharmacy.pharmacyDetails?.acceptBackOrders ? 'Back order' : 'Out of Stock' }}

                                </span>
                        </p>

                        <ng-select
                          *ngIf="medication?.matchingResponseOfMedicationFound.length > 0 && !medication?.matchedScript?.showdropdown"
                          class="mt-1" [items]="medication?.matchingResponseOfMedicationFound"
                          [(ngModel)]="medication.matchedScript.resetDropdownId" [clearable]="false"
                          [ngModelOptions]="{standalone: true}"
                          (change)="OnProductChange($event,medication?.matchedScript,medication?.matchingResponseOfMedicationFound,pharmacy.medicationNotAvailable,i,pharmacy)"
                          bindLabel="pharmacyProductName" bindValue="pharmacyProductId"
                          placeholder="Select product">
                          <ng-template ng-option-tmp let-item="item" let-index="index">
                            <p>{{ item.pharmacyProductName }}
                              <span class="float-right font-weight-bold">{{ '$' + item.productCost }}</span>
                              <span class="badge mr-3 float-right" *ngIf="!item.isDiscontinued"
                                    [ngClass]="item.availableStock <= 0 && pharmacy.pharmacyDetails?.acceptBackOrders ? 'bg-light-warning' :
                                          item.availableStock <= 0 && !pharmacy.pharmacyDetails?.acceptBackOrders ?
                                            'bg-light-danger' : 'bg-light-success' ">
                                      {{
                                  item.availableStock <= 0 && pharmacy.pharmacyDetails?.acceptBackOrders ? 'Back order' :
                                    item.availableStock <= 0 && !pharmacy.pharmacyDetails?.acceptBackOrders ?
                                      'Out of stock' : 'In-stock'
                                }}
                                    </span>
                              <span class="badge mr-3 float-right bg-light-secondary" *ngIf="item.isDiscontinued">
                                      Discontinued
                                    </span>
                            </p>
                          </ng-template>
                        </ng-select>
                        <hr>
                      </div>
                    </div>
                    <div class="row" *ngFor="let medication of pharmacy.medicationNotAvailable">
                      <div class="col-sm-12 col-12 pt-1" *ngIf="medication.showRow">
                            <span
                              class="ft-trash cursor-pointer text-danger float-right"
                              (click)="removeUnmatchedScript(medication,pharmacy)"
                              ngbTooltip="Remove Product" placement="top"></span>
                        <div class="text-capitalize">
                          <b>Script
                            : </b>
                          {{
                            medication.product_name === '' ? medication.product_brand_name :
                              medication.product_name.includes(medication?.medication_strength)
                                ? medication.product_name : medication.product_name + ' ' + medication?.medication_strength
                          }}
                          <span class="badge bg-light-danger cursor-pointer">Unmatched</span><i
                          class="ft-info text-primary cursor-pointer ml-1"
                          *ngIf="!medication?.matchedScript?.showdropdown" (click)="collapse1 = !collapse1"
                          [attr.aria-expanded]="!collapse1" aria-controls="unMatch"></i><br>

                          <p class="font-italic font-small-3" id="unMatch"
                             [ngbCollapse]="collapse1">
                            We couldn’t match a medication. The pharmacy will review your script, select the
                            correct option, and seek your approval before dispensing.
                          </p>
                        </div>
                        <hr>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="row mt-2">
              <div class="col-md-12">
                <div class="form-group text-center">
                  <button type="button" class="btn primary-btn" (click)="gotoNext(wizard,docModal)"
                          [disabled]="!selectedPharmacy || disableBTn">
                    Next
                    <span style="margin-left:10px;"><i class="ft-chevron-right"></i></span>
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div class="row mt-2">
            <div class="col-md-12 col-12">
              <p>
                <button type="button" class="btn mr-2 help-btn">Help&nbsp;{{ platform.number }}</button>
              </p>
            </div>
          </div>
        </aw-wizard-step>

        <aw-wizard-step [stepTitle]="'Step 2'" [navigationSymbol]="{ symbol: '&#xf2bd;', fontFamily: 'FontAwesome' }">
          <h4 class="head text-center">ORDER SUMMARY</h4>
          <br/>
          <h5 class="font-weight-bold font-italic">Your Information</h5>
          <hr>
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12 col-12">
              <label class="font-weight-bold">Name</label>
              <p>{{ this.selectedCustomer?.firstName }} {{ this.selectedCustomer?.middleName }} {{ this.selectedCustomer?.lastName }}</p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-12">
              <label class="font-weight-bold">Mobile Number</label>
              <p>{{ this.selectedCustomer?.number }}</p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-12">
              <label class="font-weight-bold">Email</label>
              <p>{{ this.selectedCustomer?.email }}</p>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
              <label class="font-weight-bold">Address</label>
              <div class="d-flex flex-column flex-md-row align-items-stretch align-items-md-center">
                <ng-select [items]="this.userAddress" bindLabel="completeAddress" bindValue="_id"
                           placeholder="Select Address" [clearable]="false"
                           [(ngModel)]="selectedUserAddress" [ngModelOptions]="{
                           standalone:true
                           }"
                           class="mb-2 mb-md-0 flex-grow-1"
                           (change)="OnChangeAddress($event,docModal)">
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <b>{{ item.completeAddress }} <i class="{{item.icon}} float-right font-weight-bold"></i></b>
                  </ng-template>
                </ng-select>
                <button class="btn primary-btn ml-0 ml-md-2  mt-0" *ngIf="orderBy === 'Customer'"
                        (click)="openAddressModal(Address,'lg')">
                  <i class="ft-plus"></i> Add Address
                </button>
              </div>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-12 col-12"></div>
            <div class="col-lg-3 col-md-3 col-sm-12 col-12">
              <label class="font-weight-bold">Status</label><br>
              <span class="badge bg-light-success text-capitalize"
                    [ngClass]="userStatus.toLowerCase() === 'verified' ? 'bg-light-success' : 'bg-light-warning'">
                <i class="ft-check-circle mr-1"
                   [ngClass]="userStatus.toLowerCase() === 'verified'? 'ft-check-circle' : 'ft-alert-triangle'"></i>{{ userStatus }}
              </span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-lg-4 col-md-4 col-sm-12 col-12">
              <label class="font-weight-bold">Select Delivery or Instore Pickup <sup class="text-danger">*</sup></label>
              <div class="d-flex">
                <span class="badge bg-light-primary btn"
                      *ngIf="this.selectedPharmacy?.pharmacy_delivery_type === 'delivery' || this.selectedPharmacy?.pharmacy_delivery_type === 'both'"
                      [ngClass]="deliverModal.delivery ? 'bg-light-secondary' : 'bg-light-primary'"
                      (click)="openDeliveryModal(content4,'sm',false)"> <i
                  class="ft-truck mr-1"></i>Delivery</span>

                <span class="badge bg-light-primary btn ml-1"
                      *ngIf="this.selectedPharmacy?.pharmacy_delivery_type === 'pickup' || this.selectedPharmacy?.pharmacy_delivery_type === 'both'"
                      [ngClass]="deliverModal.pickup ? 'bg-light-secondary' : 'bg-light-primary'"
                      (click)="openDeliveryModal(content4,'lg',true)"><i
                  class="ft-shopping-bag mr-1"></i>Pickup</span>
              </div>
            </div>
            <div class="col-12 col-md-8 col-lg-8 col-sm-12 pt-1" *ngIf="showDoc">
              <label class="font-weight-bold">Government Issued Photo ID</label><br>
              <div class="d-flex flex-wrap card-container pt-1">
                <div class="card-item col-md-6 col-md-4 col-lg-auto" *ngIf="frontLicense !== ''">
                  <img [src]="frontLicense" alt="" class="img-thumbnail ">
                  <p class="text-center">Front Licence</p>
                </div>
                <div class="card-item col-md-6 col-md-4 col-lg-auto" *ngIf="backLicense !== ''">
                  <img [src]="backLicense" alt="" class="img-thumbnail">
                  <p class="text-center">Back Licence</p>
                </div>
                <div class="card-item col-md-6 col-md-4 col-lg-auto" *ngIf="medicareCard !== ''">
                  <img [src]="medicareCard" alt="" class="img-thumbnail">
                  <p class="text-center">Medicare Card</p>
                </div>
                <div class="card-item col-md-6 col-md-4 col-lg-auto" *ngIf="pensionCard !== ''">
                  <img [src]="pensionCard" alt="" class="img-thumbnail">
                  <p class="text-center">Pension Card</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="scriptImage!== ''">
            <div class="col-12 col-md-12">
              <label class="font-weight-bold">{{ showUrl ? 'Script Image' : 'Script URL' }}
                <i
                  *ngIf="showUrl"
                  class="ml-2 ft-download-cloud cursor-pointer font-medium-1" ngbTooltip="Download Script"
                  (click)="downloadScript(scriptImage)"></i>
              </label><br>
              <div *ngIf="showUrl">
                <img class="cursor-pointer" src="{{this.scriptImage}}" alt="" width="60" height="40"
                     (click)="isCollapsed = !isCollapsed"
                     [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample1">
                <div id="collapseExample1" [ngbCollapse]="isCollapsed">
                  <img src="{{this.scriptImage}}" alt="" width="400" height="400">
                </div>
              </div>
              <div *ngIf="!showUrl">
                <p>{{ scriptImage }}</p>
              </div>
            </div>
          </div>
          <br>
          <div class="d-none d-lg-block d-md-block d-sm-none">
            <div class="row">
              <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-12">
                <p class="font-weight-bold font-italic font-medium-2">Scripts</p>
                <table class="table m-0">
                  <thead>
                  <tr>
                    <th>Script Medication</th>
                    <th>Status</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let script of this.ScriptRows;">
                    <td class="text-capitalize padding-box">{{ script.erxNames }}</td>
                    <td>
                        <span class="badge"
                              [ngClass]="script.script_status === 'Match' ? 'bg-light-success' : 'bg-light-danger'">{{ script.script_status }}</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-12">
                <p class="font-weight-bold font-italic font-medium-2">Selected Product</p>
                <div class="table-responsive">
                  <table class="table m-0">
                    <thead>
                    <tr>
                      <th>Name</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let product of this.trackingItems; let i = index;">
                      <td>{{ product.product_name }}</td>
                      <td>
                        <div class="btn-group btn-group-sm" role="group">
                            <span class="btn primary-btn font-weight-bold"
                                  (click)="updateQuantity('decrease',product)">-</span>
                          <span class="btn primary-btn font-weight-bold">{{ product.quantity }}</span>
                          <span class="btn primary-btn font-weight-bold"
                                (click)="updateQuantity('increase',product)">+</span>
                        </div>
                      </td>
                      <td>${{ product.price }}</td>
                      <td><span class="badge bg-danger cursor-pointer"
                                (click)="confirm_Modal_open(confirmModal1, product, product.scriptData)">Remove</span>
                      </td>
                    </tr>
                    <tr *ngFor="let script of this.unMatchedScripts; let i = index;">
                      <td>{{
                          script.product_name === '' ? script.product_brand_name : script.product_name.includes(script.medication_strength) ? script.product_name :
                            script.product_name + ' ' + script.medication_strength
                        }}
                      </td>
                      <td>
                        <div class="btn-group btn-group-sm" role="group">
                          <span class="btn primary-btn font-weight-bold"
                                (click)="updateQuantity('decrease',script,'unmatch')">-</span>
                          <span class="btn primary-btn font-weight-bold">{{ script.quantity }}</span>
                          <span class="btn primary-btn font-weight-bold"
                                (click)="updateQuantity('increase',script,'unmatch')">+</span>

                        </div>
                      </td>
                      <td>${{ script.price }}</td>
                      <td>
                        <span
                          class="badge bg-danger cursor-pointer"
                          (click)="confirm_Modal_open(confirmModal1, script, script)"
                        >Remove</span>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" class="text-right"><b>Item Total :</b></td>
                      <td>${{ totalCost }}</td>
                      <td></td>
                    </tr>
                    <tr *ngIf="!pickup">
                      <td colspan="2" class="text-right"><b>Delivery Charges :</b></td>
                      <td>${{ this.selectedPharmacy?.pharmacyDetails?.delivery_charge }}</td>
                    </tr>
                    <tr>
                      <td colspan="2" class="text-right"><b>Total Amount :</b></td>
                      <td>
                        <b>${{ totalAmount_to_paid.toFixed(2) }}</b><br>
                        <small>*Includes GST of
                          ${{ GST_amount.toFixed(2) }}</small>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!--Mobile View Card-->

          <div class="d-sm-block d-lg-none d-md-none d-xl-none">
            <div class="card outer-card" *ngFor="let script of this.ScriptRows;let j = index;">
              <div class="card-body">
                <h4 class="card-title">{{ script.erxNames }} </h4>
                <span class="badge"
                      [ngClass]="script.script_status === 'Match' ? 'bg-light-success' : 'bg-light-danger'">{{ script.script_status }}</span>
                <div *ngIf="script.script_status === 'Match'">
                  <div class="card inner-card" *ngFor="let product of script.itemsOrdered; let i = index;">
                    <div class="card-content">
                      <div class="card-body">
                        <p>{{ product.product_name }}</p>
                        <p>${{ product.price }}</p>
                        <div class="btn-group btn-group-sm" role="group">
                            <span class="btn primary-btn font-weight-bold"
                                  (click)="updateQuantity('decrease',product)">-</span>
                          <span class="btn primary-btn font-weight-bold">{{ product.quantity }}</span>
                          <span class="btn primary-btn font-weight-bold"
                                (click)="updateQuantity('increase',product)">+</span>
                        </div>
                        <a (click)="confirm_Modal_open(confirmModal1, product, script)"
                           class="btn badge bg-danger btn-block text-white">Remove</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="script.script_status !== 'Match'">
                  <div class="card inner-card">
                    <div class="card-content">
                      <div class="card-body">
                        <p>{{ script.product_name === '' ? script.product_brand_name : script.product_name.includes(script.medication_strength) ? script.product_name : script.product_name + ' ' + script.medication_strength }}</p>
                        <p>${{ script.price }}</p>
                        <div class="btn-group btn-group-sm" role="group">


                            <span class="btn primary-btn font-weight-bold"
                                  (click)="updateQuantity('decrease',script,'unmatch')">-</span>
                          <span class="btn primary-btn font-weight-bold">{{ script.quantity }}</span>
                          <span class="btn primary-btn font-weight-bold"
                                (click)="updateQuantity('increase',script,'unmatch')">+</span>
                        </div>
                        <a (click)="confirm_Modal_open(confirmModal1, script, script)"
                           class="btn badge bg-danger btn-block text-white">Remove</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <p><b>Item Total :</b> ${{ totalCost }}</p>
              <p *ngIf="!pickup"><b>Delivery Charge :</b>
                ${{ this.selectedPharmacy?.pharmacyDetails?.delivery_charge }}</p>
              <p><b>Total Amount : </b> <b>${{ totalAmount_to_paid.toFixed(2) }}</b> <br>
                <small>*Includes GST of
                  ${{ GST_amount.toFixed(2) }}</small>
              </p>
            </div>
          </div>

          <h5 class="font-weight-bold font-italic">Customer note</h5>
          <hr>
          <i>If this is a preorder the pharmacy might not be able to fulfill your order. Please add notes if you feel
            this is the case in the box below.</i>
          <div class="row">
            <div class="col-md-6 col-lg-6 col-12 pt-1">
              <textarea class="form-control" [(ngModel)]="customerNote" rows="3" cols="10"></textarea>
            </div>
          </div>
          <h5 class="font-weight-bold font-italic mt-2">Selected Pharmacy</h5>
          <hr>
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="col-12">
                <label class="font-weight-bold">Pharmacy Name</label>
                <p>{{ this.selectedPharmacy?.pharmacy_name }}</p>
              </div>
              <div class="col-12 mt-2">
                <label class="font-weight-bold">Mobile Number</label>
                <p>{{ this.selectedPharmacy?.pharmacyDetails?.phoneNumber }}</p>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="col-12">
                <label class="font-weight-bold">Email</label>
                <p>{{ this.selectedPharmacy?.pharmacyDetails?.email }}</p>
              </div>
              <div class="col-12">
                <label class="font-weight-bold">Pharmacy Address</label>
                <p>{{
                    this.selectedPharmacy?.pharmacyDetails?.address + ' ' + this.selectedPharmacy?.pharmacyDetails?.suburb + ' ' + this.selectedPharmacy?.pharmacyDetails?.state + ' '
                    + this.selectedPharmacy?.pharmacyDetails?.postcode
                  }}</p>
              </div>
            </div>
          </div>

          <div class="form-group text-center space-20 mt-2 px-1 py-1">
            <button uiSref="work" type="button" class="btn secondary-btn mr-1" awPreviousStep
                    (click)="checkProdEmpty()">
              <span style="margin-right:10px;"><i class="ft-chevron-left"></i></span> Previous
            </button>
            <button type="button" class="btn primary-btn" (click)="confirmation(content3,'sm')">Submit
              <span style="margin-left:10px;"><i class="ft-check-circle"></i></span>
            </button>
          </div>
          <div class="row">
            <div class="col-md-12 col-12 col-sm-12 pt-1 text-md-left text-center">
                <span
                  class="btn mr-2 help-btn">Help&nbsp;{{ this.selectedPharmacy?.pharmacyDetails?.phoneNumber }}</span>
            </div>
          </div>
        </aw-wizard-step>
      </aw-wizard>
    </div>
  </div>
</section>


<ng-template #view_script_image let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Script Image</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="text-center">
      <img [src]="viewScript" alt="" width="100%" height="auto">
    </div>
  </div>
</ng-template>

<ng-template #confirmModal1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Delete Medicine</h4>
  </div>
  <div class="modal-body">
    <p class="text-center">Are you sure you want to remove this Medicine?</p>
    <div class="row mt-1">
      <div class="col-12 text-center">
        <button class="btn btn-danger" (click)="removeItem(modal)">Yes</button>
        <button class="btn btn-secondary ml-2" (click)="modal.dismiss('Cross click')">No</button>
      </div>
    </div>
  </div>
</ng-template>


<!--Token passing for erx-->
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="scriptForm" (ngSubmit)="add(smallModal)">
      <h5 class="head text-center">Enter Token Number</h5>
      <br/>
      <div class="row">
        <div class="col-md-12 col-12">
          <label class="form-control-label">Token Number</label>
          <input class="form-control input-md token"
                 formControlName="token" type="text">
          <p *ngIf="scriptForm.controls['token'].hasError('pattern')" class="text-danger">Invalid Token number</p>
          <p *ngIf="submitted && scriptForm.controls['token'].hasError('required')" class="text-danger">Token is
            required</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-12 px-2 py-2 text-center">
          <button type="submit" class="btn primary-btn">Submit
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>


<ng-template #docModal let-c="close" let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Upload Document!</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeMod(modal)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="showMessageBlock">
      <p class="text-justify font-weight-bold word-spaceing">Please upload your government ID for the pharmacy to verify
        your details match your script.</p>
      <div class="d-flex align-items-center justify-content-center">
        <button class="btn btn-success" (click)="createPharmacyUser()">Okay</button>
      </div>
    </div>
    <ng-container *ngIf="showUpload">
      <div class="row">
        <div class="col-md-6 col-12 col-sm-12 col-lg-6">
          <label class="form-label font-small-3">Select Document</label>
          <ng-select [(ngModel)]="this.type" (change)="OnChangeType($event)" placeholder="Select" [clearable]="false">
            <ng-option value="DriversLicense">Drivers License</ng-option>
            <ng-option value="medicareCardNumber">Medicare Card</ng-option>
            <ng-option value="pensionCardNumber">Pension Card</ng-option>
          </ng-select>
        </div>
      </div>

      <div class="row mt-2" *ngIf="this.type === 'DriversLicense'">
        <div class="col-md-6 col-12 col-sm-12 col-lg-6">
          <div class="form-group mb-2">
            <label>Upload Front Side</label>
            <input type="file" class="form-control-file" accept="image/png,image/jpg,image/jpeg"
                   (change)="uploadLicence($event,'licenseFront')">
          </div>
        </div>
        <div class="col-md-6 col-12 col-sm-12 col-lg-6">
          <div class="form-group mb-2">
            <label>Upload Back Side</label>
            <input type="file" class="form-control-file" accept="image/png,image/jpg,image/jpeg"
                   (change)="uploadLicence($event,'licenseBack')">
          </div>
        </div>
      </div>
      <div class="row mt-2" *ngIf="this.type === 'pensionCardNumber' || this.type === 'medicareCardNumber'">
        <div class="col-md-6 col-12 col-sm-12 col-lg-6">
          <div class="form-group mb-2">
            <label>Upload</label>
            <input type="file" class="form-control-file" accept="image/png,image/jpg,image/jpeg"
                   (change)="uploadLicence($event,this.type)">
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-md-12 col-12 col-sm-12 col-lg-12 text-center">
          <button class="btn secondary-btn" [disabled]="this.trackFileUpload" (click)="goNext(modal)">Next
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>


<ng-template #verifyAddressModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Warning!</h4>
  </div>
  <div class="modal-body">
    <div *ngIf="showMessageBlock">
      <p class="text-justify font-weight-bold">Please upload your government ID for the pharmacy to verify your details
        match your script.</p>
      <div class="d-flex align-items-center justify-content-center">
        <button class="btn btn-success" (click)="createPharmacyUser()">Okay</button>
      </div>
    </div>
    <ng-container *ngIf="showUpload">
      <div class="row">
        <div class="col-md-6 col-6">
          <label class="form-label font-small-3">Select Document</label>
          <select id="licenseType" class="form-control square" (change)="OnChangeType($event)">
            <option selected>Select</option>
            <option value="DriversLicense">Drivers License</option>
            <option value="medicareCardNumber">Medicare Card</option>
            <option value="pensionCardNumber">Pension Card</option>
          </select>
        </div>
      </div>

      <div class="row mt-2" *ngIf="this.type === 'DriversLicense'">
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Upload Front Side</label>
            <input type="file" class="form-control-file" accept="image/png,image/jpg,image/jpeg"
                   (change)="uploadLicence($event,'licenseFront')">
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Upload Back Side</label>
            <input type="file" class="form-control-file" accept="image/png,image/jpg,image/jpeg"
                   (change)="uploadLicence($event,'licenseBack')">
          </div>
        </div>
      </div>
      <div class="row mt-2" *ngIf="this.type === 'pensionCardNumber' || this.type === 'medicareCardNumber'">
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label>Upload</label>
            <input type="file" class="form-control-file" accept="image/png,image/jpg,image/jpeg"
                   (change)="uploadLicence($event,this.type)">
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12 text-center">
          <button class="btn secondary-btn" [disabled]="this.trackFileUpload" (click)="uploadNext()">Next</button>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>


<ng-template #content3 let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Warning!</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-justify word-spaceing">Are you certain about proceeding with the order to the selected pharmacy?</p>
    <div class="text-center">
      <button class="btn secondary-btn" [disabled]="lockButton" (click)="placeOrder(content3)">Yes</button>
    </div>
  </div>
</ng-template>


<ng-template #content4 let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{ pickup ? 'Pickup' : 'Delivery' }}</h4>
    <button type="button" class="close" aria-label="Close"
            (click)="d('Cross click');this.deliverModal.delivery = false;this.deliverModal.pickup = false;this.pickup = false;
this.pickup = true; this.forPickUpCalculation();
">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="pickup;else others">
      <p class="text-justify text-center font-weight-bold">Who is picking up?</p>
      <div class="d-flex align-items-center justify-content-center">
        <button class="btn" (click)="selectMe('Me')" [ngClass]="choiceMade === 'Me' ? 'primary-btn' :'secondary-btn'">
          Me
        </button>
        <button class="btn ml-2" (click)="selectMe('Carrier')"
                [ngClass]="choiceMade === 'Carrier' ? 'primary-btn' : 'secondary-btn'">Carer
        </button>
      </div>
      <div class="px-1 py-2" *ngIf="showAuthTPAForm">
        <h4>Authorized TPA Details</h4>
        <div class="row">
          <div class="col-md-6">
            <label>Full Name</label>
            <input type="text" name="fullName" class="form-control square" #fullname>
          </div>
          <div class="col-md-6">
            <label>Relationship</label>
            <select name="relationship" class="form-control square" #relType>
              <option value="mother">Mother</option>
              <option value="father">Father</option>
              <option value="spouse">Spouse</option>
              <option value="brother">Brother</option>
              <option value="sister">Sister</option>
              <option value="grandParent">Grand parent</option>
              <option value="son">Son</option>
              <option value="daughter">Daugther</option>
            </select>
          </div>
        </div>
        <p class="mt-1"><b>Note:</b> TPA Must Provide Goverment issued ID on pickup that matches full Name</p>
        <div class="row">
          <div class="col-12 col-md-12 text-center">
            <label class="text-justify font-weight-bold">Pharmacy Address</label>
            <p>{{ this.selectedPharmacy.pharmacy_name }}
              <br> {{
                this.selectedPharmacy?.pharmacyDetails?.address + ' ' + this.selectedPharmacy?.pharmacyDetails?.suburb + ' ' + this.selectedPharmacy?.pharmacyDetails?.state + ' '
                + this.selectedPharmacy?.pharmacyDetails?.postcode
              }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-12 text-center px-2 py-2">
            <button class="btn primary-btn" (click)="fillData(fullname.value,relType.value,modal)">Submit</button>
          </div>
        </div>
      </div>
      <div class="row mt-1" *ngIf="choiceMade === 'Me'">
        <div class="col-12 col-md-12 text-center">
          <label class="text-justify font-weight-bold">Pharmacy Address</label>
          <p>{{ this.selectedPharmacy.pharmacy_name }}
            <br> {{
              this.selectedPharmacy?.pharmacyDetails?.address + ' ' + this.selectedPharmacy?.pharmacyDetails?.suburb + ' ' + this.selectedPharmacy?.pharmacyDetails?.state + ' '
              + this.selectedPharmacy?.pharmacyDetails?.postcode
            }}</p>
        </div>
      </div>
      <div class="row" *ngIf="choiceMade === 'Me'">
        <div class="col-12 col-md-12 text-center px-2 py-2">
          <button class="btn primary-btn"
                  (click)="removeFrightCharge(modal)">
            Submit
          </button>
        </div>
      </div>
    </div>
    <ng-template #others>
      <p class="text-justify font-weight-bold text-danger"> Delivery Fee:
        <span>${{ this.selectedPharmacy?.pharmacyDetails?.delivery_charge }}</span></p>
      <p class="text-justify font-weight-bold">Your Delivery Address is:
        <br> <span class="font-weight-normal"> {{
            this.selectedAddress.address + ' ' + this.selectedAddress.suburb + ' '
            + this.selectedAddress.state + ' ' + this.selectedAddress.postcode
          }}</span></p>
      <div class="row">
        <div class="col-12">
          <label>Delivery Instructions</label>
          <textarea class="form-control square" cols="10" rows="2" #delivery_Instruction></textarea>
        </div>
      </div>
      <div class="text-center mt-1">
        <button class="btn primary-btn" (click)="confirm(delivery_Instruction.value,content4)">Confirm</button>
      </div>
    </ng-template>
  </div>
</ng-template>


<ng-template #Address let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Add New Address</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="addressForm" (ngSubmit)="submitAddress(Address)">
      <div class="row">
        <div class="col-12 col-lg-6 col-md-6 col-sm-12">
          <label>Address Type</label>
          <select formControlName="addressType" id="" class="form-control square">
            <option value="Home">Home</option>
            <option value="Work">Work</option>
            <option value="Others">Others</option>
          </select>
          <p class="text-danger" *ngIf="submitted && addressForm.controls['addressType'].invalid">Address Type is
            required</p>
        </div>
        <div class="col-12 col-lg-6 col-md-6 col-sm-12">
          <label>Address</label>
          <input type="text" class="form-control square" formControlName="address">
          <p class="text-danger" *ngIf="submitted && addressForm.controls['address'].invalid">Address is required</p>
        </div>
        <div class="col-12 col-lg-6 col-md-6 col-sm-12">
          <label>Suburb</label>
          <input type="text" class="form-control square" formControlName="suburb">
          <p class="text-danger" *ngIf="submitted && addressForm.controls['suburb'].invalid">Suburb is required</p>
        </div>
        <div class="col-12 col-lg-6 col-md-6 col-sm-12">
          <label>State</label>
          <ng-select formControlName="state" placeholder="Select State" [clearable]="false" [(ngModel)]="selectedState">
            <ng-option *ngFor="let state of states" [value]="state">{{ state }}</ng-option>
          </ng-select>
          <p class="text-danger" *ngIf="submitted && addressForm.controls['state'].invalid">State is required</p>
        </div>
        <div class="col-12 col-lg-6 col-md-6 col-sm-12">
          <label>Postcode</label>
          <input type="text" class="form-control square" formControlName="postcode" maxlength="4">
          <p class="text-danger" *ngIf="submitted && addressForm.controls['postcode'].invalid">Postcode is required</p>
          <p class="text-danger" *ngIf="addressForm.controls['postcode'].hasError('pattern')">Invalid postcode</p>
        </div>
        <div class="col-12 col-lg-12 col-md-12 col-sm-12 text-right pt-1">
          <button type="submit" class="btn secondary-btn">Submit</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #smallModal let-modal let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Message</h4>
  </div>
  <div class="modal-content">
    <div class="modal-body text-center">
      <p>If you have a paper script or token, make sure to store it securely or dispose it properly when no longer
        needed.</p>
      <div class="text-center">
        <button class="btn primary-btn" (click)="d('Cross click')">OK</button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #ScriptOpen let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Search Medicine</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-content">
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <label>Select Product</label>
          <ng-select [items]="productList" [virtualScroll]="true"
                     (change)="onChangeProduct($event,modal)" bindLabel="productName" bindValue="productName">
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <p>{{ item.productName }}
                <span *ngIf="item.stock <= 0 && !item?.isDiscontinued" class="badge  btn text-capitalize float-right"
                      [ngClass]="this.selectedPharmacy?.acceptBackOrders ? 'bg-light-warning' : 'bg-light-danger'">
                {{ this.selectedPharmacy?.acceptBackOrders ? 'Backorder' : 'Out of stock' }}</span>

                <span *ngIf="item.stock > 0 && !item?.isDiscontinued"
                      class="badge bg-light-success btn text-capitalize float-right">In-Stock</span>

                <span *ngIf="item?.isDiscontinued" class="badge bg-light-secondary btn text-capitalize float-right">Discontinued</span>
              </p>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #ScriptImage_Upload let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Upload Script</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12 col-12">
        <select class="form-control square" (change)="OnChangeUploadType($event)">
          <option selected disabled>Select Type</option>
          <option value="image">Image</option>
          <option value="url">Url</option>
        </select>
      </div>
    </div>
    <div class="row" *ngIf="uploadType === 'image'">
      <div class="col-md-12 col-12">
        <input type="file" accept="image/jpg,image/png,image/jpeg" id="scriptupload" class="form-control d-none"
               (change)="upload($event,modal)">
        <label class="btn secondary-btn ml-2 mt-2" for="scriptupload"><i class="ft-camera mr-1"></i>Upload Script
          Image</label>
      </div>
    </div>
    <div class="row mt-2" *ngIf="uploadType === 'url'">
      <div class="col-md-12 col-12">
        <div class="d-flex">
          <input type="url" class="form-control square" #scriptUrl>
          <button class="btn ml-1 secondary-btn"
                  (click)="paste(scriptUrl.value,modal)">Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>


