<!--Login Page Starts-->
<section id="login" class="page-wrapper">
  <div class="row auth-height full-height-vh m-0">
    <div class="col-12 d-flex align-items-center justify-content-center">
      <div class="card overflow-hidden">
        <div class="card-content">
          <div class="card-body auth-img">
            <div class="row">
              <div class="col-12 col-md-12 d-flex justify-content-center align-items-center auth-img-bg p-2">
                <img src="{{this.platform.logo}}" width="auto" height="70">
              </div>
            </div>
            <div class="row m-0">
              <div class="col-12 d-flex justify-content-center align-items-center auth-img-bg">
                <h4 class="text-center"><br>Forgot Password</h4>
              </div>
            </div>
            <div class="row m-0" *ngIf="!showEmailBlock && !showPhoneBlock">
              <div class="col-12 d-flex justify-content-center align-items-center auth-img-bg p-2">
                <a class="btn btn-md primary-btn" (click)="showBlock('email')"><i class="ft-mail mr-1"></i>Email</a>
                <span class="ml-2 title-text">OR</span>
                <a (click)="showBlock('phone')" class="btn btn-md secondary-btn float-right mx-2"><i
                  class="ft-phone mr-1"></i>Phone</a>
              </div>
            </div>
            <div class="row m-0" *ngIf="showEmailBlock">
              <div class="col-md-12 col-12">
                <form [formGroup]="emailForm" (ngSubmit)="sendEmail()">
                  <div class="row">
                    <div class="col-md-12 col-12">
                      <label>Email</label>
                      <input type="email" class="form-control square" formControlName="email" placeholder="Please Enter Your Registered Email ID">
                      <p class="danger"
                         *ngIf="(submitted && emailForm.controls['email'].invalid )|| (emailForm.controls['email'].hasError('email'))">
                        Invaild Email</p>
                    </div>
                    <div class="col-md-12 col-12 text-center px-2 py-2">
                      <label for="email"></label>
                      <button type="submit" id="email" class="btn secondary-btn"><i class="ft-mail mr-1"></i>Send
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="row m-0" *ngIf="showPhoneBlock">
              <div class="col-md-12 col-12">
                <form [formGroup]="phoneNumberForm" (ngSubmit)="sendSMS()">
                  <div class="row">
                    <div class="col-md-12 col-12 ">
                      <label>Phone Number</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text cursor-pointer">+61</span>
                        </div>
                        <input type="text" class="form-control square" formControlName="number"
                               placeholder="Please Enter Your Registered Phone No">
                      </div>
                      <p class="danger"
                         *ngIf="(submitted && phoneNumberForm.controls['number'].invalid )|| (phoneNumberForm.controls['number'].hasError('pattern'))">
                        Invaild Phone Number</p>
                    </div>
                    <div class="col-md-12 col-12 text-center px-2 py-2">
                      <label for="number"></label>
                      <button type="submit" id="number" class="btn secondary-btn"><i class="ft-send mr-1"></i>Send
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Login Page Ends-->

