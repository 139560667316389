<section id="shopping-cart">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="card-header">
              <h4 class="card-title">Audit Logs</h4>
            </div>
          </div>
        </div>
        <div class="row d-none d-lg-block d-md-block d-lg-block">
          <div class="col-md-12 col-12 d-flex justify-content-end align-items-center">
            <!-- Calendar Input Group -->

            <!-- Select Dropdown -->
            <select (change)="OnChangeEnums($event)" class="form-control mr-2" style="width: 200px;">
              <option selected disabled>Select Table</option>
              <option *ngFor="let enum of tableData" [value]="enum">{{ enum }}</option>
            </select>
            <div class="input-group mr-2" style="width: 300px">
              <div class="input-group-prepend dp-hidden">
                <input
                  name="datepicker"
                  class="form-control dp-hidden"
                  ngbDatepicker
                  #datepicker="ngbDatepicker"
                  [autoClose]="'outside'"
                  (dateSelect)="onDateSelection($event)"
                  [displayMonths]="1"
                  [dayTemplate]="t"
                  outsideDays="hidden"
                  [startDate]="fromDate!"
                  tabindex="-1"
                />
                <ng-template #t let-date let-focused="focused">
          <span
            class="custom-day"
            [class.focused]="focused"
            [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)"
            (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null"
          >
            {{ date.day }}
          </span>
                </ng-template>
              </div>
              <input
                #dpFromDate
                class="form-control mt-2"
                placeholder="yyyy-mm-dd"
                name="dpFromDate"
                [value]="from + ' - ' + to"
                (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
              />
              <div class="input-group-append">
                <div class="input-group-text mt-2" (click)="datepicker.toggle()">
                  <i class="fa fa-calendar" style="cursor: pointer;"></i>
                </div>
              </div>
            </div>

            <!-- Download Button with Dropdown -->
            <div class="mr-2" ngbDropdown>
              <button class="btn primary-btn" id="dropdownBasic1" ngbDropdownToggle>Download</button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button class="dropdown-item" (click)="downloadAll()" [disabled]="calendarChoosed"><i class="ft-download mr-1"></i>All</button>
                <button class="dropdown-item" (click)="currentPage()" [disabled]="calendarChoosed"><i class="ft-download mr-1"></i>This Page</button>
                <button class="dropdown-item" (click)="dateRange()" [disabled]="!calendarChoosed"><i class="ft-download mr-1"></i>Date Range</button>
              </div>
            </div>

          </div>
        </div>
        <div class="row d-sm-block d-lg-none d-md-none d-lg-none">
          <div class="col-sm-12 col-12">
            <div class="input-group">
              <div class="input-group-prepend dp-hidden">
                <input
                  name="datepicker"
                  class="form-control dp-hidden"
                  ngbDatepicker
                  #datepicker="ngbDatepicker"
                  [autoClose]="'outside'"
                  (dateSelect)="onDateSelection($event)"
                  [displayMonths]="1"
                  [dayTemplate]="t"
                  outsideDays="hidden"
                  [startDate]="fromDate!"
                  tabindex="-1"
                />
                <ng-template #t let-date let-focused="focused">
          <span
            class="custom-day"
            [class.focused]="focused"
            [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)"
            (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null"
          >
            {{ date.day }}
          </span>
                </ng-template>
              </div>
              <input
                #dpFromDate
                class="form-control mt-2"
                placeholder="yyyy-mm-dd"
                name="dpFromDate"
                [value]="from + ' - ' + to"
                (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
              />
              <div class="input-group-append">
                <div class="input-group-text mt-2" (click)="datepicker.toggle()">
                  <i class="fa fa-calendar" style="cursor: pointer;"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-12">
            <div ngbDropdown>
              <button class="btn primary-btn" id="dropdownBasic2" ngbDropdownToggle>Download</button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                <button class="dropdown-item" [disabled]="calendarChoosed" (click)="downloadAll()"><i class="ft-download mr-1"></i>All</button>
                <button class="dropdown-item" [disabled]="calendarChoosed" (click)="currentPage()"><i class="ft-download mr-1"></i>This Page</button>
                <button class="dropdown-item" (click)="dateRange()" [disabled]="!calendarChoosed"><i class="ft-download mr-1"></i>Date Range</button>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-12">
            <select (change)="OnChangeEnums($event)" class="form-control mt-2">
              <option selected disabled>Select Table</option>
              <option *ngFor="let enum of tableData" [value]="enum">{{ enum }}</option>
            </select>
          </div>
        </div>
        <h4 class="text-center" *ngIf="auditLogs.length === 0">No Audit logs found</h4>
        <div class="card-body">
          <div class="table-responsive" *ngIf="auditLogs.length > 0">
            <table class="table">
              <thead class="thead-light text-center">
              <tr>
                <th>#</th>
                <th>Description</th>
                <th>Date</th>
                <th>Time</th>
                <th>Table</th>
              </tr>
              </thead>
              <tbody class="text-center">
              <tr *ngFor="let auditlog of auditLogs">
                <td>{{ auditlog.slNumber }}</td>
                <td>
                  <p class="text-justify">{{ auditlog.change }}</p>
                  </td>
                <td>{{ auditlog.changedDate.split('T')[0].split('-').reverse().join('-') }}</td>
                <td>{{ auditlog.createdAt | date : 'shortTime' }}</td>
                <td class="text-capitalize">{{ auditlog.table }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer text-center">
            <ngb-pagination class="float-right" [collectionSize]="this.maxSize * 10" (pageChange)="pagination($event)"
                            [(page)]="page" [maxSize]="3" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
