<p class="text-center font-weight-bold">TERMS OF SERVICE
</p>
<h5>Licence</h5>
<ol>
  <li>Under these Terms of Service (the “Agreement”), and subject to the payment of any applicable Fees, Chemsoft Pty
    Ltd, ABN: 49 675 969 847 ACN: 675 969 847 (the “Vendor”) grants to the user (the “Licensee”) a revocable,
    non-exclusive, non-transferable, limited licence (the “Licence”) to use Script IT (the “Platform”) in accordance
    with the terms of this Agreement.
  </li>
  <li>The Vendor shall, during the term of this Agreement, provide the Platform to the Licensee on and subject to the
    terms of this Agreement.
  </li>
  <li>“Platform” includes the software-as-a-service program and any related printed, electronic, and online
    documentation and any other files that may accompany the product.
  </li>
  <li>“Fees” means, where the Licensee is a pharmacy business, any fees paid or payable by the Licensee to the Vendor as
    set out in the relevant purchase order, approved quote or onboarding form then in effect between the parties.
  </li>
  <li>For the avoidance of doubt, the Platform is provided to the Licensee on a non-exclusive basis and nothing in this
    Agreement shall prevent the Vendor offering the Platform to any other customers and/or users.
  </li>
  <li>Title, copyright, intellectual property rights and distribution rights in the Platform remain exclusively with the
    Vendor. Intellectual property rights include the look and feel of the Platform. This Agreement constitutes a licence
    for use only and is not in any way a transfer of ownership rights to the Platform.
  </li>
  <li>The Platform may not be modified, reverse-engineered, or decompiled in any manner.</li>
  <li>A user is not permitted to use the Platform if they are under the age of 18.</li>
  <li>The Licensee must not use the Platform for any unlawful purpose that will violate any applicable local, state,
    federal or international law, rule or regulation.
  </li>
  <li>If the Licensee is using the Platform on behalf of a subject of care, the Licensee warrants it has all legal
    permissions and consents required to act on behalf of the subject of care to use the Platform and accept and consent
    to this Agreement and the Privacy Policy.
  </li>
  <li>Apart from any incidental use arising as a result of the Licensee’s exercise of the licence contained in clause 1,
    the Licensee must not use the name, logo or associated trademarks of the Vendor without the express written approval
    of the Vendor (which approval may be given on such conditions as the Vendor thinks fit including the condition that
    the Licensee will comply with the Vendor’s trademark guidelines).
  </li>
  <li>Failure to comply with any of the terms set out in clauses 1 to 11 in this ‘Licence’ section will be considered a
    material breach of this Agreement.
  </li>
</ol>

<h5>Charges and payment</h5>
<ol start="13">
  <li>If the Licensee is a pharmacy business, the Licensee shall pay the Fees to the Vendor in accordance with clauses 13 to 18, and any applicable purchase order, approved quote or onboarding form between the Licensee and the Vendor with respect to the Platform.</li>
  <li>The Licensee shall provide to the Vendor valid, up-to-date and complete credit debit card or direct debit details or approved purchase order information acceptable to the Vendor and any other relevant valid, up-to-date and complete contact and billing details and the Licensee hereby authorises the Vendor to take payment from such card on the commencement date of this Agreement and every month thereafter during the term of this Agreement (or as otherwise agreed between the parties).</li>
  <li>Fees shall be payable one (1) month in advance or as agreed in accordance with the Licensee’s purchase order, approved quote or onboarding form.</li>
  <li>If the Vendor has not received payment within thirty (30) days after the relevant due date, and without prejudice to any other rights and remedies of the Vendor:
    <ol type="a">
      <li>the Vendor may, without liability to the Licensee, disable the Licensee’s password, account and access to all or part of the Platform and the Vendor shall be under no obligation to provide any or all of the Platform while the invoice(s) concerned remain unpaid; and</li>
      <li>interest shall accrue on a daily basis on such due amounts at an annual rate equal to four percent (4%) over the then current base lending rate of the Commonwealth Bank from time to time, commencing on the due date and continuing until fully paid, whether before or after judgment.</li>
    </ol>
  </li>
  <li>All amounts and fees stated or referred to in the Agreement:
    <ol type="a">
      <li>shall be payable in Australian dollars;</li>
      <li>are non-cancellable and non-refundable;</li>
      <li>are exclusive of goods and services tax, which shall be added to the Vendor’s invoice(s) at the appropriate rate.</li>
    </ol>
  </li>
  <li>The Vendor shall be entitled to increase the Fees once every 12 months during the term based on a fair evaluation of inflation and any additional costs and the relevant order form, approved quote or onboarding form shall be deemed to have been amended accordingly.</li>
</ol>


<h5>Limitation of liability</h5>
<ol start="19">
  <li>Notwithstanding any other provision of this Agreement, to the maximum extent permitted by law:
    <ol type="a">
      <li>in no circumstances will either party be liable to the other for any indirect or consequential loss, damage, liability, costs or expenses (including loss of profits, transaction losses, opportunity costs, interruption of business, loss of use, loss of goodwill or loss or corruption of data) arising out of or in connection with this Agreement, whether such liability arises under contract, tort (including negligence), law or otherwise;</li>
      <li>the Vendor’s total aggregate liability to the Licensee under or in connection with this Agreement for all loss, damage, liability, costs or expenses of a kind not excluded by paragraph (a), whether arising under contract, tort (including negligence), law or otherwise is limited to an amount not exceeding the Fees paid by the Licensee during the 12 months prior to the date on which the claim arose less any amount previously paid by the Vendor to the Licensee in relation to a claim under this Agreement, or $100, whichever is higher; and</li>
      <li>the Licensee’s total aggregate liability to the Vendor under or in connection with this Agreement for all loss, damage, liability, costs or expenses of a kind not excluded by paragraph (a), whether arising under contract, tort (including negligence), law or otherwise is limited to an amount not exceeding the Fees paid by the Licensee during the 12 months prior to the date on which the claim arose less any amount previously paid by the Licensee to the Vendor in relation to a claim under these Terms, or $100, whichever is higher.</li>
    </ol>
  </li>
  <li>The Vendor shall not be obligated to indemnify or defend the Licensee with respect to any third-party claim arising out of or relating to the Platform. To the extent the Platform is required to provide indemnification by applicable law, the Vendor shall be solely responsible for the investigation, defence, settlement and discharge of any claim that the Platform or the Licensee’s use of it infringes any third-party intellectual property rights.</li>
  <li>The Vendor makes no warranty expressed or implied regarding the fitness of the Platform for a particular purpose or that the Platform will be suitable or appropriate for the specific requirements of the Licensee.</li>
  <li>Unless otherwise specified, the Vendor does not control the quality or availability of prescriptions accessed through the Platform and the Vendor takes no responsibility for the quality, availability, fitness for purpose or accuracy of data of any such prescription.</li>
  <li>To the maximum extent permitted under applicable law, the Vendor, on its own behalf and on behalf of its affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Platform, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Vendor provides no warranty or undertaking, and makes no representation of any kind that the Platform will meet your requirements, achieve any intended results, be compatible or work with any other software, applications, systems, or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.</li>
  <li>Nothing in this Agreement restricts, excludes, or modifies any rights or remedies that cannot be restricted, excluded, or modified by applicable consumer law.</li>
</ol>

<h5>Indemnity</h5>
<ol start="25">
  <li>The Licensee shall defend, indemnify and hold harmless the Vendor against claims, actions, proceedings, losses, damages, expenses and costs (including without limitation court costs and reasonable legal fees)
    arising out of or in connection with the Licensee’s use of the Platform, provided that:
    <ul>
      <li>The Licensee is given prompt notice of any such claim;</li>
      <li>The Vendor provides reasonable co-operation to the Licensee in the defence and settlement of such claim,
        at the Licensee’s expense; and
      </li>
      <li>The Licensee is given sole authority to defend or settle the claim.</li>
    </ul>
  </li>
  <li>The Vendor shall defend the Licensee, its officers, directors and employees against any claim that the Platform infringes any Australian patent effective as of the commencement date, copyright, trade mark, database right or right of confidentiality, and shall indemnify the Licensee for any amounts
    awarded against the Licensee in judgment or settlement of such claims, provided that:
    <ul>
      <li>Vendor is given prompt notice of any such claim;</li>
      <li>the Licensee provides reasonable co-operation to the Vendor in the defence and settlement of such claim, at the Vendor’s expense; and
      </li>
      <li>Vendor is given sole authority to defend or settle the claim.</li>
    </ul>
  </li>
  <li>In the defence or settlement of any claim, the Vendor may procure the right for the Licensee to continue using the Platform, or replace or modify the Platform so that they become non-infringing or, if such remedies are not reasonably available, terminate this Agreement on two business days’ notice to the Licensee without any additional liability or
    obligation to pay liquidated damages or other additional costs to the Licensee.
  </li>
  <li>In no event shall Vendor, its employees, agents and sub-contractors be liable to the
    Licensee to the extent that the alleged infringement is based on:
    <ul>
      <li>A modification of the Platform by anyone other than Vendor; or</li>
      <li>The Licensee’s use of the Platform in a manner contrary to the instructions given to the Licensee by the Vendor; or
      </li>
      <li>The Licensee’s use of the Platform after notice of the alleged or actual infringement from the Vendor or any appropriate authority.
      </li>
    </ul>
  </li>
  <li>The foregoing states the Licensee’s sole and exclusive rights and remedies, and Vendor’s (including Vendor’s employees’, agents’ and sub-contractors’)
    entire obligations and liability, for infringement of any intellectual property rights.
  </li>
</ol>


<h5>Warranties and Representations</h5>
<ol start="30">
  <li>
    The Vendor warrants and represents that it is the copyright holder of the Platform. The Vendor warrants and represents that granting the licence to use this Platform is not in violation of any other agreement, copyright or applicable statute.
  </li>
</ol>
<h5>Acceptance</h5>
<ol start="31">
  <li>By clicking the “I Agree” buttons, or using the Platform, the Licensee is agreeing to be bound by the terms of this Agreement (“Acceptance”). If a user does not agree to the terms of this Agreement,
    do not click on the “I Agree” button, or use the Platform.
  </li>
</ol>
<h5>User Support</h5>
<ol start="32">
  <li>No user support or maintenance is provided as part of this Agreement. </li>
  <li>The Vendor reserves the right to modify, suspend or discontinue, temporarily or permanently, the Platform or any service to which it connects,
    with or without notice and without liability to the Licensee.
  </li>
</ol>
<h5>Updates to the Platform</h5>
<ol start="34">
  <li>The Vendor may from time to time provide enhancements or improvements to the features/functionality of the Platform,
    which may include patches, bug fixes, updates, upgrades, and other modifications.
  </li>
  <li>Updates may modify or delete certain features and/or functionalities of the Platform. </li>
  <li>
    The Licensee agrees that the Vendor has no obligation to provide any updates, or
    continue to provide or enable any particular features and/or functionalities of the Platform to the Licensee. The Licensee further agrees that all updates or any other modifications will be (a) deemed to constitute an integral part of
    the Platform, and (b) subject to the terms and conditions of this Agreement.
  </li>
</ol>
<h5>Third-Party Services</h5>
<ol start="37">
  <li>The Platform may display, include or make available third-party content (including data, information, applications and other products services) or provide links to third-party websites or services.
  </li>
  <li>
    The Licensee acknowledges and agrees that the Vendor shall not be responsible for any third-party services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality, or any other aspect thereof. The Vendor does not assume and shall not have any liability or responsibility to the Licensee or any other person or entity for any third-party services. The Licensee must comply with applicable third parties' terms of agreement when using the Platform. Third-party services and links thereto are provided solely as a convenience to the Licensee and the Licensee accesses and uses them entirely at the
    Licensee’s own risk and subject to such third parties' terms and conditions.
  </li>
</ol>
<h5>Licensee acknowledgements</h5>
<ol start="39">
  <li>The Licensee acknowledges and agrees that:
    <ol type="a">
      <li>the Vendor is not a pharmaceutical manufacturer, a pharmacy business or a supplier of health services or advice. The Vendor is an order facilitation agent only and does not supply or sell any goods or products;</li>
      <li>the Licensee is solely responsible for maintaining the confidentiality of its Platform login, its registration information, and protecting its device from damage, theft or hacking;</li>
      <li>no one is permitted to access or use the Platform if they are under the age of 18;</li>
      <li>the continued availability of the Platform, and content provided through the operation and use of the Platform, may be subject to external factors out of the Vendor’s control including but not limited to routine maintenance, malfunction in equipment, hardware or software, internet access, and delay or failure of transmission;</li>
      <li>the Vendor may modify or update the Platform at any time without notice including but not limited to disabling or enabling certain features or functionalities of the Platform, introducing new features and functionalities to the Platform, bug fixes, error corrections, and workflow and design changes;</li>
      <li>the Licensee must not, and must ensure its employees, agents and related entities must not permit or enable others to:
        <ol type="i">
          <li>copy, reproduce, distribute, publicly display, transmit, sell, rent, lease or otherwise exploit the Platform and/or the intellectual property therein;</li>
          <li>distribute, sublicense, rent, lease, loan or allow any third-party access to or use of the Platform and/or intellectual property therein;</li>
          <li>remove, modify, vary, alter or obscure any trade mark, logo, copyright or other proprietary content, notices, legends, symbols or labels in the Platform and/or the intellectual property therein; or</li>
          <li>allow a user under the age of 18 to use the Platform or the functions and the information the Platform provides; and</li>
        </ol>
      </li>
      <li>the Licensee will take all reasonable steps to ensure that no unauthorised person will have access to the Platform or its device other than in accordance with the Privacy Policy and this Agreement.</li>
    </ol>
  </li>
  <li>For the avoidance of doubt, the Platform is provided to the Licensee on a non-exclusive basis. This Agreement shall not prevent the Vendor from entering into similar agreements with third parties, or from independently developing, using, selling or licensing documentation, products and/or services which are similar to those provided under this Agreement.</li>
  <li>This clause 41 applies where the Licensee is a pharmacy business only. The Licensee’s use of the Platform does not relieve the Licensee’s staff from complying with applicable legal and ethical responsibilities relating to the dispensing of medicines, provision of advice to patients, prescribing of medicines to patients, or the provision of pharmacy services, and the Licensee agrees that it will, and it will ensure that its pharmacy business' staff (including contractors and agents), comply with any such legal and ethical responsibilities.</li>
</ol>

<h5>Collection of data</h5>
<ol start="42">
  <li>
    The Licensee acknowledges that the Vendor may collect and use personal information through the Platform as set out in Script ITs Privacy Policy,
    which can be found here: <a class="text-primary font-weight-bold text-capitalize" (click)="openModal(smallModal)">Click Here</a> Script ITs Privacy Policy is incorporated into this Agreement.
  </li>
</ol>
<h5>Term</h5>
<ol start="43">
  <li>The term of this Agreement will begin on Acceptance and is perpetual. </li>
</ol>
<h5>Termination</h5>
<ol start="44">
  <li>The Vendor may, in its sole discretion, at any time and for any or no reason, suspend or terminate this Agreement with or without prior notice.
  </li>
  <li>This Agreement may be terminated by the Vendor where the Licensee has failed to comply with any of the terms of this Agreement or is in breach of this Agreement.
  </li>
</ol>
<h5>Force Majeure</h5>
<ol start="46">
  <li>The Vendor will be free of liability to the Licensee where the Vendor is prevented from executing its obligations under this Agreement in whole or in part due to force majeure, such as an earthquake, typhoon, flood, fire, or war, or any other unforeseen and uncontrollable
    event where the Vendor has taken any and all appropriate action to mitigate such an event.
  </li>
</ol>
<h5>Governing Law</h5>
<ol start="47">
  <li>The parties to this Agreement submit to the jurisdiction of the courts of the State of New South Wales for the enforcement of this Agreement or any arbitration award or decision arising from this Agreement. This Agreement will be enforced or
    construed according to the laws of the State of New South Wales.
  </li>
</ol>
<h5>Miscellaneous</h5>
<ol start="48">
  <li>The Vendor reserves the right, at any time, to change any terms of this Agreement including in respect of your access to and use of the Platform upon giving reasonable notice to you. Such notified changes are binding on you unless you terminate this Agreement by written notice to the Vendor within that reasonable notice period. The Vendor will not impose the changes until the reasonable notice period has expired. This Agreement does not create or imply any relationship in agency or partnership between the Vendor and the Licensee.</li>
  <li>The Vendor may transfer, sub-contract or otherwise deal with the Vendor’s rights and/or obligations under this Agreement without obtaining your consent. The Vendor will notify the Licensee of any transfer or assignment of rights and/or obligations under this Agreement within a reasonable time of such transfer or assignment.</li>
  <li>The Licensee may not transfer, sub-contract or otherwise deal with its rights and/or obligations under this Agreement without the prior written consent of the Vendor which shall not be unreasonably withheld.</li>
  <li>Headings are inserted for convenience of the parties only and are not to be considered when interpreting this Agreement. Words in the singular mean and include the plural and vice versa. Words in the masculine gender include the feminine gender and vice versa.</li>
  <li>If any term, covenant, condition or provision of this Agreement is held by a court of competent jurisdiction to be invalid, void or unenforceable, it is the parties’ intent that such provision be reduced in scope by the court only to the extent deemed necessary by that court to render the provision reasonable and enforceable and the remainder of the provisions of this Agreement will in no way be affected, impaired or invalidated as a result.</li>
  <li>This Agreement, together with the Script IT Privacy Policy and any purchase order, quote or onboarding form setting out any additional terms and conditions (including any relevant Fees) constitute the entire agreement between the parties. All understandings have been included in this Agreement and the other documentation listed in this clause 53. Representations which may have been made by any party to this Agreement may in some way be inconsistent with this final written Agreement. All statements are declared to be of no value in this Agreement. Only the written terms of this Agreement and the other documentation listed in this clause 53 will bind the parties.</li>
  <li>This Agreement and the terms and conditions contained in this Agreement apply to and are binding upon the Vendor’s successors and assigns.</li>
</ol>

<h5>Notices</h5>
<ol start="55">
  <li>All notices to the Vendor under this Agreement are to be provided at the following address:
    Script IT postal address: 7 Butterfield St, Herston, QLD 4006
    Script IT email address: info@scriptit.com.au
  </li>
</ol>


<ng-template #smallModal let-modal let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Script IT - Privacy Policy</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-privacy-policy></app-privacy-policy>
  </div>
</ng-template>
