<section id="login" class="page-wrapper">
  <div class="row auth-height full-height-vh m-0">
    <div class="col-12 p-0 m-0">
      <div class="row m-0">
        <div class="col-12 col-md-12 col-lg-12 d-flex align-items-center justify-content-center">
          <div>
            <div class="logo-box d-flex align-items-center justify-content-center">
              <img src="../../../../assets/img/scriptit_logo_background.png" alt="platform">
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-12  d-flex align-items-center justify-content-center">
          <div class="payment-container">
            <div class="header">
              <img src="assets/img/HeaderLogo.ee686e04b34072be3e83.svg" alt="BPOINT Logo" class="logo">
            </div>
            <!--        Payment Card Details filling-->
            <div class="payment-details" id="payment-block" *ngIf="!hideForm">
              <div class="row">
                <div class="col-md-12 col-12 col-sm-12 col-lg-12">
                  <h5 class="font-small-3 font-weight-bold">You are paying</h5>
                  <p>{{ paymentData.pharmacyName }}</p>
                </div>
                <div class="col-md-12 col-12 col-sm-12 col-lg-12 mt-1">
                  <h5 class="font-small-3 font-weight-bold">Biller Code</h5>
                  <p>{{ paymentData.billerCode }} - Online Payment - {{ paymentData.pharmacyName }}</p>
                </div>
                <div class="col-md-12 col-12 col-sm-12 col-lg-12 mt-1">
                  <h5 class="font-small-3 font-weight-bold">Reference 1</h5>
                  <p>{{ paymentData.crn1 }}</p>
                </div>
                <div class="col-md-12 col-12 col-sm-12 col-lg-12 mt-1">
                  <h5 class="font-small-3 font-weight-bold">Amount</h5>
                  <p>${{ this.displayAmount }}</p>
                </div>
                <div class="col-md-12 col-12 col-sm-12 col-lg-12 mt-1">
                  <h5 class="font-small-3 font-weight-bold">Accepted Cards</h5>
                  <img *ngFor="let card of paymentCard;" (click)="selectedCard(card)" [src]="card.logo"
                       [alt]="card.name"
                       width="50" [ngbTooltip]="card.name" placement="top" class="cursor-pointer ml-1">
                </div>
              </div>
            </div>
            <div class="card-info" *ngIf="!hideForm">
              <form [formGroup]="cardDetailsForm" (ngSubmit)="addPaymentDetails(confirmation)">
                <div class="row">
                  <div class="col-md-5 col-lg-5 col-sm-12 col-12">
                    <label class="font-small-3 font-weight-bold text-capitalize">Card number</label>
                    <div class="input-group">
                      <input type="text" placeholder="1234567890123456" class="form-control" maxlength="16"
                             formControlName="cardNumber">
                      <div class="input-group-prepend">
                    <span class="input-group-text cursor-pointer"> <img [src]="selectedPaymentCard.logo"
                                                                        [alt]="selectedPaymentCard.name"
                                                                        class="selected-logo"></span>
                      </div>
                    </div>
                    <small class="text-danger"
                           *ngIf="submitted && cardDetailsForm.controls['cardNumber'].hasError('required')">card number
                      is
                      required</small>
                    <small class="text-danger" *ngIf="cardDetailsForm.controls['cardNumber'].hasError('pattern')">Invalid
                      card number</small>
                  </div>
                  <div class="col-md-5 col-lg-5 col-sm-12 col-12">
                    <label class="font-small-3 font-weight-bold text-capitalize">Card Name</label>
                    <input type="text" placeholder="Enter full Name" class="form-control"
                           formControlName="userName">
                    <small class="text-danger"
                           *ngIf="submitted && cardDetailsForm.controls['userName'].hasError('required')">Card Name is
                      required</small>
                    <small class="text-danger" *ngIf="cardDetailsForm.controls['userName'].hasError('pattern')">Card
                      Name is
                      invalid</small>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-md-4 col-lg-4 col-sm-12 col-12">
                    <label class="font-small-3 font-weight-bold text-capitalize">Month</label>
                    <input type="text" class="form-control" formControlName="month" maxlength="2" placeholder="MM">
                    <small class="text-danger"
                           *ngIf="submitted &&  cardDetailsForm.controls['month'].hasError('required')">month is
                      required
                    </small> <small class="text-danger"
                                    *ngIf="cardDetailsForm.controls['month'].hasError('pattern')">Invalid
                    month
                  </small>
                  </div>
                  <div class="col-md-4 col-lg-4 col-sm-12 col-12">
                    <label class="font-small-3 font-weight-bold text-capitalize">Year</label>
                    <input type="text" class="form-control" formControlName="year" maxlength="2" placeholder="YY">
                    <small class="text-danger"
                           *ngIf="submitted && (cardDetailsForm.controls['year'].hasError('required'))">year
                      is required</small>
                    <small class="text-danger" *ngIf="cardDetailsForm.controls['year'].hasError('pattern')">Invalid
                      year</small>
                  </div>
                  <div class="col-md-4 col-lg-4 col-sm-12 col-12">
                    <label class="font-small-3 font-weight-bold text-capitalize">CVN</label>
                    <input type="text" id="cvn" formControlName="cvn" maxlength="3" placeholder="123"
                           class="form-control">
                    <small class="text-danger"
                           *ngIf="submitted && cardDetailsForm.controls['cvn'].hasError('required')">cvn
                      number is required</small>
                    <small class="text-danger" *ngIf="cardDetailsForm.controls['cvn'].hasError('pattern')">Invalid cvn
                      number</small>
                  </div>
                </div>
                <button type="submit" class="text-dark btn btn-large next">Next<i class="ft-chevron-right"></i></button>
              </form>
            </div>

            <!--        Payment Failed-->
            <p *ngIf="errorBlock.showBlock" style="font-weight: 500;"
               class="text-danger text-center">{{ this.errorBlock.orderStatus === 'dispense' ? 'Payment is completed you can close the window' : this.errorBlock.errorMessage }}</p>
            <div class="text-center mt-2" id="payment-link-block" *ngIf="errorBlock.showBlock">
              <a class="btn primary-btn" (click)="retryPayment()" *ngIf="showPaymentBtn">Retry Payment</a>
            </div>

            <!--        Payment Success -->
            <div class="row" id="result-block" *ngIf="hidepaymentPage && !errorBlock.showBlock">
              <div class="col-md-12 col-lg-12 col-sm-12 col-12 text-center">
                <div class="icon">
                  <span><i class="ft-check-circle text-success"></i></span>
                </div>
                <h4 class="d-none d-lg-block d-md-block d-xl-block" style="line-height: 30px;"
                    [innerHTML]="this.paymentResponseData.receiptNumber"></h4>
                <h5 class="modal-title d-sm-block d-lg-none d-md-none d-xl-none font-medium-1"
                    style="line-height: 30px;"
                    [innerHTML]="this.paymentResponseData.receiptNumber">
                </h5>
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 col-12">
                <div class="card-table-content d-none d-lg-block d-md-block d-xl-block">
                  <table>
                    <tr>
                      <td>Payment attempt date</td>
                      <td>{{ this.today }}</td>
                    </tr>
                    <tr>
                      <td>Biller Code</td>
                      <td>{{ paymentData.billerCode }} - Online Payment - {{ paymentData.pharmacyName }}</td>
                    </tr>
                    <tr>
                      <td>Reference 1</td>
                      <td>{{ paymentData.crn1 }}</td>
                    </tr>
                    <tr>
                      <td>Amount to pay today</td>
                      <td>AUD {{ paymentData.amount }}</td>
                    </tr>
                    <tr>
                      <td>Card number</td>
                      <td>{{ this.paymentResponseData.cardNumber }}</td>
                    </tr>
                    <tr>
                      <td>Expiry date</td>
                      <td>{{ this.paymentResponseData.month }}/{{ this.paymentResponseData.year }}</td>
                    </tr>
                  </table>
                </div>
                <div class="row d-sm-block d-lg-none d-md-none d-xl-none mt-2">
                  <div class="col-sm-12 col-12">
                    <p><b>Payment attempt date</b> <br>{{ this.today }}</p>
                  </div>
                  <div class="col-sm-12 col-12">
                    <p><b>Biller Code</b> <br>{{ paymentData.billerCode }} - Online Payment
                      - {{ paymentData.pharmacyName }}
                    </p>
                  </div>
                  <div class="col-sm-12 col-12">
                    <p><b>Reference 1</b> <br>
                      {{ paymentData.crn1 }}</p>
                  </div>
                  <div class="col-sm-12 col-12">
                    <p><b>Amount to pay today</b>
                      AUD <br> {{ paymentData.amount }}</p>
                  </div>
                  <div class="col-sm-12 col-12">
                    <p><b>Card number</b> <br>
                      {{ this.paymentResponseData.cardNumber }}</p>
                  </div>
                  <div class="col-sm-12 col-12">
                    <p><b>Expiry date </b><br>{{ this.paymentResponseData.month }}/{{ this.paymentResponseData.year }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="showMessage">
              <div class="col-md-12 col-lg-12 col-sm-12 col-12">
                <div class="countdown-timer text-center">
                  <p style="font-weight: 500;" class="text-danger">Payment is completed you can close the window</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>


<ng-template #confirmation let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Review Details</h4>
  </div>
  <div class="modal-body">
    <div class="payment-details">
      <p><strong>You are Paying</strong><br>{{ this.paymentData.pharmacyName }}</p>
      <p><strong>Biller Code</strong><br>{{ this.paymentData.billerCode }} - Online Payment
        - {{ this.paymentData.pharmacyName }}</p>
      <p><strong>Reference 1</strong><br>{{ this.paymentData.crn1 }}</p>
      <p><strong>Amount</strong><br>${{ this.displayAmount }}</p>
      <p><strong>Card Number</strong><br>{{ this.paymentBody.card.number }}</p>
      <p><strong>Expiry Date</strong><br>{{ this.paymentBody.card.expiry.month }}
        /{{ this.paymentBody.card.expiry.year }}</p>
    </div>
    <div class="row">
      <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-12 text-center p-1">
        <span class="btn btn-back" (click)="modal.dismiss('Cross-click')"><i class="ft-chevron-left"></i>BACK</span>
      </div>
      <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-12 text-center p-1">
        <button class="btn btn-pay" (click)="make_payment(modal)">PAY <i class="ft-chevron-right"></i></button>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>

<ngx-spinner></ngx-spinner>
