<section id="register" class="page-wrapper">
  <div class="row auth-height full-height-vh m-0">
    <div class="col-12 d-flex align-items-center justify-content-center">
      <div class="card">
        <div class="card-header">
          <h2>Registration Form</h2>
        </div>
        <div class="card-content">
          <div class="card-body">
            <form [formGroup]="userRegisteration" (ngSubmit)="onSubmit(termsConditions,consentForm)">
              <div class="row">
                <div class="col-md-6 col-12">
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="horizontal-form-1">First Name</label>
                    <div class="col-md-9">
                      <input type="text" class="form-control square" id="horizontal-form-1"
                             formControlName="firstName"
                      >
                      <small class="text-danger" *ngIf="submitted && rf.firstName.hasError('required')">FirstName is
                        required</small>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="horizontal-form-2">Middle Name</label>
                    <div class="col-md-9">
                      <input type="text" class="form-control square" id="horizontal-form-2"
                             formControlName="middleName">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-12">
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="horizontal-form-3">Last Name</label>
                    <div class="col-md-9">
                      <input type="text" class="form-control square" id="horizontal-form-3" formControlName="lastName">
                      <small class="text-danger" *ngIf="submitted && rf.lastName.hasError('required')">LastName is
                        required</small>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="horizontal-form-4">Email</label>
                    <div class="col-md-9">
                      <input type="text" class="form-control square" id="horizontal-form-4" formControlName="email">
                      <small class="text-danger" *ngIf="submitted && rf.email.hasError('required')">Email is
                        required</small>
                      <small class="text-danger" *ngIf="rf.email.hasError('email')">Invalid email</small>
                    </div>
                  </div>
                </div>

              </div>
              <div class="row">
                <div class="col-md-6 col-12">
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="horizontal-form-5">Phone Number</label>
                    <div class="col-md-9">
                      <input type="text" class="form-control square" id="horizontal-form-5" formControlName="number"
                             maxlength="10" minlength="8">
                      <div *ngIf="submitted && rf.number.hasError('required')">
                        <small class="text-danger">Phone number is required</small>
                      </div>
                      <div *ngIf="rf.number.hasError('pattern')">
                        <small class="text-danger">Invalid Phone number</small>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="horizontal-form-5">Landline Number</label>
                    <div class="col-md-9">
                      <input type="text" class="form-control square" formControlName="landLineNumber"
                             maxlength="10" minlength="8">
                      <small class="text-danger" *ngIf="submitted && rf.landLineNumber.hasError('required')">LandLine
                        number is
                        required</small>
                      <small class="text-danger" *ngIf="rf.landLineNumber.hasError('pattern')">Invalid Land Line
                        number</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-12">
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="horizontal-form-1">Password</label>
                    <div class="col-md-9">
                      <div class="input-group">
                        <input type="password" class="form-control square" #passsword formControlName="password">
                        <div class="input-group-prepend">
                          <span class="input-group-text cursor-pointer" (click)="showPassword(passsword,'password')"><i
                            [ngClass]="showpwd ? 'ft-eye' : 'ft-eye-off'"></i></span>
                        </div>
                      </div>
                      <small class="text-danger" *ngIf="submitted && rf.password.hasError('required')">Password is
                        required</small>
                      <div *ngIf="userRegisteration.controls['password'].hasError('pattern')">
                        <small class="text-danger">
                          Password must be at least seven characters long, contain at least one letter and one number.
                          <br>
                          Password must have atleast one of the special characters ! @ # $ % ^ & *<br>
                          Password must not use the same character repeatedly or have any sequential characters (for
                          example, AAAA or 1234)<br>
                          Password must not have sequential characters. <br>
                          Password must not be the same as one of your last four passwords. <br>
                        </small>
                      </div>
                      <div *ngIf="isSequential">
                        <small class="text-danger">Password must not use the same character repeatedly or have any
                          sequential characters (for example, AAAA or 1234)</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="horizontal-form-2">Confirm Password</label>
                    <div class="col-md-9">
                      <div class="input-group">
                        <input type="password" class="form-control square" #confirmpassword
                               formControlName="confirm_pwd" (change)="ismisMatch = false">
                        <div class="input-group-prepend">
                          <span class="input-group-text cursor-pointer"
                                (click)="showPassword(confirmpassword,'confirm_password')"><i
                            [ngClass]="showConfirmPassword ? 'ft-eye' : 'ft-eye-off'"></i></span>
                        </div>
                      </div>
                      <small class="text-danger" *ngIf="submitted && rf.confirm_pwd.hasError('required')">Confirm
                        Password is
                        required</small>
                      <div *ngIf="userRegisteration.controls['confirm_pwd'].hasError('pattern')">
                        <small class="text-danger">
                          Password must be at least seven characters long, contain at least one letter and one number.
                          <br>
                          Password must have atleast one of the special characters ! @ # $ % ^ & *<br>
                          Password must not use the same character repeatedly or have any sequential characters (for
                          example, AAAA or 1234)<br>
                          Password must not have sequential characters. <br>
                          Password must not be the same as one of your last four passwords. <br>
                        </small>
                      </div>
                      <div *ngIf="ismisMatch">
                        <small class="text-danger">Password is mismatch</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-12">
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="horizontal-form-5">Date Of Birth</label>
                    <div class="col-md-9">
                      <div class="input-group">
                        <input maxlength="10" class="form-control square" [minDate]="{year: 1901, month:1, day: 1}"
                               placeholder="yyyy-mm-dd" name="dp" ngbDatepicker (dateSelect)="this.isFuture_Date = false; this.isValid_Date = true;"
                               [(ngModel)]="model" [ngModelOptions]="{standalone:true}"
                               [maxDate]="maxDate"
                               #d2="ngbDatepicker">
                        <div class="input-group-append">
                          <div class="input-group-text" (click)="d2.toggle()">
                            <i class="fa fa-calendar" style="cursor: pointer;"></i>
                          </div>
                        </div>
                      </div>
                      <small class="text-danger">
                        {{
                          submitted && !model ? 'Date of Birth is required' : !isValid_Date ? 'Invalid Date' : this.isFuture_Date ? 'Future date is not allowed' : ''
                        }}
                      </small>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="horizontal-form-2">Address Type</label>
                    <div class="col-md-9">
                      <select formControlName="addressType" id="" class="form-control square">
                        <option value="Home">Home</option>
                        <option value="Work">Work</option>
                        <option value="Others">Others</option>
                      </select>
                      <small class="text-danger" *ngIf="submitted && rf.addressType.hasError('required')">AddressType is
                        required</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-12">
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="horizontal-form-2">Address</label>
                    <div class="col-md-9">
                      <input type="text" class="form-control square" formControlName="address">
                      <small class="text-danger" *ngIf="submitted && rf.address.hasError('required')">Address is
                        required</small>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="horizontal-form-2">Suburb</label>
                    <div class="col-md-9">
                      <input type="text" class="form-control square" formControlName="suburb">
                      <small class="text-danger" *ngIf="submitted && rf.suburb.hasError('required')">Suburb is
                        required</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-12">
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="horizontal-form-2">State</label>
                    <div class="col-md-9">
                      <ng-select formControlName="state" placeholder="Select State" [(ngModel)]="selectedState">
                        <ng-option *ngFor="let state of states" [value]="state">{{ state }}</ng-option>
                      </ng-select>
                      <small class="text-danger" *ngIf="submitted && rf.state.hasError('required')">State is
                        required</small>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="horizontal-form-2">Postcode</label>
                    <div class="col-md-9">
                      <input type="text" class="form-control square" formControlName="postcode" maxlength="4">
                      <small class="text-danger" *ngIf="submitted && rf.postcode.hasError('required')">Postcode is
                        required</small>
                      <small class="text-danger" *ngIf="userRegisteration.controls['postcode'].hasError('pattern')">Invalid
                        postcode</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12 d-flex justify-content-center align-items-center">
                  <p class="checkbox text-center text-capitalize">
                    <input type="checkbox" id="acceptTerms" #termsConditions>
                    <label for="acceptTerms"></label>I accept the
                    <a class="text-primary font-weight-bold text-capitalize" (click)="openModal(smallModal,'T&C')">terms
                      & conditions</a> and
                    <a class="text-primary font-weight-bold text-capitalize" (click)="openModal(smallModal,'P&P')">privacy
                      policy</a>
                  </p>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-12 d-flex justify-content-center align-items-center">
                  <p class="checkbox text-center text-capitalize">
                    <input type="checkbox" id="consentForm" #consentForm>
                    <label for="consentForm"></label>I wish to receive marketing material from the platform
                  </p>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-12 text-center">
                  <button type="submit" class="btn primary-btn mb-2"><i class="ft-check-square mr-1"></i>Sign Up
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #smallModal let-modal let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{ modalType === 'T&C' ? 'ScriptIT Terms & Conditions' : 'ScriptIT - Privacy Policy' }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-terms-conditions *ngIf="modalType === 'T&C'"></app-terms-conditions>
    <app-privacy-policy *ngIf="modalType !== 'T&C'"></app-privacy-policy>
  </div>
</ng-template>

<ngx-spinner></ngx-spinner>
