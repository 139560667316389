<div class="card">
  <div class="card-header">
    <h4>Update My Profile</h4>
  </div>
  <div class="card-content">
    <div class="card-body">
      <!--      User details update form starts-->

      <form [formGroup]="editProfile" (ngSubmit)="editMyProfile()">
        <div class="row">
          <div class="col-md-6 col-sm-12 col-lg-6 col-12">
            <label class="form-label">First Name</label>
            <input type="text" class="form-control" formControlName="firstName">
            <p *ngIf="submitted && lf.firstName.invalid" class="text-danger">First Name is required</p>
          </div>
          <div class="col-md-6 col-sm-12 col-lg-6 col-12">
            <label class="form-label">Middle Name</label>
            <input type="text" class="form-control" formControlName="middleName">
          </div>
          <div class="col-md-6 col-sm-12 col-lg-6 col-12">
            <label class="form-label">Last Name</label>
            <input type="text" class="form-control" formControlName="lastName">
            <p *ngIf="submitted && lf.lastName.invalid" class="text-danger">Last Name is required</p>
          </div>
          <div class="col-md-6 col-sm-12 col-lg-6 col-12">
            <label class="form-label">Email</label>
            <input type="email" class="form-control" formControlName="email">
            <p *ngIf="submitted && lf.email.invalid" class="text-danger">Email is required</p>
            <p *ngIf="lf.email.hasError('email')" class="text-danger">
              Invalid Email</p>
          </div>
          <div class="col-md-6 col-sm-12 col-lg-6 col-12">
            <label class="form-label">Phone number</label>
            <input type="tel" class="form-control" formControlName="number" maxlength="10">
            <p *ngIf="submitted && editProfile.controls['number'].invalid" class="text-danger">Phone number is
              required</p>
            <p *ngIf="lf.number.hasError('pattern')" class="text-danger">Invalid phone number</p>
          </div>
          <div class="col-md-6 col-sm-12 col-lg-6 col-12" *ngIf="this.userData.auth.data.role === 'User'">
            <label class="form-label">Land Line number</label>
            <input type="tel" class="form-control" formControlName="landLineNumber" maxlength="10">
            <p *ngIf="lf.landLineNumber.hasError('pattern')" class="text-danger">Invalid Land-Line Number</p>
          </div>
          <div class="col-md-6 col-sm-12 col-lg-6 col-12" *ngIf="this.userData.auth.data.role === 'User'">
            <label class="form-label">Date of Birth</label>
            <p class="px-1 py-1">{{ this.dateOfBirth }}</p>
          </div>
          <div class="col-md-12 col-sm-12 col-lg-12 col-12 d-flex justify-content-center align-items-center">
            <button type="submit" class="btn secondary-btn d-none d-sm-none d-lg-block d-md-block"><i
              class="ft ft-check-square mr-1"></i>Save
            </button>
            <button type="submit" class="btn secondary-btn btn-block  d-sm-block d-lg-none d-md-none"><i
              class="ft ft-check-square mr-1"></i>Save
            </button>
          </div>
        </div>
      </form>
<!--      User details update form ends-->
      <div class="row mt-2" *ngIf="userData.auth.data.role === 'User'">
        <div class="col-md-12 col-12 d-flex align-items-center justify-content-between">
          <h4 class="mb-0">User Address</h4>
          <button class="btn badge primary-btn" (click)="openModal(Address)">
            <i class="ft-plus mr-1"></i>Add Address
          </button>
        </div>
      </div>
      <div class="table-responsive mt-2" *ngIf="userData.auth.data.role === 'User'">
        <table class="table">
          <tr class="thead-light">
            <th>Address Type</th>
            <th>Address</th>
            <th>Suburb</th>
            <th>State</th>
            <th>Postcode</th>
            <th>Action</th>
          </tr>
          <tr *ngFor="let addr of this.userAddress">
            <th>{{ addr.addressType }}</th>
            <td>{{ addr.address }}</td>
            <td>{{ addr.suburb }}</td>
            <td>{{ addr.state }}</td>
            <td>{{ addr.postcode }}</td>
            <td><span (click)="openModal1(smallModal,addr)" class="cursor-pointer"><i
              class="ft-trash text-danger"></i></span></td>
          </tr>
        </table>
      </div>
      <span *ngIf="userAddress.length > 0" class="text-info mt-1 d-lg-none d-sm-block"><i class="ft-info mr-1"></i>Please scroll across for more detail</span>
    </div>
  </div>
</div>


<ng-template #Address let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Add New Address</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="addressForm" (ngSubmit)="submitAddress(Address)">
      <div class="row">
        <div class="col-md-6 col-12">
          <label>Address Type</label>
          <select formControlName="addressType" id="" class="form-control square">
            <option value="Home">Home</option>
            <option value="Work">Work</option>
            <option value="Others">Others</option>
          </select>
          <p class="text-danger" *ngIf="submitted && addressForm.controls['addressType'].invalid">Address Type is
            required</p>
        </div>
        <div class="col-12 col-md-6">
          <label>Address</label>
          <input type="text" class="form-control square" formControlName="address">
          <p class="text-danger" *ngIf="submitted && addressForm.controls['address'].invalid">Address is required</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <label>Suburb</label>
          <input type="text" class="form-control square" formControlName="suburb">
          <p class="text-danger" *ngIf="submitted && addressForm.controls['suburb'].invalid">Suburb is required</p>
        </div>
        <div class="col-12 col-md-6">
          <label>State</label>
          <ng-select formControlName="state" placeholder="Select State" [(ngModel)]="selectedState">
            <ng-option *ngFor="let state of states" [value]="state">{{ state }}</ng-option>
          </ng-select>
          <p class="text-danger" *ngIf="submitted && addressForm.controls['state'].invalid">State is required</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <label>Postcode</label>
          <input type="text" class="form-control square" formControlName="postcode" maxlength="4">
          <p class="text-danger" *ngIf="submitted && addressForm.controls['postcode'].invalid">Postcode is required</p>
          <p class="text-danger" *ngIf="addressForm.controls['postcode'].hasError('pattern')">Invalid postcode</p>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 text-right">
          <button type="submit" class="btn secondary-btn">Submit</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>


<ng-template #smallModal let-modal let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Delete Address</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-content">
    <div class="modal-body text-center">
      <p>Are you sure you want to delete this Address?</p>
      <div class="text-center">
        <button class="btn btn-danger" (click)="deleteAddress(modal)">Yes</button>
      </div>
    </div>
  </div>
</ng-template>
