import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../shared/auth/auth.service';
import {ToastrService} from 'ngx-toastr';
import {Spinner} from 'ngx-spinner/lib/ngx-spinner.enum';
import {NgxSpinnerService} from 'ngx-spinner';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {LocalStorageService} from '../../../shared/storage/local-storage.service';

@Component({
  selector: 'app-platform',
  templateUrl: './platform.component.html',
  styleUrls: ['./platform.component.scss']
})
export class PlatformComponent implements OnInit {

  // name: req.body.name,
  // primaryColour: req.body.primaryColour,
  // website: req.body.website,
  // secondaryColour: req.body.secondaryColour,
  // url: req.body.url,
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('tableRowDetails') tableRowDetails: any;
  @ViewChild('tableResponsive') tableResponsive: any;
  rows: any[];
  rowHeight: any;
  platform = new FormGroup({
    name: new FormControl('', [Validators.required]),
    primaryColour: new FormControl('', [Validators.required]),
    website: new FormControl('', [Validators.required]),
    secondaryColour: new FormControl('', [Validators.required]),
    url: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    number: new FormControl('', [Validators.required, Validators.pattern('[0-9]{10}|[0-9]{9}')]),
  });

  userRegisteration = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    middleName: new FormControl(' '),
    lastName: new FormControl('', [Validators.required]),
    number: new FormControl('', [Validators.required, Validators.pattern('[0-9]{10}|[0-9]{9}')]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    confirm_password: new FormControl('', [Validators.required]),
    // address: new FormControl('', [Validators.required]),
    // suburb: new FormControl('', [Validators.required]),
    // state: new FormControl('', [Validators.required]),
    // postcode: new FormControl('', [Validators.required, Validators.pattern('[0-9]{4}')]),
  });

  selectedAdmin: any;
  userData: any;
  submitted = false;
  submitted1 = true;
  selectedPlatForm: any;
  platformList: any[] = [];
  showblock = false;
  showForm = true;
  userCreated: any;
  superAdmins: any = [];
  showpwd = false;
  showConfirmPassword = false;
  file = null;

  constructor(private modalService: NgbModal, private authService: AuthService, private toastr: ToastrService, private storage: LocalStorageService,
              private spinner: NgxSpinnerService) {
  }

  async ngOnInit(): Promise<void> {
    this.storage.getData('script-capture').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.loadPlatform();
  }

  loadPlatform() {
    this.authService.fetch_all_platform(this.userData).then((resp) => {
      this.platformList = resp.data;
    })
  }

  open(content, data = {}, size = 'lg') {
    this.selectedAdmin = data;
    this.userRegisteration.controls['firstName'].setValue(this.selectedAdmin.firstName);
    this.userRegisteration.controls['lastName'].setValue(this.selectedAdmin.lastName);
    this.userRegisteration.controls['middleName'].setValue(this.selectedAdmin.middleName);
    this.userRegisteration.controls['email'].setValue(this.selectedAdmin.email);
    this.userRegisteration.controls['number'].setValue(this.selectedAdmin.number.split('+61')[1]);
    this.userRegisteration.controls['password'].setValue(this.selectedAdmin.password);
    this.userRegisteration.controls['confirm_password'].setValue(this.selectedAdmin.password);
    this.showblock = false;
    this.showForm = true;
    this.platform.reset();
    this.submitted = false;
    this.modalService.open(content, {size: size, backdrop: 'static'});
  }


  openModal(modal) {
    this.showblock = false;
    this.file = null;
    this.showForm = true;
    this.platform.reset();
    this.submitted = false;
    this.showpwd = false;
    this.showConfirmPassword = false;
    this.modalService.open(modal, {size: 'lg', backdrop: 'static'});
  }

  confirmModal(content, platform, size) {
    this.showblock = false;
    this.showForm = true;
    this.file = null;
    this.submitted = false;
    this.userRegisteration.reset();
    this.selectedPlatForm = platform;
    this.platform.controls['name'].setValue(this.selectedPlatForm.name);
    this.platform.controls['email'].setValue(this.selectedPlatForm.email);
    this.platform.controls['number'].setValue(this.selectedPlatForm.number.split('+61')[1]);
    this.platform.controls['primaryColour'].setValue(this.selectedPlatForm.primaryColour);
    this.platform.controls['website'].setValue(this.selectedPlatForm.website);
    this.platform.controls['secondaryColour'].setValue(this.selectedPlatForm.secondaryColour);
    this.platform.controls['url'].setValue(this.selectedPlatForm.url);
    this.modalService.open(content, {size: size, backdrop: 'static'});
  }


  deletePlatform(platform, modal) {
    this.selectedPlatForm = platform;
    this.modalService.open(modal, {size: 'md', backdrop: 'static'})
  }

  downloadLicenseData() {
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    this.authService.downloadLicense_globalProd(this.userData, this.selectedPlatForm._id).then((licenseData: any) => {
      if (licenseData.status === 404) {
        this.toastr.clear();
        this.toastr.error('Platform not found', 'Message', {
          positionClass: 'toast-top-center'
        });
        this.spinner.hide();
        return;
      }
      if (licenseData.status === 403) {
        this.toastr.clear();
        this.toastr.error('Logged in user is unable to generate a license file for the given pharmacy', 'Message', {
          positionClass: 'toast-top-center'
        });
        this.spinner.hide();
        return;
      }
      if (licenseData.status === 500) {
        this.toastr.clear();
        this.toastr.error('Internal Server Error', 'Message', {
          positionClass: 'toast-top-center'
        });
        this.spinner.hide();
        return;
      }
      this.downloadJson(licenseData);
      this.toastr.clear();
      this.toastr.success('License file downloaded successfully', 'Message', {
        positionClass: 'toast-top-center'
      });
      this.spinner.hide();
    }).catch(err => {
      console.log(err);
      this.spinner.hide();
    });
  }

  downloadJson(licenseData) {
    const jsonStr = JSON.stringify(licenseData, null, 2); // Convert object to JSON string
    const blob = new Blob([jsonStr], {type: 'application/json'}); // Create a Blob object
    const url = window.URL.createObjectURL(blob); // Generate a URL for the Blob

    // Create a temporary anchor element to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'license.json'; // File name
    a.click();

    // Clean up the URL to release resources
    window.URL.revokeObjectURL(url);
  }

  removePlatform(modal) {
    this.authService.deletePlatform(this.userData, this.selectedPlatForm).then((resp: any) => {
      this.toastr.clear();
      this.toastr.error('Platform Deleted Successfully', 'Deleted', {
        positionClass: 'toast-top-center'
      });
      this.loadPlatform();
      modal.dismiss('Cross-Click')
    }).catch(err => {
      this.toastr.clear();
      this.toastr.error('Platform Deletion failed', 'Error', {
        positionClass: 'toast-top-center'
      });
    })
  }


  edit_platform(modal) {
    this.submitted1 = true;
    if (this.platform.invalid) {
      return;
    }
    const body = [];
    for (const key in this.platform.controls) {
      if (key === 'number' && this.platform.controls[key].dirty) {
        body.push({
          propName: key, value: '+61' + this.platform.controls[key].value
        })
      } else {
        if (this.platform.controls[key].dirty) {
          body.push({
            propName: key, value: this.platform.controls[key].value
          })
        }
      }
    }

    this.authService.edit_Platform(this.userData, this.selectedPlatForm, body).then(async (resp: any) => {
      if (this.file) {
        this.spinner.show(undefined,
          {
            type: 'ball-triangle-path',
            size: 'medium',
            bdColor: 'rgba(0, 0, 0, 0.8)',
            color: '#fff',
            fullScreen: true
          });
        await this.authService.upload_platform_logo(this.userData, this.selectedPlatForm, this.file).then((resp: any) => {
          this.spinner.hide();
          if (resp.status === 403) {
            this.toastr.clear();
            this.toastr.error('Failed to upload the Platform Logo', 'Error', {
              positionClass: 'toast-top-center'
            });
          } else {
            this.toastr.clear();
            this.toastr.success('Platform Logo Uploaded', 'Success', {
              positionClass: 'toast-top-center'
            });
            this.loadPlatform();
            this.modalService.dismissAll();
          }
        }).catch(err => {
          this.spinner.hide();
          this.toastr.clear();
          this.toastr.error('Platform Logo Upload failed', 'Error', {
            positionClass: 'toast-top-center'
          });
        })
      }
      this.toastr.clear();
      this.toastr.success('Platform Updated Successfully', 'Updated', {
        positionClass: 'toast-top-center'
      });
      this.loadPlatform();
      this.platform.reset();
      this.showblock = true;
      this.showForm = false;
      this.submitted1 = false;
      modal.dismiss('Cross-Click');
    }).catch(err => {
      this.toastr.clear();
      this.toastr.error('Platform Updation failed', 'Error', {
        positionClass: 'toast-top-center'
      });
    })
  }

  rowDetailsToggleExpand(row) {
    this.selectedPlatForm = row;
// TODO design to add the fields...
    this.authService.fetch_superAdmin_Platform(this.userData, row).then((response: any) => {
      this.superAdmins = response.body;
      this.tableRowDetails.rowDetail.collapseAllRows();
      this.rowHeight = row.height;
      if (row.expand) {
        this.tableRowDetails.rowDetail.collapseAllRows();
        this.platformList.filter(itm => {
          itm.expand = false;
        })
      } else {
        this.tableRowDetails.rowDetail.toggleExpandRow(row);
        row.expand = true;
      }
    })


  }


  create_platform() {
    this.submitted = true;
    if (this.platform.invalid) {
      return;
    }

    const body = {
      name: this.platform.value.name,
      primaryColour: this.platform.value.primaryColour,
      website: this.platform.value.website,
      email: this.platform.value.email,
      number: '+61' + this.platform.value.number,
      secondaryColour: this.platform.value.secondaryColour,
      url: this.platform.value.url,
    }

    this.authService.create_platform(this.userData, body).then(async (resp: any) => {
      if (resp.status === 400) {
        this.toastr.clear();
        this.toastr.error('Platform already exists', 'Error', {
          positionClass: 'toast-top-center'
        });
      }
      if (resp.status === 500) {
        this.toastr.clear();
        this.toastr.error('Platform creation failed', 'Error', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.clear();
        this.selectedPlatForm = resp.platform_created;
        this.toastr.success('Platform created Successfully', 'Created', {
          positionClass: 'toast-top-center'
        });
        if (this.file) {
          this.spinner.show(undefined,
            {
              type: 'ball-triangle-path',
              size: 'medium',
              bdColor: 'rgba(0, 0, 0, 0.8)',
              color: '#fff',
              fullScreen: true
            });
          await this.authService.upload_platform_logo(this.userData, this.selectedPlatForm, this.file).then((resp: any) => {
            this.spinner.hide();
            if (resp.status === 403) {
              this.toastr.clear();
              this.toastr.error('Failed to upload the Platform Logo', 'Error', {
                positionClass: 'toast-top-center'
              });
            } else {
              this.toastr.clear();
              this.toastr.success('Platform Logo Uploaded', 'Success', {
                positionClass: 'toast-top-center'
              });
              this.loadPlatform();
              this.modalService.dismissAll();
            }
          }).catch(err => {
            this.spinner.hide();
            this.toastr.clear();
            this.toastr.error('Platform Logo Upload failed', 'Error', {
              positionClass: 'toast-top-center'
            });
          })
        }
        this.showblock = true;
        this.showForm = false;
        this.loadPlatform();
        this.platform.reset();
      }
    }).catch(err => {
      this.toastr.error('Platform creation failed', 'Error', {
        positionClass: 'toast-top-center'
      });
      this.modalService.dismissAll();
    })
  }

  get rf() {
    return this.userRegisteration.controls;
  }

  uploadLogo(file) {

    if (file.target.files[0].type === 'image/gif') {
      this.toastr.error('GIF images not allowed', 'Error', {
        positionClass: 'toast-top-center'
      });
      this.spinner.hide();
      return;
    }
    this.file = file.target.files[0];


  }

  filterUpdate(value) {
    if (value.toLowerCase().trim() === '') {
      this.loadPlatform();
    } else {
      const val = value.toLowerCase().trim();
      const res = this.platformList.filter((d) => {
        return d.name.toLowerCase().indexOf(val) !== -1 || !val;
      });
      if (res.length === 0) {
        this.toastr.clear();
        this.toastr.error('No Platform found', 'Error', {
          positionClass: 'toast-top-center'
        });
        this.platformList = [];
      } else {
        this.platformList = res;
      }
    }
  }

  async onSubmit(modal) {
    this.submitted = true;
    if (this.userRegisteration.invalid) {
      return;
    }

    if (this.userRegisteration.controls['password'].value !== this.userRegisteration.controls['confirm_password'].value) {
      this.toastr.error('Password mismatch', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }

    const body = {
      'firstName': this.userRegisteration.value.firstName,
      'middleName': this.userRegisteration.value.middleName,
      'lastName': this.userRegisteration.value.lastName,
      'email': this.userRegisteration.value.email,
      'password': this.userRegisteration.value.password,
      'number': '+61' + parseInt(this.userRegisteration.value.number, 10),
      'twofaEnabled': true,
      'role': 'Super Admin',
      'userType': 'manager',
      'platformId': this.selectedPlatForm._id
    }
    this.authService.register_new_user(body).then(async (resp: any) => {
      if (resp.status === 403) {
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else {
        if (resp.status === 400) {
          this.toastr.error('User already Exits', 'Error', {
            positionClass: 'toast-top-center'
          });
        } else {
          this.userCreated = resp.userCreated;
          this.toastr.success('User Registration Successfull', 'Message', {
            positionClass: 'toast-top-center'
          });
          this.authService.fetch_superAdmin_Platform(this.userData, this.selectedPlatForm).then((response: any) => {
            this.superAdmins = response.body;
          });
          this.submitted = false;
          this.userRegisteration.reset();
        }
      }

    }).catch(err => {
      this.toastr.error('User registration failed', 'Error', {
        positionClass: 'toast-top-center'
      });
    })
    modal.dismiss('Cross-Click')
  }


  loadSuperAdmin() {
    this.authService.fetch_superAdmin_Platform(this.userData, this.selectedPlatForm).then((response: any) => {
      this.superAdmins = response.body;
    });
  }


  removeSuperAdmin(modal: any) {
    this.authService.delete_User(this.userData, this.selectedAdmin, 'true').then((resp) => {
      if (resp.status === 500) {
        this.toastr.clear();
        this.toastr.error('Super Admin Deletion failed', 'Deleted!', {
          positionClass: 'toast-top-center'
        });
        modal.dismiss('Cross-Click');
        return;
      }
      this.toastr.error('Super Admin Deleted Successfully', 'Deleted!', {
        positionClass: 'toast-top-center'
      });
      this.loadSuperAdmin();
      modal.dismiss('Cross-Click');
    }).catch(err => {
      this.toastr.error('Super Admin Deletion failed', 'Error', {
        positionClass: 'toast-top-center'
      });
    })
  }

  updateUser(modal: any) {
    this.submitted = true;
    if (this.userRegisteration.invalid) {
      return;
    }
    let body = [];
    for (const key in this.userRegisteration.controls) {
      if (key === 'password' || key === 'confirm_password') {
        continue;
      } else if (key === 'number' && this.userRegisteration.controls[key].dirty) {
        body.push({
          propName: 'number', value: '+61' + this.userRegisteration.controls[key].value
        });
      } else if (this.userRegisteration.controls[key].dirty) {
        body.push({
          propName: key, value: this.userRegisteration.controls[key].value
        })
      }
    }
    if (body.length === 0) {
      this.toastr.error('Edit any of the fields', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    this.authService.update_user_details(this.userData, body, this.selectedAdmin._id).then((response: any) => {
      if (response.status === 403) {
        this.toastr.error(response.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else if (response.status === 500) {
        this.toastr.error('Super Admin details updation failed', 'Error', {
          positionClass: 'toast-top-center'
        });
        this.submitted = false;
        body = [];
        return;
      } else {
        this.toastr.success('Super Admin Details Updated', 'Success', {
          positionClass: 'toast-top-center'
        });
        body = [];
        this.submitted = false;
        this.loadSuperAdmin();
        modal.dismiss('Cross-Click')
      }
    }).catch(err => {
      this.toastr.error('Super Admin details updation failed', 'Error', {
        positionClass: 'toast-top-center'
      });
    })
  }

  showPassword(password, passwordType) {
    if (passwordType === 'password') {
      this.showpwd = !this.showpwd;
      password.type = this.showpwd ? 'text' : 'password';
    } else {
      this.showConfirmPassword = !this.showConfirmPassword;
      password.type = this.showConfirmPassword ? 'text' : 'password';
    }
  }
}
