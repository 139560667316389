import {Component, HostListener, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ModalDismissReasons, NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {AuthService} from '../../../../shared/auth/auth.service';
import {MonthFormatPipe} from '../../../../shared/pipes/month-format.pipe';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {DownloadFileService} from '../../../../shared/services/downloadFile.service';
import {Order} from '../orders/order';
import {LocalStorageService} from '../../../../shared/storage/local-storage.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {NgxIntlTelInputComponent} from 'ngx-intl-tel-input';

const moment = require('moment');

@Component({
  selector: 'app-my-scripts',
  templateUrl: './my-scripts.component.html',
  styleUrls: ['./my-scripts.component.scss']
})
export class MyScriptsComponent implements OnInit {
  date = new Date();
  userData: any;
  @ViewChild('phoneInput', {static: false}) phoneInput!: NgxIntlTelInputComponent;
  phoneDialCode = '61';
  pharmacy: any;
  submitted = false;
  page = 1;
  closeResult = '';
  orderList: any[] = [];
  orderedItems: any = [];
  rows: any[] = [];
  selectedScripts = [];
  rowHeight = 0;
  maxPageSize = 0;
  EnableRepeat = false;
  platform: any;
  showUrl = false;
  isCollapsed = true;
  pharmacyUser: any;
  customerNotes = [];
  selectedOrder: Order | any;
  ordersMessage = 'There are no scripts currently';
  globalSelectedStatus = '';
  token_updation = {
    'orderId': '',
    'newToken': '',
    'repeats': '',
    'duration': '',
  };
  scriptForm = new FormGroup({
    token: new FormControl('',
      [Validators.pattern(/^(1|2)[A-Za-z0-9]{17,20}$|^3[A-Za-z0-9]{14,17}-(?:[1-9]|[1-4][0-9]|50)$/
      )
        , Validators.required])
  });
  erxResponse = [];
  disablebtn = false;
  viewScript = '';
  max = 0;
  pagNum = 1;
  message = 'Thank you for your order the pharmacy will contact you shortly.';
  valid = false;
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('tableRowDetails') tableRowDetails: any;
  @ViewChild('tableResponsive') tableResponsive: any;
  selectedScript: any;
  ismatch = false;
  tokens = [];
  isRefreshing = false;
  responeCount = 0;
  firstLoad = false;

  constructor(private authService: AuthService, private monthPipe: MonthFormatPipe, private spinner: NgxSpinnerService,
              private modalService: NgbModal, private downloadFile: DownloadFileService, private storage: LocalStorageService,
              private toastr: ToastrService, public formatter: NgbDateParserFormatter, private calendar: NgbCalendar,
              public router: Router) {
  }

  async ngOnInit(): Promise<void> {
    this.storage.getData('script-capture').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.platform = JSON.parse(sessionStorage.getItem('platform'));
    // this.loadOrders();
    this.fetchUsers_scripts();

  }

  loadOrders() {
    const today = moment(new Date()).format();
    this.authService.fetch_my_orders(this.userData, this.platform, this.page, this.userData.auth.data._id, true, '', 5).then((resp: any) => {
      if (resp.response === 'No orders found for this customer') {
        this.rows = [];
        this.ordersMessage = 'There are no scripts currently';
      } else {
        this.rows = resp.results.filter((order) => {
          return !order.deleted;
        });
        if (resp.results.length === 0) {
          this.rows = [];
          return;
        }
        this.filter('all')
        this.maxPageSize = resp.totalPages;
        this.rows.filter((order) => {
          if (moment(order.orderDate).format('l') === moment(today).format('l')) {
            order.enableBtn = true;
          } else {
            order.enableBtn = false;
          }
          return order;
        });
        this.rows.filter(itm => {
          itm.isAfterDate = moment(new Date()).isAfter(moment(itm.orderDate));
          itm.isCurrentDate = moment(itm.orderDate).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD');
          itm.height = (60 * itm.orderedItem.length) + 110;
        });
      }
    });
  }

  filter(status: string) {
    this.globalSelectedStatus = status;
    if (status !== 'all') {
      const result = this.rows.filter(order => {
        return order.status === status;
      })
      this.rows = result;
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else if (reason === 'x-key-press') {
      return 'by pressing the X key';
    } else {
      return `with: ${reason}`;
    }
  }

  onCheckSelectScript(script, event) {
    if (event.target.checked) {
      this.selectedScripts.push(script._id);
    } else {
      const temp = this.selectedScripts.filter(data => data !== script._id);
      this.selectedScripts = temp;
    }
  }


  viewScript_image(uploadedScript, modal) {
    this.viewScript = uploadedScript;
    this.modalService.open(modal, {size: 'lg', backdrop: 'static'});
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if ((event.key === 'x' || event.key === 'X') && event.target instanceof HTMLElement && event.target.tagName !== 'INPUT' && event.target.tagName !== 'TEXTAREA') {
      this.modalService.dismissAll('x-key-press');
    }
  }


  rowDetailsToggleExpand(row) {
    this.tableRowDetails.rowDetail.collapseAllRows();
    this.rowHeight = row.height;
    if (row.expand) {
      this.tableRowDetails.rowDetail.collapseAllRows();
      this.rows.filter(itm => {
        itm.expand = false;
      })
    } else {
      this.tableRowDetails.rowDetail.toggleExpandRow(row);
      row.expand = true;
    }
  }

  cancelOrder(textbox) {
    const body = [{
      propName: 'status',
      value: 'cancelled'
    }, {
      'propName': 'cancellation_reason.reason',
      'value': textbox.value
    }];
    this.authService.updateOrderStatus(this.userData, body, this.selectedOrder).then((resp) => {
      this.toastr.clear();
      this.toastr.error('Order Cancelled', 'Message', {
        positionClass: 'toast-top-center'
      });
      this.loadOrders();
      this.filter('pending');
      this.modalService.dismissAll();
    }).catch(err => {
      this.toastr.clear();
      this.toastr.error('Order Cancellation Failed!', 'Error', {
        positionClass: 'toast-top-center'
      });
    })
  }


  ViewOrderModal(order: any, content) {
    this.isCollapsed = true;
    this.authService.getOrder_by_Id(this.userData, order._id).then(async (response: any) => {
      this.selectedOrder = response;
      this.pharmacyUser = response.userId;
      this.customerNotes = response.notes.filter(data => data.customerNote);
      this.showUrl = /\bhttps?:\/\/\S+?\.(?:jpg|jpeg|png|gif|webp)\b/.test(order.uploadedScript) ? true : false;
      this.selectedOrder.totalQuantity = 0;
      this.selectedOrder.totalCost = 0;
      this.orderedItems = response.orderedItem;
      this.orderedItems.map((data) => {
        this.selectedOrder.totalCost = data.price;
        this.selectedOrder.totalQuantity += data.quantity;
      })
      this.modalService.open(content, {windowClass: 'full-screen-modal', backdrop: 'static'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    });

  }

  downloadScript(scriptLink) {
    const s3Url = scriptLink; // Replace with your S3 URL
    this.downloadFile.downloadFileFromCloud(s3Url);
  }

  updateToken(token, order, index, modal) {
    let body = [];
    if (token === '') {
      this.toastr.clear();
      this.toastr.error('Please Enter the Token', 'Message', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    body.push({
      propName: 'orderedItem.' + index + '.' + 'token',
      value: token
    });

    this.authService.update_order(this.userData, body, this.selectedOrder).then((resp: any) => {
      if (resp.status === 500) {
        this.toastr.clear();
        this.toastr.error('Token updation failed', 'Message', {
          positionClass: 'toast-top-center'
        });
      } else if (resp.status === 403) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.clear();
        this.toastr.success('Token Updated Successfully', 'Updated', {
          positionClass: 'toast-top-center'
        });
        body = [];
        this.selectedOrder = resp.updatedBody;
        this.orderedItems = this.selectedOrder.orderedItem;
        this.orderedItems.map((data) => {
          this.selectedOrder.totalCost += data.price;
          this.selectedOrder.totalQuantity += data.quantity;
        });
        this.loadOrders();
        modal.dismiss('Cross-Click');
      }
    }).catch(err => {
      this.toastr.clear();
      this.toastr.error('Ordered items udpation failed', 'Error', {
        positionClass: 'toast-top-center'
      });
    })
  }


  fillDetails(newToken, Order, index: number, modal: any, confim: any) {
    if (newToken === '') {
      this.toastr.clear();
      this.toastr.error('Please Provide the token', 'Message', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    const obj = {
      'orderId': '', 'items': []
    }
    this.token_updation.newToken = newToken === '' ? ' ' : newToken;
    this.token_updation.repeats = ''
    this.token_updation.duration = ''
    this.token_updation.orderId = Order._id;
    obj.orderId = Order._id;
    obj.items.push({
      'token': newToken.trim(),
    });
    this.modalService.open(confim, {size: 'md', backdrop: 'static'})

  }

  openModal(Modal1: any, medi: any, message: string, size = 'xl') {
    this.selectedOrder = medi;
    this.valid = false;
    this.disablebtn = false;
    this.message = message;
    this.modalService.open(Modal1, {size: size, backdrop: 'static'});
  }

  copyToClipboard(token: string) {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(token)
        .then(() => {
          this.toastr.clear();
          this.toastr.info('Text copied to clipboard successfully', 'Copied!', {
            positionClass: 'toast-top-center'
          });
        })
        .catch(err => {
          console.error('Could not copy text: ', err);
        });
    }
  }

  reOrder(modal: any) {
    this.authService.re_order(this.userData, this.selectedOrder, this.token_updation).then((resp: any) => {
      if (resp.status === 400) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else if (resp.status === 500) {
        this.toastr.clear();
        this.toastr.error('Re-order Failed', 'Error', {
          positionClass: 'toast-top-center'
        });
      } else if (resp.status === 403) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.clear();
        this.toastr.success('Re-ordered successfully', 'Success', {
          positionClass: 'toast-top-center'
        });
        this.loadOrders();
        this.EnableRepeat = false;
        this.token_updation = {
          'orderId': '',
          'newToken': '',
          'repeats': '',
          'duration': '',
        }
        modal.dismiss('Cross-Click');
        this.modalService.dismissAll();
      }
    });
  }

  showGlobalProd(globalProductId: any, filteredResponse = false) {
    const queryParams = {data: JSON.stringify(globalProductId)};
    const urlWithQueryParams = this.router.createUrlTree(['/product-page'], {queryParams}).toString();
    window.open(urlWithQueryParams, '_blank');
  }


  validateToken(value: string) {
    const pattern = /^(?!\s).*$/;
    if (value.trim() === '') {
      this.valid = true;
      return;
    }
    if (!pattern.test(value.trim())) {
      this.valid = true;
    } else {
      this.valid = false;
    }
  }

  sendNote(note, modal) {
    if (note.value.trim() === '' || !/^(?!\s).*$/.test(note)) {
      this.toastr.clear();
      this.toastr.error('Customer notes must not be left blank', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    this.disablebtn = true;
    const body = {'fallowUp_note': note.value};
    this.authService.update_note(this.userData, body, this.selectedOrder, true).then((resp: any) => {
      if (resp.status === 500) {
        this.disablebtn = false;
        return;
      }
      this.toastr.clear();
      this.toastr.info('Note updated', 'Info', {
        positionClass: 'toast-top-center'
      });
      note.value = '';
      this.disablebtn = false;
    }).catch(err => {
      this.disablebtn = false;
    })
  }

  pagination(page: number, type = 'preOrder') {
    this.page = page;
    if (type === 'preOrder') {
      this.authService.fetch_my_orders(this.userData, this.platform, this.page, this.userData.auth.data._id, true, 'preOrder', 5).then((resp: any) => {
        if (resp.response === 'No scripts found for this customer') {
          this.ordersMessage = 'There are no scripts currently';
        } else {
          this.orderList = resp.results;
          this.maxPageSize = resp.totalPages;
          this.filter('all')
          this.rows = this.orderList.filter((order) => {
            return !order.deleted;
          });
          this.rows.filter((order) => {
            if (moment(order.orderDate).format('l') === moment(new Date()).format('l')) {
              order.enableBtn = true;
            } else {
              order.enableBtn = false;
            }
            return order;
          });
          this.rows.filter(itm => {
            itm.isAfterDate = moment(new Date()).isAfter(moment(itm.orderDate));
            itm.isCurrentDate = moment(itm.orderDate).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD');
            itm.height = (60 * itm.orderedItem.length) + 110;
          });
        }
      });
    } else {
      this.authService.fetchUserScripts(this.userData, this.userData.auth.data._id, this.pagNum).then((response: any) => {
        if (response.message === 'The total scripts length is Zero') {
          this.erxResponse = [];
          this.max = 0;
        } else {
          this.erxResponse.map((data) => {
            data.status = data?.medicationObject[0].status === 'active' ? 'active' : data?.medicationObject[0].status;
            data.medicationObject.map((medicine) => {
              if (!Object.keys(medicine).includes('repeats')) {
                medicine.repeats = 0;
              }
            });
            return data;
          });
          this.erxResponse.sort((a, b) => {
            if (a.status === 'active' && b.status !== 'active') {
              return -1;
            }
            if (a.status !== 'active' && b.status === 'active') {
              return 1;
            }
            const timeA = moment(a.createdAt).format('HH:mm:ss');
            const timeB = moment(b.createdAt).format('HH:mm:ss');
            return timeB.localeCompare(timeA); // Sort lexicographically by time
          });
          this.max = response.totalPages;
        }

      });
    }

  }

  openScriptModal(addScriptToken) {
    this.submitted = false;
    this.scriptForm.reset();
    this.disablebtn = false;
    this.modalService.open(addScriptToken, {size: 'md', backdrop: 'static'});
  }


  add(modal, smallModal) {
    this.submitted = true;
    if (this.scriptForm.invalid) {
      return;
    }
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    this.disablebtn = true;
    this.authService.getScriptToken(this.userData, this.scriptForm.value.token.trim().toUpperCase()).then(async (response: any) => {
      if (response.status === 400) {
        this.disablebtn = false;
        this.spinner.hide();
        this.toastr.clear();
        this.toastr.error(response.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      if (response.status === 401) {
        this.disablebtn = false;
        this.spinner.hide();
        this.toastr.clear();
        this.toastr.error(response.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        modal.dismiss('Cross-Click');
        return;
      }
      if (response.status === 500) {
        this.disablebtn = false;
        this.spinner.hide();
        this.toastr.clear();
        this.toastr.error('Oops! Looks like something went wrong. Please try again later!', 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      if (Array.isArray(response)) {
        const body = {
          'userId': this.userData.auth.data._id,
          'medicationObject': response
        }
        await this.authService.createScript(this.userData, body).then((scriptResponse: any) => {
          if (scriptResponse.status === 404) {
            this.toastr.clear();
            this.disablebtn = false;
            this.spinner.hide();
            this.toastr.error(scriptResponse.error.message, 'Error', {
              positionClass: 'toast-top-center'
            });
            return;
          }
          if (scriptResponse.status === 403) {
            this.toastr.clear();
            this.disablebtn = false;
            this.spinner.hide();
            this.toastr.error(scriptResponse.error.message, 'Error', {
              positionClass: 'toast-top-center'
            });
            return;
          }
          if (scriptResponse.status === 400) {
            this.toastr.clear();
            this.disablebtn = false;
            this.spinner.hide();
            this.toastr.error(scriptResponse.error.message, 'Error', {
              positionClass: 'toast-top-center'
            });
            return;
          }
          this.toastr.clear();
          this.toastr.success('Script Created Successfully', 'Message', {
            positionClass: 'toast-top-center'
          });
          this.modalService.open(smallModal, {size: 'md', backdrop: 'static'});
          this.fetchUsers_scripts();
          this.spinner.hide();
          modal.dismiss('Cross-Click');
        }).catch(err => {
          this.toastr.clear();
          this.spinner.hide();
          this.toastr.error(err.error.message, 'Error', {
            positionClass: 'toast-top-center'
          });
          this.disablebtn = false;
        });
        return;
      }
      this.spinner.hide();
      this.toastr.clear();
      this.disablebtn = false;
      if (Object.keys(response).includes('IHI_number')) {
        if (!response.IHI_number) {
          this.toastr.info(response.status === '' ? 'Token status not found' : 'This token is already ' + response.status, 'Info', {
            positionClass: 'toast-top-center'
          });
          return;
        }
        const body = {
          'userId': this.userData.auth.data._id,
          'medicationObject': response.medications
        }
        await this.authService.createScript(this.userData, body).then((scriptResponse: any) => {
          if (scriptResponse.status === 404) {
            this.toastr.clear();
            this.disablebtn = false;
            this.spinner.hide();
            this.toastr.error(scriptResponse.error.message, 'Error', {
              positionClass: 'toast-top-center'
            });
            return;
          }
          if (scriptResponse.status === 403) {
            this.toastr.clear();
            this.disablebtn = false;
            this.spinner.hide();
            this.toastr.error(scriptResponse.error.message, 'Error', {
              positionClass: 'toast-top-center'
            });
            return;
          }
          if (scriptResponse.status === 400) {
            this.toastr.clear();
            this.disablebtn = false;
            this.spinner.hide();
            this.toastr.error(scriptResponse.error.message, 'Error', {
              positionClass: 'toast-top-center'
            });
            return;
          }
          this.toastr.clear();
          this.toastr.success('Script Created Successfully', 'Message', {
            positionClass: 'toast-top-center'
          });
          this.modalService.open(smallModal, {size: 'md', backdrop: 'static'});
          this.fetchUsers_scripts();
          this.spinner.hide();
          modal.dismiss('Cross-Click');
        }).catch(err => {
          this.toastr.clear();
          this.spinner.hide();
          this.toastr.error(err.error.message, 'Error', {
            positionClass: 'toast-top-center'
          });
          this.disablebtn = false;
        });
      } else {
        this.toastr.error('Oops! Looks like something went wrong. Please try again later!', 'Error', {
          positionClass: 'toast-top-center'
        });
      }

      modal.dismiss('Cross-Click');
    }).catch(err => {
      this.spinner.hide();
      modal.dismiss('Cross-Click');
      this.disablebtn = false;
    })
  }

  fetchUsers_scripts() {
    this.authService.fetchUserScripts(this.userData, this.userData.auth.data._id, this.pagNum).then(async (response: any) => {
      if (response.response === 'The total scripts length is Zero') {
        this.erxResponse = [];
        this.max = 0;
        this.firstLoad = false;
      } else {
        if (response.response === 'The total scripts length is Zero') {
          this.erxResponse = [];
          this.max = 0;
          this.firstLoad = false;
        } else {
          this.erxResponse = response.results;
          this.erxResponse.map((data) => {
            data.status = data?.medicationObject[0].status === 'active' ? 'active' : data?.medicationObject[0].status;
            data.medicationObject.map((medicine) => {
              if (!Object.keys(medicine).includes('repeats')) {
                medicine.repeats = 0;
              }
            });
            return data;
          });
          this.erxResponse.sort((a, b) => {
            if (a.status === 'active' && b.status !== 'active') {
              return -1;
            }
            if (a.status !== 'active' && b.status === 'active') {
              return 1;
            }
            const timeA = moment(a.createdAt).format('HH:mm:ss');
            const timeB = moment(b.createdAt).format('HH:mm:ss');
            return timeB.localeCompare(timeA); // Sort lexicographically by time
          });
          this.max = response.totalPages;
          // refresh tokens on first load if they have any
          if (this.firstLoad) {
            this.firstLoad = false;
            await this.refreshTokens();
          }
        }
      }
    });
  }


  Order_now() {
    this.router.navigate(['/new-order'], {queryParams: {data: JSON.stringify(this.selectedScripts)}});
  }

  sendScript(script) {
    this.router.navigate(['/new-order'], {queryParams: {data: JSON.stringify([script._id])}});
  }

  onCountryChange(event: any, phone: NgxIntlTelInputComponent): void {
    this.phoneInput = phone;
    this.phoneDialCode = this.phoneInput.selectedCountry.dialCode;
  }

  sendMyScript(modal, phone: NgxIntlTelInputComponent) {
    this.phoneInput = phone;
    this.submitted = true;
    if (this.phoneDialCode === '' && this.submitted) {
      return;
    }
    if (this.phoneInput.phoneNumber === '') {
      return;
    }
    let medication = '';
    this.selectedScript.medicationObject.forEach(medi => {
      medication += medi.product_name === '' ? medi.product_brand_name.split(' ')[0] : medi.product_name + ',';
    });
    const body = {
      'scriptId': this.selectedScript.medicationObject[0].token,
      'number': '+' + this.phoneDialCode + this.phoneInput.phoneNumber,
      'initials': this.selectedScript.firstName.charAt(0) + this.selectedScript.lastName.charAt(0),
      'medication': medication.endsWith(',') ? medication.substring(0, medication.length - 1).trim() : medication.substring(0, medication.length).trim()
    }
    this.authService.send_Script(this.userData, body).then((response: any) => {
      if (response.status === 500) {
        this.toastr.clear();
        this.toastr.error(response.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      this.toastr.clear();
      this.toastr.success('Script Sent Successfully', 'Success', {
        positionClass: 'toast-top-center'
      });
    }).catch(err => {
    })
    modal.dismiss('Cross-Click');
  }

  deleteMyScript(modal) {
    this.authService.delete_my_script(this.userData, this.selectedScript._id).then((response: any) => {
      this.toastr.clear();
      this.toastr.error('Script Deleted Successfully', 'Message', {
        positionClass: 'toast-top-center'
      });
      this.fetchUsers_scripts();
      modal.dismiss('Cross-Click');
    })
  }

  openConfirmModal(delete_confirmModal, script, checkbox = null) {
    this.selectedScript = script;
    this.phoneDialCode = '61';
    this.submitted = false;
    this.ismatch = true;
    const temp = this.selectedScripts.filter(data => data !== script._id);
    this.selectedScripts = temp;
    if (checkbox) {
      checkbox.checked = false;
    }
    this.modalService.open(delete_confirmModal, {size: 'md', backdrop: 'static'});
  }

  async refreshTokens() {
    this.responeCount = 0;
    // Set a flag to prevent multiple simultaneous executions
    this.isRefreshing = true;

    for (let i = 0; i < this.erxResponse.length; i++) {
      // Call getScriptToken API and wait for the result
      this.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      const response: any = await this.authService.getScriptToken(this.userData, this.erxResponse[i].medicationObject[0].token);
      if (response.status === 500) {
        this.spinner.hide();
        this.toastr.error('Oops! Looks like something went wrong. Please try again later!', 'Message', {
          positionClass: 'toast-top-center'
        });
        continue;
      }
      if (Object.keys(response).includes('IHI_number') && !response.IHI_number) {
        let body = {
          status: response.status,
          token: this.erxResponse[i].medicationObject[0].token,
          userId: this.userData.auth.data._id
        };
        await this.authService.createScript(this.userData, body, 'true');
        this.responeCount++;
        this.spinner.hide();
      } else {
        let body;
        if (Object.keys(response).includes('medications')) {
          body = {
            status: response.medications[0].status,
            token: this.erxResponse[i].medicationObject[0].token,
            userId: this.userData.auth.data._id
          };
        } else {
          body = {
            status: response[0].status,
            token: this.erxResponse[i].medicationObject[0].token,
            userId: this.userData.auth.data._id
          };
        }
        await this.authService.createScript(this.userData, body, 'true');
        this.responeCount++;
        this.spinner.hide();
      }
      // Call createScript API after the previous call is done
      // Increment response count after each successful call
      // Fetch users' scripts if needed
      this.fetchUsers_scripts();
    }
  }
}
