<section id="ngx-datatable-filter" xmlns="http://www.w3.org/1999/html">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Staff Details</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <!-- ngx-datatable-filter -->
            <div class="row">

              <div class="col-md-12 col-sm-12 col-lg-12 col-12 text-right">
                <button class="float-right btn primary-btn" (click)="open(content,'md')"><i
                  class="ft-plus-circle mr-1"></i>Add Staff
                </button>
                <button *ngIf="this.userData.auth.data.role === 'Super Admin'"
                        class="float-right btn primary-btn mr-2" (click)="open(content1)"><i
                  class="ft-plus-circle mr-1"></i>Add Pharmacy Admin
                </button>
              </div>
            </div>

            <div *ngIf="this.userData.auth.data.role === 'Super Admin'">
              <ul ngbNav #basicnav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="1">
                  <a ngbNavLink>List Pharmacy Admins</a>
                  <ng-template ngbNavContent>
                    <div class="card">
                      <div class="card-content">
                        <div class="card-body table-responsive">
                          <div class="row">
                            <div class="col-md-6 col-sm-12 col-lg-12 col-12">
                              <fieldset class="form-group">
                                <label for="">Search:
                                  <input (keyup)="filterUpDate($event,'Admin')" id="ngx-filter-ref2"
                                         class="form-control form-control-sm d-inline-block width-200" type="text"
                                         placeholder="Filter the Name column..."/>
                                </label>
                              </fieldset>
                            </div>
                          </div>
                          <table class="table text-center m-0">
                            <thead>
                            <tr>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Phone Number</th>
                              <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let phad of pharmacyAdmins">
                              <td>{{ phad.firstName + ' ' + phad.middleName + ' ' + phad.lastName }}</td>
                              <td>{{ phad.email }}</td>
                              <td>{{ phad.number }}</td>
                              <td>
                                <a class="primary ml-2" (click)="openEditModal(Editcontent1,phad)">
                                  <i class="ft-edit font-medium-3"></i>
                                </a><a class="danger ml-2" (click)="openDeleteModal(deleteModal,phad)">
                                <i class="ft-trash font-medium-3"></i>
                              </a>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <!--                    <div class="card-footer">-->
                      <!--                      <button class="btn btn-secondary">Previous</button>-->
                      <!--                      <button class="btn btn-primary float-right">Next</button>-->
                      <!--                    </div>-->
                    </div>
                  </ng-template>
                </li>
                <li [ngbNavItem]="2">
                  <a ngbNavLink>List Staff</a>
                  <ng-template ngbNavContent>
                    <div class="card">
                      <div class="card-content">
                        <div class="card-body table-responsive">
                          <div class="row">
                            <div class="col-md-6 col-sm-12 col-lg-12 col-12">
                              <fieldset class="form-group">
                                <label for="ngx-filter-ref1">Search:
                                  <input (keyup)="filterUpDate($event,'staff')" id="ngx-filter-ref1"
                                         class="form-control form-control-sm d-inline-block width-200" type="text"
                                         placeholder="Filter the Name column..."/>
                                </label>
                              </fieldset>
                            </div>
                          </div>
                          <table class="table text-center m-0">
                            <thead>
                            <tr>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Phone Number</th>
                              <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let staff of staffList">
                              <td>{{ staff?.firstName + ' ' + staff?.middleName + ' ' + staff?.lastName }}</td>
                              <td>{{ staff.email }}</td>
                              <td>{{ staff.number }}</td>
                              <td>
                                <a class="primary ml-2" (click)="openEditModal(editModal,staff)">
                                  <i class="ft-edit font-medium-3"></i>
                                </a><a class="danger ml-2" (click)="openDeleteModal(deleteModal,staff)">
                                <i class="ft-trash font-medium-3"></i>
                              </a>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <!--                    <div class="card-footer">-->
                      <!--                      <button class="btn btn-secondary">Previous</button>-->
                      <!--                      <button class="btn btn-primary float-right">Next</button>-->
                      <!--                    </div>-->
                    </div>
                  </ng-template>
                </li>
              </ul>

              <div [ngbNavOutlet]="basicnav" class="mt-2"></div>
            </div>
            <section *ngIf="this.userData.auth.data.role !== 'Super Admin'">
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-content">
                      <div class="card-body table-responsive">
                        <h4 class="card-title text-center" *ngIf="staffList.length === 0">No Staffs found</h4>
                        <table class="table text-center mt-1" *ngIf="staffList.length > 0">
                          <thead>
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Actions</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr *ngFor="let staff of staffList">
                            <td>{{ staff?.firstName + ' ' + staff?.middleName + ' ' + staff?.lastName }}</td>
                            <td>{{ staff.email }}</td>
                            <td>{{ staff.number }}</td>
                            <td>
                              <a class="primary ml-2" (click)="openEditModal(editModal,staff)">
                                <i class="ft-edit font-medium-3"></i>
                              </a><a class="danger ml-2" (click)="openDeleteModal(deleteModal,staff)">
                              <i class="ft-trash font-medium-3"></i>
                            </a>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <!--                    <div class="card-footer">-->
                    <!--                      <button class="btn btn-secondary">Previous</button>-->
                    <!--                      <button class="btn btn-primary float-right">Next</button>-->
                    <!--                    </div>-->
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Staff Registration</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="staffForm" (ngSubmit)="createStaff(checkbox)">
      <div class="row">
        <div class='col-12 col-md-12'>
          <div class="form-group">
            <label class="form-control-label">First Name</label>
            <input formControlName="firstName" class="form-control input-md" type="text">
            <p class="text-danger" *ngIf="submitted && staffForm.controls['firstName'].invalid">First Name is
              required</p>
          </div>
        </div>
        <div class='col-12 col-md-12'>
          <div class="form-group">
            <label class="form-control-label">Middle Name</label>
            <input class="form-control input-md"
                   formControlName="middleName"
                   type="text">
          </div>
        </div>
      </div>
      <div class="row">
        <div class='col-12 col-md-12'>
          <div class="form-group">
            <label class="form-control-label">Last Name</label>
            <input class="form-control input-md" type="text"
                   formControlName="lastName">
            <p class="text-danger" *ngIf="submitted && staffForm.controls['lastName'].invalid">Last Name is required</p>
          </div>
        </div>
        <div class='col-12 col-md-12'>
          <div class="form-group">
            <label class="form-control-label">Phone number</label>
            <input class="form-control input-md" type="tel" formControlName="number" maxlength="10">
            <p class="text-danger" *ngIf="submitted && staffForm.controls['number'].invalid">Phone Number is
              required</p>
            <p class="text-danger" *ngIf="staffForm.controls['number'].hasError('pattern')">
              Invalid phone number
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class='col-12 col-md-12'>
          <div class="form-group">
            <label class="form-control-label">Email</label>
            <input class="form-control input-md" type="email" formControlName="email">
            <p class="text-danger" *ngIf="submitted && staffForm.controls['email'].invalid">Email is required</p>
            <p class="text-danger" *ngIf="staffForm.controls['email'].hasError('email')">
              Invalid email
            </p>
          </div>
        </div>
        <div class='col-12 col-md-12'>
          <div class="form-group">
            <label class="form-control-label">Password</label>
            <div class="input-group">
              <input type="password" class="form-control square" #passsword formControlName="password">
              <div class="input-group-prepend">
                <span class="input-group-text cursor-pointer" (click)="showPassword(passsword,'password')"><i
                  [ngClass]="showpwd ? 'ft-eye' : 'ft-eye-off'"></i></span>
              </div>
            </div>
            <p class="text-danger" *ngIf="submitted && staffForm.controls['password'].invalid">Password is required</p>
          </div>
        </div>
        <div class='col-12 col-md-12'>
          <div class="form-group">
            <label class="form-control-label">Confirm password</label>
            <div class="input-group">
              <input type="password" class="form-control square" #confirmpassword formControlName="confirmpwd">
              <div class="input-group-prepend">
                <span class="input-group-text cursor-pointer"
                      (click)="showPassword(confirmpassword,'confirm_password')"><i
                  [ngClass]="showConfirmPassword ? 'ft-eye' : 'ft-eye-off'"></i></span>
              </div>
            </div>
            <p class="text-danger" *ngIf="submitted && staffForm.controls['confirmpwd'].invalid">Confirm Password is
              required</p>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 d-flex justify-content-center align-items-center">
          <p class="checkbox text-center text-capitalize">
            <input type="checkbox" id="acceptTerms" #checkbox>
            <label for="acceptTerms"></label>
            By checking this box, you are consenting that you are allowed to access prescription information on their
            behalf of User.
          </p>
        </div>
      </div>
      <div class="form-group text-center">
        <button type="submit" class="btn secondary-btn"><i class="ft-check-circle mr-1"></i>Save
        </button>
      </div>
    </form>
  </div>
</ng-template>


<ng-template #content1 let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Create Pharmacy Admin</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-content">
    <div class="modal-body">
      <form [formGroup]="pharmacyAdmin" (ngSubmit)="createPharmacyAdmin()">
        <br/>
        <div class="row">
          <div class='col-12 col-sm-4'>
            <div class="form-group">
              <label class="form-control-label">First Name</label>
              <input formControlName="firstName" class="form-control input-md" type="text">
              <p class="text-danger" *ngIf="submitted && pharmacyAdmin.controls['firstName'].invalid">First Name is
                required</p>
            </div>
          </div>
          <div class='col-12 col-sm-4'>
            <div class="form-group">
              <label class="form-control-label">Middle Name</label>
              <input class="form-control input-md"
                     formControlName="middleName"
                     type="text">
            </div>
          </div>
          <div class='col-12 col-sm-4'>
            <div class="form-group">
              <label class="form-control-label">Last Name</label>
              <input class="form-control input-md" type="text"
                     formControlName="lastName">
              <p class="text-danger" *ngIf="submitted && pharmacyAdmin.controls['lastName'].invalid">Last Name is
                required</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class='col-12 col-sm-4'>
            <div class="form-group">
              <label class="form-control-label">Phone number</label>
              <input class="form-control input-md" type="tel" formControlName="number" maxlength="10">
              <p class="text-danger" *ngIf="submitted && pharmacyAdmin.controls['number'].invalid">Phone Number is
                required</p>
              <p class="text-danger" *ngIf="pharmacyAdmin.controls['number'].hasError('pattern')">
                Invalid phone number
              </p>
            </div>
          </div>
          <div class='col-12 col-sm-4'>
            <div class="form-group">
              <label class="form-control-label">Email</label>
              <input class="form-control input-md" type="email" formControlName="email">
              <p class="text-danger" *ngIf="submitted && pharmacyAdmin.controls['email'].invalid">Email is required</p>
              <p class="text-danger" *ngIf="pharmacyAdmin.controls['email'].hasError('email')">
                Invalid email
              </p>
            </div>
          </div>
          <div class='col-12 col-sm-4'>
            <label class="form-control-label">Select Pharmacy</label>
            <ng-select formControlName="pharmacy" (change)="EditpushPharmacy($event)"
                       [items]="phamacyList" bindValue="_id" bindLabel="pharmacy_name"
                       [multiple]="true">
            </ng-select>
            <p class="text-danger"
               *ngIf="submitted && pharmacyAdmin.controls['pharmacy'].invalid && selectedPharmacy.length === 0">Pharmacy
              is required</p>
          </div>
        </div>
        <div class="row">
          <div class='col-12 col-sm-4'>
            <div class="form-group">
              <label class="form-control-label">Password</label>
              <div class="input-group">
                <input type="password" class="form-control square" #passsword formControlName="password">
                <div class="input-group-prepend">
                  <span class="input-group-text cursor-pointer" (click)="showPassword(passsword,'password')"><i
                    [ngClass]="showpwd ? 'ft-eye' : 'ft-eye-off'"></i></span>
                </div>
              </div>
              <p class="text-danger" *ngIf="submitted && pharmacyAdmin.controls['password'].invalid">Password is
                required</p>
            </div>
          </div>
          <div class='col-12 col-sm-4'>
            <div class="form-group">
              <label class="form-control-label">Confirm password</label>
              <div class="input-group">
                <input type="password" class="form-control square" #confirmpassword formControlName="confirmpwd">
                <div class="input-group-prepend">
                  <span class="input-group-text cursor-pointer"
                        (click)="showPassword(confirmpassword,'confirm_password')"><i
                    [ngClass]="showConfirmPassword ? 'ft-eye' : 'ft-eye-off'"></i></span>
                </div>
              </div>
              <p class="text-danger" *ngIf="submitted && pharmacyAdmin.controls['confirmpwd'].invalid">Confirm Password
                is
                required</p>
            </div>
          </div>
        </div>
        <div class="form-group text-center">
          <button type="submit" class="btn secondary-btn"><i class="ft-check-circle mr-1"></i>Save
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<!--update Pharmacy Admin-->


<ng-template #Editcontent1 let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Edit Pharmacy Admin</h4>
    <button type="button" class="close" aria-label="Close" (click)="resetClose(modal)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-content">
    <div class="modal-body">
      <form [formGroup]="EditAdmin" (ngSubmit)="updatePharmacyAdmin(modal)">
        <br/>
        <div class="row">
          <div class='col-12 col-sm-4'>
            <div class="form-group">
              <label class="form-control-label">First Name</label>
              <input formControlName="firstName" class="form-control input-md" type="text">
              <p class="text-danger" *ngIf="submitted && EditAdmin.controls['firstName'].invalid">First Name is
                required</p>
            </div>
          </div>
          <div class='col-12 col-sm-4'>
            <div class="form-group">
              <label class="form-control-label">Middle Name</label>
              <input class="form-control input-md"
                     formControlName="middleName"
                     type="text">
            </div>
          </div>
          <div class='col-12 col-sm-4'>
            <div class="form-group">
              <label class="form-control-label">Last Name</label>
              <input class="form-control input-md" type="text"
                     formControlName="lastName">
              <p class="text-danger" *ngIf="submitted && EditAdmin.controls['lastName'].invalid">Last Name is
                required</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class='col-12 col-sm-4'>
            <div class="form-group">
              <label class="form-control-label">Phone number</label>
              <input class="form-control input-md" type="tel" formControlName="number" maxlength="10">
              <p class="text-danger" *ngIf="submitted && EditAdmin.controls['number'].invalid">Phone Number is
                required</p>
              <p class="text-danger" *ngIf="EditAdmin.controls['number'].hasError('pattern')">
                Invalid phone number
              </p>
            </div>
          </div>
          <div class='col-12 col-sm-4'>
            <div class="form-group">
              <label class="form-control-label">Email</label>
              <input class="form-control input-md" type="email" formControlName="email">
              <p class="text-danger" *ngIf="submitted && EditAdmin.controls['email'].invalid">Email is required</p>
              <p class="text-danger" *ngIf="EditAdmin.controls['email'].hasError('email')">
                Invalid email
              </p>
            </div>
          </div>
          <div class='col-12 col-sm-4'>
            <label class="form-control-label">Select Pharmacy</label>
            <ng-select formControlName="pharmacy" (change)="EditpushPharmacy($event)"
                       [items]="phamacyList" bindValue="_id" bindLabel="pharmacy_name" placeholder="Select Pharmacy"
                       [multiple]="true">
            </ng-select>
          </div>
        </div>
        <div class="form-group text-center mt-2">
          <button type="submit" class="btn secondary-btn"><i class="ft-check-circle mr-1"></i>Save
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #view let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Staff Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <!-- Card data starts -->
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <table class="table table-borderless">
                  <tbody>
                  <tr>
                    <td><b>First Name:</b></td>
                    <td>{{ this.selectedStaff.firstName }}</td>
                  </tr>
                  <tr>
                    <td><b>Middle Name:</b></td>
                    <td>{{ this.selectedStaff.middleName }}</td>
                  </tr>
                  <tr>
                    <td><b>Last Name:</b></td>
                    <td>{{ this.selectedStaff.lastName }}</td>
                  </tr>
                  <tr>
                    <td><b>Email:</b></td>
                    <td>{{ this.selectedStaff.email }}</td>
                  </tr>
                  <tr>
                    <td><b>Phone number:</b></td>
                    <td>{{ this.selectedStaff.number }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- Card data ends -->
      </div>
    </div>
  </div>
</ng-template>
<ng-template #deleteModal let-modal>
  <div class="modal-header">
    <h4
      class="modal-title">{{ this.title === 'Would you like to delete this Pharmacy Admin?' ? 'Delete Pharmacy Admin' : 'Delete Staff' }}</h4>
  </div>
  <div class="modal-body text-center">
    <p>{{ title }}</p>
  </div>
  <div class="modal-footer text-center justify-content-center">
    <button type="button" class="btn btn-danger" (click)="deleteStaff()">Yes</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">No</button>
  </div>
</ng-template>


<ng-template #editModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Edit Staff</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <form [formGroup]="Editstaff" (ngSubmit)="editStaff()">
      <br/>
      <div class="row">
        <div class='col-12 col-sm-6'>
          <div class="form-group">
            <label class="form-control-label">First Name</label>
            <input formControlName="firstName" class="form-control input-md" type="text">
            <p class="text-danger" *ngIf="submitted && Editstaff.controls['firstName'].invalid">First Name is
              required</p>
          </div>
        </div>
        <div class='col-12 col-sm-6'>
          <div class="form-group">
            <label class="form-control-label">Middle Name</label>
            <input class="form-control input-md"
                   formControlName="middleName"
                   type="text">
          </div>
        </div>
      </div>
      <div class="row">
        <div class='col-12 col-sm-6'>
          <div class="form-group">
            <label class="form-control-label">Last Name</label>
            <input class="form-control input-md" type="text"
                   formControlName="lastName">
            <p class="text-danger" *ngIf="submitted && Editstaff.controls['lastName'].invalid">Last Name is required</p>
          </div>
        </div>
        <div class='col-12 col-sm-6'>
          <div class="form-group">
            <label class="form-control-label">Phone number</label>
            <input class="form-control input-md" type="tel" formControlName="number" maxlength="10">
            <p class="text-danger" *ngIf="submitted && Editstaff.controls['number'].invalid">Phone Number is
              required</p>
            <p class="text-danger" *ngIf="Editstaff.controls['number'].hasError('pattern')">
              Invalid phone number
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class='col-12 col-sm-6'>
          <div class="form-group">
            <label class="form-control-label">Email</label>
            <input class="form-control input-md" type="email" formControlName="email">
            <p class="text-danger" *ngIf="submitted && Editstaff.controls['email'].invalid">Email is required</p>
            <p class="text-danger" *ngIf="Editstaff.controls['email'].hasError('email')">
              Invalid email
            </p>
          </div>
        </div>

      </div>
      <div class="form-group text-center">
        <button type="submit" class="btn secondary-btn float-right"><i class="ft ft-check-circle mr-1"></i>Save</button>
      </div>
    </form>
  </div>
</ng-template>
