import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, NgModel, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../shared/auth/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {LocalStorageService} from '../../../shared/storage/local-storage.service';
import {NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-customer-login',
  templateUrl: './customer-login.component.html',
  styleUrls: ['./customer-login.component.scss']
})
export class CustomerLoginComponent implements OnInit {

  loginFormSubmitted = false;
  otpformSubmitted = false;
  authCodeSubmitted = false;
  isLoginFailed = false;
  showOTP = false;
  showLogin = true;
  showQRCode = false;
  showTwofa = false;
  closeResult: string;
  userData: any;
  userreponse: any;
  loginForm = new UntypedFormGroup({
    number: new UntypedFormControl('', [Validators.required, Validators.pattern('^[0-9]{9,10}$')
    ]),
  });

  verifyOTp = new UntypedFormGroup({
    otp: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]{4}')]),
  });

  googleAuthCode = new UntypedFormGroup({
    authCode: new UntypedFormControl('', [Validators.required]),
  })
  validateNumber = false;
  validatepostalCode = false;
  validateMedicareNum = false;
  platform: any;


  constructor(private router: Router, private authService: AuthService, private modalService: NgbModal,
              private cdr: ChangeDetectorRef,
              private spinner: NgxSpinnerService, private storage: LocalStorageService, private toastr: ToastrService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.platform = JSON.parse(sessionStorage.getItem('platform'));
    // history.pushState(null, '', location.href);
    // window.onpopstate = () => {
    //   history.go(1);
    // }
  }

  get lf() {
    return this.loginForm.controls;
  }

  get of() {
    return this.verifyOTp.controls;
  }

  get authf() {
    return this.googleAuthCode.controls;
  }

  // On submit button click
  async onSubmit() {
    this.loginFormSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    const body = {
      number: '+61' + this.loginForm.value.number
    }
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });

    await this.authService.loginPhone(body, this.platform).then(async (resp: any) => {
      if (resp.status === 404) {
        this.toastr.clear();
        this.toastr.error(resp.error.hasOwnProperty('error') ? resp.error.error : resp.error.message, 'Message!', {
          positionClass: 'toast-top-center'
        });
        this.spinner.hide();
        return;
      }
      if (resp.status === 400) {
        this.toastr.clear();
        this.toastr.error('Invalid phone number', 'Message!', {
          positionClass: 'toast-top-center'
        });
        this.spinner.hide();
        return;
      }

      this.toastr.clear();
      this.toastr.success('OTP has sent to your registered Phone number', 'Message!', {
        positionClass: 'toast-top-center'
      });
      this.userreponse = resp;
      this.showLogin = false;
      this.showOTP = true;
      this.otpformSubmitted = false;
      this.verifyOTp.reset();
      this.spinner.hide();
      this.cdr.detectChanges();
    }).catch(err => {
      this.showLogin = true;
      this.showOTP = false;
      this.cdr.detectChanges();
      console.log(err);
    });

  }


  verify_OTP() {
    this.otpformSubmitted = true;
    if (this.verifyOTp.invalid) {
      return;
    }
    const body = {
      number: '+61' + this.loginForm.value.number,
      otp: this.verifyOTp.value.otp
    }
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    this.authService.verify_otp(body, this.platform).then(async (resp: any) => {
      if (resp.status === 400) {
        this.toastr.clear();
        this.toastr.error('Wrong OTP', 'Error!', {
          positionClass: 'toast-top-center'
        });
        this.spinner.hide();
        this.showQRCode = false;
        this.showTwofa = false;
        this.cdr.detectChanges();
        return;
      }
      this.spinner.hide();
      this.userData = resp;
      if (resp?.user?.twoFactorAuth?.enabled) {
        this.googleAuthCode.reset();
        this.authCodeSubmitted = false;
        await this.generateQrCode(resp);
      } else {
        if (resp.user.role !== 'User') {
          this.authService.passText.next('unselect');
          this.authService.pharmacySwitch.next(resp.user.pharmacy[0]._id);
          await this.router.navigate(['/dashboard']);
        }
        if (resp.user.role === 'Super Admin') {
          this.authService.passText.next('select')
          this.userData = JSON.parse(sessionStorage.getItem('script-capture'));
          this.authService.sendResponse(resp.user.firstName);
          this.authService.fetch_pharmacy_platformId(this.userData, this.platform).then(async (userData) => {
            sessionStorage.setItem('pharmacyId', userData.result.length > 0 ? userData.result[0]._id : '');
          });
          this.router.navigate(['/dashboard']);
          return;
        }
        if (resp.user.role === 'User') {
          await this.router.navigate(['/my-scripts']);
        }
      }
    }).catch(err => {
      this.spinner.hide();
      this.toastr.clear();
      console.log(err)
      this.toastr.error('Some thing went wrong', 'Error!', {
        positionClass: 'toast-top-center'
      });
      this.showQRCode = false;
      this.showTwofa = false;
    });
  }


  async generateQrCode(resp) {
    await this.authService.generateQrCode(resp).then(async (res: any) => {
      // document.querySelector('#qrcode').setAttribute('src', res.qrCodeUrl);
      const image = document.createElement('img');
      image.src = res.qrCodeUrl;
      document.querySelector('.qrcode-container-block').append(image);
      this.showOTP = false;
      this.showQRCode = true;
      this.showTwofa = true;
      this.cdr.detectChanges();
    })
  }

  open(content) {
    this.modalService.open(content, {windowClass: 'small-modal'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  verify_Two_fa() {
    const user = JSON.parse(sessionStorage.getItem('script-capture'));
    this.authCodeSubmitted = true;
    if (this.googleAuthCode.invalid) {
      return;
    }
    const body = {
      six_digit_code: this.googleAuthCode.value.authCode
    }

    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    this.authService.verify_two_faAuth(user, body).then(async (resp: any) => {
      if (resp.status === 400) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Message!', {
          positionClass: 'toast-top-center'
        });
        this.spinner.hide();
        return;
      }
      if (resp.status === 500) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Message!', {
          positionClass: 'toast-top-center'
        });
        this.spinner.hide();
        return;
      }

      if (user.auth.data.role === 'User') {
        await this.router.navigate(['/my-scripts']);
      } else {
        await this.router.navigate(['/dashboard']);
      }
      this.spinner.hide();
    }).catch(err => {
      this.spinner.hide();
      this.toastr.clear();
      this.toastr.error('Verification failed', 'Error!', {
        positionClass: 'toast-top-center'
      });
    })
  }


  validateMobile(event: any) {
    const pattern = /^[0-9]{10}$/;
    if (!pattern.test(event.target.value)) {
      this.validateNumber = true;
      return;
    }
    this.validateNumber = false;
  }

  validatePostCode(event: any) {
    const pattern = /^[0-9]{6}$/;
    if (!pattern.test(event.target.value)) {
      this.validatepostalCode = true;
      return;
    }
    this.validatepostalCode = false;
  }


  validateMedicareNumber(event: any) {
    const pattern = /^[0-9]{10}$/;
    if (!pattern.test(event.target.value)) {
      this.validateMedicareNum = true;
      return;
    }
    this.validateMedicareNum = false;
  }


  tryAgain() {
    this.showLogin = true;
    this.showOTP = false;
    this.verifyOTp.reset();
    this.showQRCode = false;
    this.showTwofa = false;
    this.loginFormSubmitted = false;
  }
}
