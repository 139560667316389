<!--Login Page Starts-->
<section id="login" class="page-wrapper">
  <div class="row auth-height full-height-vh m-0">
    <div class="col-12 d-flex align-items-center justify-content-center">
      <div class="card overflow-hidden">
        <div class="card-content">
          <div class="card-body auth-img">
            <div class="row m-0">
              <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center auth-img-bg p-3">
                <img src="{{this.platform?.logo}}" alt="" class="img-fluid" width="300" height="230">
              </div>
              <div class="col-lg-6 col-12 px-4 py-3">
                <h4 class="mb-2 card-title">Login with Phone number</h4>
                <p>Welcome, please login to your account.</p>
                <ngb-alert type="light-danger" class="mb-2" *ngIf="isLoginFailed" (close)="isLoginFailed = false">
                  <p class="mb-0">Login failed!</p>
                </ngb-alert>
                <div class="row" *ngIf="showLogin">
                  <div class="col-12">
                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                      <div class="form-group">
                        <input type="text" formControlName="number" class="form-control" placeholder="Phone number"
                               [ngClass]="{ 'is-invalid': loginFormSubmitted && lf.number.hasError('required'), 'is-valid': loginFormSubmitted && !lf.number.hasError('required') }"
                               required>
                        <div
                          *ngIf="loginFormSubmitted && loginForm.controls['number'].hasError('required')"
                          class="help-block mt-1 text-danger"><i class="ft-alert-circle align-middle"></i> This is
                          required
                        </div>
                        <div class="text-danger" *ngIf="loginForm.controls['number'].hasError('pattern')">Invalid Phone
                          number
                        </div>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <button type="submit" class="btn primary-btn">Submit</button>
                      </div>
                    </form>
                  </div>
                </div>

                <div class="row mt-2" *ngIf="showOTP">
                  <div class="col-12">
                    <form [formGroup]="verifyOTp" (ngSubmit)="verify_OTP()">
                      <div class="form-group">
                        <input type="text" formControlName="otp" class="form-control" placeholder="OTP"
                               [ngClass]="{ 'is-invalid': otpformSubmitted && of.otp.invalid, 'is-valid': otpformSubmitted && !of.otp.invalid }"
                               required>
                        <div *ngIf="otpformSubmitted && (of.otp.invalid || of.otp.errors?.required)"
                             class="help-block mt-1 text-danger"><i class="ft-alert-circle align-middle"></i> This is
                          required
                        </div>
                        <div class="text-danger" *ngIf="verifyOTp.controls['otp'].hasError('pattern')">Invalid OTP
                        </div>
                      </div>
                      <p class="cursor-pointer">Didn't receive OTP? <a (click)="tryAgain()" class="text-primary">Try
                        Again</a></p>
                      <div class="d-flex justify-content-center align-items-center">
                        <button type="submit" class="btn primary-btn">Verify OTP</button>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-12">
                  <div class="d-flex justify-content-center align-items-center qrcode-container-block">
                  </div>
                </div>
                <div class="row mt-2" *ngIf="showTwofa">
                  <div class="col-12">
                    <form [formGroup]="googleAuthCode" (ngSubmit)="verify_Two_fa()">
                      <div class="form-group">
                        <input type="text" formControlName="authCode" class="form-control" placeholder="Two FA code"
                               [ngClass]="{ 'is-invalid': authCodeSubmitted && authf.authCode.invalid, 'is-valid': authCodeSubmitted && !authf.authCode.invalid }"
                               required>
                        <div *ngIf="authCodeSubmitted && (authf.authCode.invalid || authf.authCode.errors?.required)"
                             class="help-block mt-1 text-danger"><i class="ft-alert-circle align-middle"></i> This is
                          required
                        </div>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <button type="submit" class="btn primary-btn">Verify</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<!--Login Page Ends-->

<ngx-spinner></ngx-spinner>
