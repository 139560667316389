import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../../../shared/auth/auth.service";
import {LocalStorageService} from '../../../../shared/storage/local-storage.service';
const moment = require('moment');
@Component({
  selector: 'app-pharmacy-dashboard',
  templateUrl: './pharmacy-dashboard.component.html',
  styleUrls: ['./pharmacy-dashboard.component.scss']
})
export class PharmacyDashboardComponent implements OnInit {

  userData: any;
  orderList:any;
  todays = 0;
  orderStatusArr: any[] = [
    {id: 'new', title: 'New'},
    {id: 'pending', title: 'Pending'},
    {id: 'dispense', title: 'Dispense'},
    {id: 'shipped', title: 'Shipped'},
    {id: 'completed', title: 'Completed'},
  ];

  constructor(private authService: AuthService, private storage: LocalStorageService) {
  }

  async ngOnInit(): Promise<void> {
    this.storage.getData('script-capture').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    // this.authService.fetchOrders_by_date(this.userData,moment(new Date()).format(),moment(new Date()).format()).then(resp =>{
    //   this.orderList = resp;
    // });
  }

  onResized(event: any) {
    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent('HTMLEvents');
    evt.initEvent('resize', true, false);
    window.dispatchEvent(evt);
  };

  onChange(event: any) {
   const res = this.orderList.filter((order) =>{
     return order.status === event.target.value;
   });
   this.todays = res.length;
  }
}
