import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../../shared/auth/auth.service';
import {ToastrService} from 'ngx-toastr';
import {LocalStorageService} from '../../../../shared/storage/local-storage.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {


  userData: any;
  submitted = false;
  ismismatch = false;
  isSequential = false;
  changepassword = new FormGroup({
    username: new FormControl({value: '', disabled: true}),
    new_password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?^&]{7,}$/)]),
    // TODO Need to change this to be a validator to match the new_password field instead
    confirm_passwrd: new FormControl('',
      [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?^&]{7,}$/)]),
  });
  showpwd = false;
  showConfirmPassword = false;

  constructor(private authService: AuthService, private toastr: ToastrService, private storage: LocalStorageService) {
  }

  async ngOnInit(): Promise<void> {
    this.storage.getData('script-capture').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.authService.fetch_user_detail(this.userData).then((resp: any) => {
      this.changepassword.controls['username'].setValue(resp.email);
    });
  }

  updatePassword() {
    this.submitted = true;
    if (this.changepassword.invalid) {
      return;
    }
    if (this.changepassword.value.new_password !== this.changepassword.value.confirm_passwrd) {
     this.ismismatch = true;
      this.submitted = false;
      return;
    }
    let body = [{
      propName: 'password',
      value: this.changepassword.value.new_password
    }]
    if (this.hasSequentialCharacters(this.changepassword.value.new_password)) {
      this.isSequential = true;
      return;
    }
    this.isSequential = false;
    this.ismismatch = false;
    this.authService.update_user_details(this.userData, body).then((res: any) => {
      this.toastr.success('Password changed Successfully', 'Message', {
        positionClass: 'toast-top-center'
      });
      this.submitted = false;
      this.changepassword.controls['new_password'].setValue('');
      this.changepassword.controls['confirm_passwrd'].setValue('');
    }).catch(err => {
      this.toastr.error('Password updation failed', 'Error', {
        positionClass: 'toast-top-center'
      });
    })
  }

  showPassword(password, passwordType) {
    if (passwordType === 'password') {
      this.showpwd = !this.showpwd;
      password.type = this.showpwd ? 'text' : 'password';
    } else {
      this.showConfirmPassword = !this.showConfirmPassword;
      password.type = this.showConfirmPassword ? 'text' : 'password';
    }
  }
  hasSequentialCharacters(password) {
    const length = password.length;
    // Loop through the password to check for sequential letters and numbers
    for (let i = 0; i < length - 1; i++) {
      const currentChar = password.charCodeAt(i);
      // Check for ascending sequences of numbers/letters
      if (password.charCodeAt(i + 1) === currentChar + 1) {
        return true;
      }
      // Check for descending sequences of numbers/letters
      if (password.charCodeAt(i + 1) === currentChar - 1) {
        return true;
      }

      // Check if the password is repeated
      if (password.charAt(i + 1) === password.charAt(i)) {
        return true;
      }
    }
    return false;
  }

}
