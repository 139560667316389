<div class="card">
  <div class="card-header">
    <h4>Pharmacy Profile</h4>
  </div>
  <div class="card-content">
    <div class="card-body">
      <form [formGroup]="editPharmacyProfile" (ngSubmit)="updatePharmacy(backOrders)">
        <div class="row">
          <div class="col-md-6 col-12 col-sm-12">
            <label class="form-label">Pharmacy Name</label>
            <input type="text" class="form-control" formControlName="pharmacy_name">
            <p class="text-danger"
               *ngIf="submitted && editPharmacyProfile.controls['pharmacy_name'].hasError('pattern')">Pharmacy Name is
              required</p>
          </div>
          <div class="col-md-6 col-12 col-sm-12">
            <label class="form-label">Pharmacy Email</label>
            <input type="text" class="form-control" formControlName="email">
            <p class="text-danger" *ngIf="submitted && editPharmacyProfile.controls['email'].hasError('required')">Email
              is required</p>
            <p class="text-danger" *ngIf="editPharmacyProfile.controls['email'].hasError('email')">Invalid email</p>
          </div>
          <div class="col-md-6 col-12 col-sm-12">
            <label class="form-label">Pharmacy number</label>
            <input type="text" class="form-control" formControlName="phoneNumber">
            <p class="text-danger"
               *ngIf="submitted && editPharmacyProfile.controls['phoneNumber'].hasError('required')">Phone number is
              required</p>
            <p class="text-danger" *ngIf="editPharmacyProfile.controls['phoneNumber'].hasError('pattern')">Invalid Phone
              number</p>
          </div>
          <div class="col-md-6 col-12 col-sm-12">
            <label class="form-label">Address</label>
            <input type="text" class="form-control" formControlName="address">
            <p class="text-danger" *ngIf="submitted && editPharmacyProfile.controls['address'].hasError('required')">
              Address is required</p>
          </div>
          <div class="col-md-6 col-12 col-sm-12">
            <label class="form-label">Suburb</label>
            <input type="text" class="form-control" formControlName="suburb">
            <p class="text-danger" *ngIf="submitted && editPharmacyProfile.controls['suburb'].hasError('required')">
              Suburb is required</p>
          </div>
          <div class="col-md-6 col-12 col-sm-12">
            <label class="form-label">State</label>
            <select class="form-control" formControlName="state">
              <option selected disabled>Select State</option>
              <option *ngFor="let state of states" value="{{state}}">{{ state }}</option>
            </select>
            <p class="text-danger" *ngIf="submitted && editPharmacyProfile.controls['state'].hasError('required')">State
              is required</p>
          </div>
          <div class="col-md-6 col-12 col-sm-12">
            <label class="form-label">Postcode</label>
            <input type="email" class="form-control" formControlName="postcode" maxlength="4">
            <p class="text-danger" *ngIf="editPharmacyProfile.controls['postcode'].hasError('pattern')">Invalid
              postcode</p>
            <p class="text-danger" *ngIf="submitted && editPharmacyProfile.controls['postcode'].hasError('required')">
              Postcode is required</p>
          </div>
          <div class="col-md-6 col-12 col-sm-12">
            <label>Payment Type</label><i
            class="ft-info text-info ml-1 cursor-pointer"
            (click)="collapse1 = !collapse1" [attr.aria-expanded]="!collapse1"></i><br>
            <div class="custom-switch custom-control-inline mb-1 mb-xl-0">
              <input type="checkbox" class="custom-control-input" id="custom-switch-1" disabled
                     [checked]="!paymentType"
                     (change)="this.paymentType = !this.paymentType;">
              <label class="custom-control-label mr-1" for="custom-switch-1">
                <span>{{ paymentType ? 'Manual' : 'Online' }}</span>
              </label>
            </div>
            <p class="text-capitalize text-italic" aria-controls="collapseExample" [ngbCollapse]="collapse1">
              {{ paymentType ? 'By choosing the manual payment option, online payment is disabled.' : 'By selecting the online payment option, the customer will receive payment links through both SMS and email' }}
            </p>
          </div>
          <div class="col-md-6 col-12 col-sm-12">
            <label>Back Orders
              <i class="ft-info cursor-pointer ml-1" aria-controls="collapseExample1" (click)="collapse = !collapse"
                 [attr.aria-expanded]="!collapse"></i></label><br>
            <p id="collapseExample1" style="font-style: italic; font-size: 14px;" [ngbCollapse]="collapse">Toggle on to
              accept backorders, toggle off to show out of stock.</p>
            <div class="custom-switch custom-control-inline mb-1 mb-xl-0">
              <input type="checkbox" #backOrders class="custom-control-input" disabled
                     (change)="backOrder_toggle = backOrders.checked" id="custom-switch-2"
                     [checked]="backOrder_toggle">
              <label class="custom-control-label mr-1" for="custom-switch-2">
                <span>{{ backOrder_toggle ? 'Active' : 'Inactive' }}</span>
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
