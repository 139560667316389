<!--Statistics cards Starts-->
<div class="row" (resized)="onResized($event)">
  <div class="col-xl-3 col-lg-6 col-md-6 col-12">
    <div class="card card-size">
      <div class="card-content">
        <div class="card-body py-0">
          <div class="media pb-1">
            <div class="media-body text-dark text-left">
              <h3 class="font-large-1 text-dark mb-0">{{this.todays}}</h3>
              <span> Total <select class="form-control data-input" (change)="onChange($event)">
                <option *ngFor="let ordStatus of orderStatusArr" [value]="ordStatus.id">{{ordStatus.title}}</option>
              </select> orders for Today</span>
            </div>
            <div class="media-right text-dark text-right">
              <i class="ft-calendar font-large-1"></i>
            </div>
          </div>

        </div>
        <div id="Widget-line-chart" class=" WidgetlineChart WidgetlineChartshadow mb-2">
          <x-chartist class="height-75">
          </x-chartist>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-lg-6 col-md-6 col-12">
    <div class="card card-size">
      <div class="card-content">
        <div class="card-body py-0">
          <div class="media pb-1">
            <div class="media-body text-dark text-left">
              <h3 class="font-large-1 text-dark mb-0"></h3>
              <span>Total orders for Yesterday</span>
            </div>
            <div class="media-right text-dark text-right">
              <i class="ft-calendar font-large-1"></i>
            </div>
          </div>
          <select class="form-control">
            <option *ngFor="let ordStatus of orderStatusArr" [value]="ordStatus.id">{{ordStatus.title}}</option>
          </select>
        </div>
        <div id="Widget-line-chart0" class="WidgetlineChart WidgetlineChartshadow mb-2">
          <x-chartist class="height-75">
          </x-chartist>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-3 col-lg-6 col-md-6 col-12">
    <div class="card card-size">
      <div class="card-content">
        <div class="card-body py-0">
          <div class="media pb-1">
            <div class="media-body text-dark text-left">
              <h3 class="font-large-1 text-dark mb-0"></h3>
              <span>Total orders for this week</span>
            </div>
            <div class="media-right text-dark text-right">
              <i class="ft-calendar font-large-1"></i>
            </div>
          </div>
          <select class="form-control">
            <option *ngFor="let ordStatus of orderStatusArr" [value]="ordStatus.id">{{ordStatus.title}}</option>
          </select>
        </div>
        <div id="Widget-line-chart1" class="WidgetlineChart WidgetlineChartshadow mb-2">
          <x-chartist class="height-75">
          </x-chartist>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-lg-6 col-md-6 col-12">
    <div class="card card-size">
      <div class="card-content">
        <div class="card-body py-0">
          <div class="media pb-1">
            <div class="media-body text-dark text-left">
              <h3 class="font-large-1 text-dark mb-0"></h3>
              <span>Total orders for this Month</span>
            </div>
            <div class="media-right text-dark text-right">
              <i class="ft-calendar font-large-1"></i>
            </div>
          </div>
          <select class="form-control">
            <option *ngFor="let ordStatus of orderStatusArr" [value]="ordStatus.id">{{ordStatus.title}}</option>
          </select>
        </div>
        <div id="Widget-line-chart2" class="WidgetlineChart WidgetlineChartshadow mb-2">
          <x-chartist class="height-75">
          </x-chartist>
        </div>
      </div>
    </div>
  </div>
</div>
