import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FullPagesRoutingModule} from './full-pages-routing.module';
import {ChartistModule} from 'ng-chartist';
import {AgmCoreModule} from '@agm/core';
import {NgbDate, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';
import {PipeModule} from 'app/shared/pipes/pipe.module';
import {CalendarModule} from 'angular-calendar';
import {DashboardComponent} from './dashboard/dashboard.component';
import {ArchwizardModule} from 'angular-archwizard';
import {DragulaModule} from 'ng2-dragula';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {NgbNavModule} from '@ng-bootstrap/ng-bootstrap';
import {QuillModule} from 'ngx-quill';
import {PharmacyDashboardComponent} from './pharmacy/pharmacy-dashboard/pharmacy-dashboard.component';
import {OrdersComponent} from './pharmacy/orders/orders.component';
import {OrdersHistoryComponent} from './pharmacy/orders-history/orders-history.component';
import {CustomersComponent} from './pharmacy/customers/customers.component';
import {SettingsComponent} from './pharmacy/settings/settings.component';
import {ListProductComponent} from './pharmacy/list-product/list-product.component';
import {MonthFormatPipe} from '../../shared/pipes/month-format.pipe';
import {StaffComponent} from './pharmacy/staff/staff.component';
import {ListPharmacyComponent} from './pharmacy/list-pharmacy/list-pharmacy.component';
import {NewOrderComponent} from './pharmacy/new-order/new-order.component';
import {CustOrderHistoryComponent} from './pharmacy/cust-order-history/cust-order-history.component';
import {ChangePasswordComponent} from './pharmacy/change-password/change-password.component';
import {EditProfileComponent} from './pharmacy/edit-profile/edit-profile.component';
import {CategoriesComponent} from './pharmacy/categories/categories.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {QRCodeModule} from 'angularx-qrcode';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {CustomFormsModule} from 'ngx-custom-validators';
import {AuditLogsComponent} from './pharmacy/audit-logs/audit-logs.component';
import {PharmacyInfoComponent} from './pharmacy/pharmacy-info/pharmacy-info.component';
import {GlobalProdListComponent} from './pharmacy/global-prod-list/global-prod-list.component';
import {PlatformComponent} from './platform/platform.component';
import {MyScriptsComponent} from './pharmacy/my-scripts/my-scripts.component';
import {UpcomingOrdersComponent} from './pharmacy/upcoming-orders/upcoming-orders.component';
import {MailsFromPharmacyComponent} from './pharmacy/mails-from-pharmacy/mails-from-pharmacy.component';
import {ProductPageComponent} from './pharmacy/product-page/product-page.component';
import {OrdersMobileviewPageComponent} from './pharmacy/orders-mobileview-page/orders-mobileview-page.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { CustomerLeadsComponent } from './customer-leads/customer-leads.component';

@NgModule({
  imports: [
    CommonModule,
    FullPagesRoutingModule,
    CalendarModule,
    ChartistModule,
    AgmCoreModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,
    NgbNavModule,
    NgxIntlTelInputModule,
    PipeModule,
    NgxDatatableModule,
    ArchwizardModule,
    DragulaModule,
    NgbDatepickerModule,
    QuillModule.forRoot(),
    NgxSpinnerModule,
    QRCodeModule,
    ClipboardModule,
    CustomFormsModule,
  ],
  declarations: [
    DashboardComponent,
    PharmacyDashboardComponent,
    OrdersComponent,
    OrdersHistoryComponent,
    CustomersComponent,
    SettingsComponent,
    ListProductComponent,
    StaffComponent,
    ListPharmacyComponent,
    NewOrderComponent,
    CustOrderHistoryComponent,
    ChangePasswordComponent,
    EditProfileComponent,
    CategoriesComponent,
    AuditLogsComponent,
    PharmacyInfoComponent,
    GlobalProdListComponent,
    PlatformComponent,
    MyScriptsComponent,
    UpcomingOrdersComponent,
    MailsFromPharmacyComponent,
    ProductPageComponent,
    OrdersMobileviewPageComponent,
    CustomerLeadsComponent,
  ],
  exports: [],
  providers: [
    MonthFormatPipe,
    QRCodeModule
  ], schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FullPagesModule {
}
