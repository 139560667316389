import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../../shared/auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DownloadFileService} from '../../../../shared/services/downloadFile.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Order} from '../orders/order';
import {Clipboard} from '@angular/cdk/clipboard';
import {LocalStorageService} from '../../../../shared/storage/local-storage.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-orders-mobileview-page',
  templateUrl: './orders-mobileview-page.component.html',
  styleUrls: ['./orders-mobileview-page.component.scss']
})
export class OrdersMobileviewPageComponent implements OnInit {

  selectedOrder: Order | any;
  userData: any;
  platform: any;
  viewScript = '';
  isCollapsed = true;
  childOrder: any;
  pharmacyUser: any;
  notes = [];
  customerNotes = [];
  showUrl = false;

  constructor(private authService: AuthService, private router: Router, private activatedRouter: ActivatedRoute, private clipboard: Clipboard,
              private toastr: ToastrService, private spinner: NgxSpinnerService,
              private downloadFile: DownloadFileService, private modalService: NgbModal, private storage: LocalStorageService) {
  }

  async ngOnInit(): Promise<void> {
    this.storage.getData('script-capture').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.platform = JSON.parse(sessionStorage.getItem('platform'));
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.spinner.show(undefined,
      {
        zIndex: 99999,
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    this.authService.getOrder_by_Id(this.userData, urlParams.get('orderId')).then(async (response: any) => {
      if (response.status === 404) {
        this.spinner.hide();
        return;
      }
      if (response.status === 500) {
        this.spinner.hide();
        return;
      }
      this.selectedOrder = response;
      this.showUrl = /\bhttps?:\/\/\S+?\.(?:jpg|jpeg|png|gif|webp)\b/.test(response.uploadedScript) ? true : false;
      this.pharmacyUser = response.userId;
      this.customerNotes = response.notes.filter(data => data.customerNote);
      this.selectedOrder.totalQuantity = 0;
      this.selectedOrder.totalCost = 0;
      this.selectedOrder.orderedItem.map((data) => {
        data.scriptName = data.hasOwnProperty('scriptName') ? data.scriptName : data?.product_name === ''
          ? data?.product_brand_name : data?.product_name.includes(data?.medication_strength) ? data?.product_name : data?.product_name + ' ' + data?.medication_strength;
        this.selectedOrder.totalCost += data.price;
        this.selectedOrder.totalQuantity += data.quantity;
        return data;
      });
      this.spinner.hide();
    }).catch(err => {
      this.spinner.hide();
    })
  }

  downloadScript(scriptLink) {
    const s3Url = scriptLink; // Replace with your S3 URL
    this.downloadFile.downloadFileFromCloud(s3Url);
  }

  copyToClipboard(token: string) {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(token)
        .then(() => {
          this.toastr.clear();
          this.toastr.info('Text copied to clipboard successfully', 'Copied!', {
            positionClass: 'toast-top-center'
          });
        })
        .catch(err => {
          console.error('Could not copy text: ', err);
        });
    }
  }

  showGlobalProd(globalProductId: any, filteredResponse = false) {
    this.authService.fetch_particularglobalProdcut(this.userData, globalProductId, this.platform).then((response: any) => {
      const queryParams = {data: JSON.stringify(globalProductId)};
      const urlWithQueryParams = this.router.createUrlTree(['/product-page'], {queryParams}).toString();
      window.open(urlWithQueryParams, '_blank');
    });
  }

  viewScript_image(uploadedScript, modal) {
    this.viewScript = uploadedScript;
    this.modalService.open(modal, {size: 'lg', backdrop: 'static'});
  }

  copyToken(token: string) {
    this.clipboard.copy(token);
  }

  viewChildOrder(orderNo, modal) {
    this.authService.searchOrder(this.userData, this.selectedOrder?.pharmacyId, 'orderNumber', orderNo, 1).then(async (response: any) => {
      this.childOrder = response.results[0];
      this.modalService.open(modal, {size: 'lg', backdrop: 'static'});
    });
  }
}
