<div class="card">
  <div class="card-content">
    <div class="card-body">
      <div class="row" id="settings-tab" aria-labelledby="base-tab2">
        <div class="col-md-6" id="settings">
          <h5 class="mt-2 mb-3">General Settings</h5>
          <ul class="list-unstyled mb-0 mx-2">
            <li class="mb-3">
              <div class="mb-1">
                <span><i class="ft ft-bell-off mr-2"></i></span>
                <span class="text-bold-500">Push Notifications</span>
                <div class="float-right">
                  <div class="custom-switch">
                    <input class="custom-control-input"
                           [checked]="this.user?.receivePushAlert"
                           (change)="enable('receivePushAlert',$event)" id="noti-s-switch-1" type="checkbox">
                    <label class="custom-control-label" for="noti-s-switch-1"></label>
                  </div>
                </div>
              </div>
              <p class="font-small-3 m-0">Enable push notifications</p>
            </li>
            <li class="mb-3">
              <div class="mb-1">
                <span><i class="ft ft-message-square mr-2"></i></span>
                <span class="text-bold-500">Text Message (SMS)</span>
                <div class="float-right">
                  <div class="custom-switch">
                    <input class="custom-control-input"
                           [checked]="this.user?.receiveTextAlert"
                           (change)="enable('receiveTextAlert',$event)" id="noti-s-switch-2" type="checkbox"
                           checked="checked">
                    <label class="custom-control-label" for="noti-s-switch-2"></label>
                  </div>
                </div>
              </div>
              <p class="font-small-3 m-0">Enable SMS options.</p>
            </li>
            <li class="mb-3">
              <div class="mb-1">
                <span><i class="ft ft-mail mr-2"></i></span>
                <span class="text-bold-500">Email</span>
                <div class="float-right">
                  <div class="custom-switch">
                    <input class="custom-control-input"
                           [checked]="this.user?.receiveEmailAlert"
                           (change)="enable('receiveEmailAlert',$event)" id="noti-s-switch-4" type="checkbox"
                           checked="checked">
                    <label class="custom-control-label" for="noti-s-switch-4"></label>
                  </div>
                </div>
              </div>
              <p class="font-small-3 m-0">Enable Mails.</p>
            </li>
            <h5 class="mt-2 mb-3">Two Factor Authentication</h5>
            <li class="mb-3">
              <div class="mb-1">
                <span><i class="ft ft-file mr-2"></i></span>
                <span class="text-bold-500">Two Factor Authentication</span>
                <div class="float-right">
                  <div class="custom-switch">
                    <input class="custom-control-input"
                           [checked]="this.user?.twoFactorAuth.enabled"
                           id="two-fa" type="checkbox" (change)="enableTwoFactAuth($event)">
                    <label class="custom-control-label" for="two-fa"></label>
                  </div>
                </div>
              </div>
              <p class="font-small-3 m-0">Enable Two FA.</p>
            </li>
            <li class="mb-3" *ngIf="this.userData.auth.data.role === 'User'">
              <button class="btn btn-danger" (click)="openModal(confirmModal)">Deactivate My Account</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #confirmModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Deactivate My Account</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <div class="d-flex">
      <div class="checkbox">
        <input type="checkbox" id="accept" #accept>
        <label for="accept"></label>
      </div>
      <label class="text-center">Tick this box if you wish to permanently erase personal and prescription data from the system</label>
    </div>

    <div class="row mt-1">
      <div class="col-12 text-center">
        <button class="btn btn-danger" (click)="deactivate_My_Acc(accept,modal)">Yes</button>
        <button class="btn btn-secondary ml-2" (click)="modal.dismiss('Cross click')">No</button>
      </div>
    </div>
  </div>
</ng-template>
