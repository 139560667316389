import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../../../../shared/auth/auth.service';
import {ToastrService} from 'ngx-toastr';
import {LocalStorageService} from '../../../../shared/storage/local-storage.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {


  categorylist: any[] = [];
  editType = '';
  showForm = false;
  disableBtn = false;
  submitted = false;
  showAll = false;
  submitted1 = false;
  closeResult = '';
  selectedLabels = [];
  replicaLabels = [];
  modalTitle = '';
  userData: any;
  selectedLabel: any;
  specificLabels = [];
  selectedCategory: any;
  disableBTN = false;
  categoryForm = new FormGroup({
    categoryName: new FormControl('', [Validators.required, Validators.pattern('^(?!\\s).*$')]),
    description: new FormControl(''),
  });
  labelForm = new FormGroup({
    labelName: new FormControl('', [Validators.required, Validators.pattern('^(?!\\s).*$')]),
    value: new FormControl('', [Validators.required])
  });
  isCollapsed = true;
  platform: any;
  categoryLables = [];
  selectedIndex: number;
  modalType = '';
  items1 = ['Javascript', 'Typescript'];

  constructor(private modalService: NgbModal, private authService: AuthService, private storage: LocalStorageService,
              private toastr: ToastrService) {
  }


  async ngOnInit(): Promise<void> {
    this.storage.getData('script-capture').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.platform = JSON.parse(sessionStorage.getItem('platform'));
    this.loadCategories();
    this.fetch_category_labels();
  }


  loadCategories() {
    this.authService.fetchAllCategory(this.userData, this.platform).then((resp: any) => {
      this.categorylist = resp.data.map((category) => {
        category.collapse = true;
        return category;
      });
    });
  }


  get ctgry() {
    return this.categoryForm.controls;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  category_create_modal(modal) {
    this.modalType = 'create';
    this.showAll = false;
    this.selectedLabels = [];
    this.authService.fetchCategoryLabels(this.userData, this.platform._id).then((response: any) => {
      if (response.status === 404) {
        this.categoryLables = [];
        return;
      }
      this.categoryLables = response.data.map((item, index) => {
        if (index < 9) {
          item.checked = true;
          this.selectedLabels.push(item._id);
        }
        if (item.labelName === 'uploadProductInfo'){
          item.checked = true;
          this.selectedLabels.push(item._id);
        }
        return item;
      });
    }).catch(err => {
      this.categoryLables = [];
      return;
    })
    this.categoryForm.reset();
    this.submitted = false;
    this.modalService.open(modal, {size: 'lg', backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  openCreateModal(create, size = 'lg') {
    this.isCollapsed = true;
    this.modalType = 'createModal';
    this.labelForm.reset();
    this.disableBTN = false;
    this.showForm = false;
    this.submitted1 = false;
    this.modalService.open(create, {size: size, backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  openModal(content, category, size = 'md') {
    this.modalTitle = 'category';
    this.selectedCategory = category;
    this.replicaLabels = [];
    this.showAll = false;
    this.selectedLabels = [];
    if (category.propertiesList.length > 0) {
      category.propertiesList.forEach((data) => {
        this.selectedLabels.push(data._id);
      });
      this.replicaLabels = [...this.selectedLabels];
      if (this.selectedLabels.length > 0) {
        this.categoryLables.map((label) => {
          if (this.selectedLabels.includes(label._id)) {
            label.checked = true;
          } else {
            label.checked = false;
          }
          return label;
        });
      }
    }
    this.categoryForm.controls['categoryName'].setValue(category.categoryName);
    this.categoryForm.controls['description'].setValue(category.description);
    this.modalService.open(content, {size: size, backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  OnSubmit() {
    this.submitted = true;
    if (this.categoryForm.invalid) {
      return;
    }

    const body = {
      categoryName: this.categoryForm.value.categoryName,
      description: this.categoryForm.value.description,
      propertiesList: this.selectedLabels,
      platformId: this.platform._id
    }
    this.disableBTN = true;
    this.authService.createCategory(this.userData, body).then((resp: any) => {
      if (resp.status === 400) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Deleted', {
          positionClass: 'toast-top-center'
        });
        this.disableBTN = false;
        return;
      }
      this.toastr.clear();
      this.toastr.success('Category created Successfully', 'Created', {
        positionClass: 'toast-top-center'
      });
      this.loadCategories();
      this.modalService.dismissAll();
    }).catch(err => {
      if (err.status === 400) {
        this.disableBTN = false;
        this.toastr.error(err.error.message, 'Deleted', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.disableBTN = false;
        this.toastr.clear();
        this.toastr.error(err.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      }

    })
  }

  deleteCategory(modal) {
    this.authService.deleteCategory(this.userData, this.selectedCategory).then((resp: any) => {
      this.toastr.clear();
      this.toastr.error('Category deleted Successfully', 'Deleted', {
        positionClass: 'toast-top-center'
      });
      this.loadCategories();
      modal.dismiss('Cross-Click');
    }).catch(err => {
      if (err.status === 400) {
        this.toastr.error(err.error.message, 'Deleted', {
          positionClass: 'toast-top-center'
        });
      }
      if (err.status === 500) {
        this.toastr.error(err.error.message, 'Deleted', {
          positionClass: 'toast-top-center'
        });
      }
    })
  }

  fetch_category_labels() {
    this.authService.fetchCategoryLabels(this.userData, this.platform._id).then((response: any) => {
      if (response.status === 404) {
        this.categoryLables = [];
        return;
      }
      this.categoryLables = response.data.map((data) => {
        data.checked = false;
        return data;
      });
    }).catch(err => {
      this.categoryLables = [];
      return;
    })
  }


  create_label(modal) {
    this.submitted1 = true;
    if (this.labelForm.invalid) {
      return;
    }

    const check = this.categoryLables.some((label) => label.labelName === 'uploadProductInfo');
    if (check && this.labelForm.value.labelName.trim() === 'Additional Resources') {
      this.toastr.clear();
      this.toastr.error('Additional Resources label already exists', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }

    const body = {
      'platformId': this.platform._id,
      'labelName': this.labelForm.value.labelName.trim() === 'Additional Resources' ? 'uploadProductInfo' : this.labelForm.value.labelName.trim(),
      'value': this.labelForm.value.value,
      'selected': false
    }
    this.disableBtn = true;
    this.authService.create_category_labels(this.userData, body).then(async (response: any) => {
      if (response.status === 400) {
        this.disableBtn = false;
        this.toastr.clear();
        this.toastr.error(response.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      if (response.status === 404) {
        this.disableBtn = false;
        this.toastr.clear();
        this.toastr.error(response.error.error, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      if (response.status === 500) {
        this.disableBtn = false;
        this.toastr.clear();
        this.toastr.error(response.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      this.toastr.clear();
      this.selectedLabel = response.Category_label_created;
      this.toastr.success('Category label created successfully', 'Message', {
        positionClass: 'toast-top-center'
      });
      if (this.modalType === 'editModal') {
        this.authService.fetchCategoryLabels(this.userData, this.platform._id).then((resp: any) => {
          if (resp.status === 404) {
            this.categoryLables = [];
            return;
          }
          this.selectedLabels.push(this.selectedLabel._id);
          this.categoryLables = resp.data;
          this.categoryLables.map((item, index) => {
            if (this.selectedLabels.includes(item._id)) {
              item.checked = true;
            }
            return item;
          });
        }).catch(err => {
          this.categoryLables = [];
          return;
        })
      } else {
        this.authService.fetchCategoryLabels(this.userData, this.platform._id).then((resp: any) => {
          if (resp.status === 404) {
            this.categoryLables = [];
            return;
          }
          this.selectedLabels.push(this.selectedLabel._id);
          this.categoryLables = resp.data;
          this.categoryLables.map((item, index) => {
            if (this.selectedLabels.includes(item._id)) {
              item.checked = true;
            }
            return item;
          });
        }).catch(err => {
          this.categoryLables = [];
          return;
        })
      }
      this.disableBtn = false;
      this.showForm = false;
      this.submitted1 = false;
      this.labelForm.reset();
      modal.dismiss('Cross-Click');

    }).catch(err => {
      this.disableBtn = false;
      this.toastr.clear();
      this.toastr.error(err.error.message, 'Error', {
        positionClass: 'toast-top-center'
      });
    })
  }

  updateCategoryDetails(modal) {
    this.submitted = true;
    if (this.categoryForm.invalid) {
      return;
    }
    const body = [];
    for (const key in this.categoryForm.controls) {
      if (this.categoryForm.controls[key].dirty) {
        body.push({
          propName: key, value: this.categoryForm.controls[key].value
        })
      }
    }
    if (JSON.stringify(this.selectedLabels) !== JSON.stringify(this.replicaLabels)) {
      body.push({
        propName: 'propertiesList', value: this.selectedLabels
      })
    }


    this.authService.update_category_details(this.userData, body, this.selectedCategory).then((response: any) => {
      if (response.status === 400) {
        this.toastr.clear();
        this.toastr.error(response.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      if (response.status === 404) {
        this.toastr.clear();
        this.toastr.error(response.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      if (response.status === 500) {
        this.toastr.clear();
        this.toastr.error(response.error.error, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      this.toastr.clear();
      this.toastr.success('Category details updated', 'Message', {
        positionClass: 'toast-top-center'
      });
      modal.dismiss('Cross-Click');
      this.loadCategories();
      this.fetch_category_labels();
    }).catch(err => {
      this.toastr.clear();
      this.toastr.error(err.error.error, 'Error', {
        positionClass: 'toast-top-center'
      });
    })
  }

  OnSelectLabel(event, cate_label: any) {
    if (event.target.checked) {
      this.selectedLabels.push(cate_label._id);
    } else {
      const temp = this.selectedLabels.filter(value => value !== cate_label._id);
      this.selectedLabels = temp;
    }
  }

  deleteLabel(modal) {
    this.authService.deleteLabel(this.userData, this.selectedLabel).then(async (response: any) => {
      if (response.status === 404) {
        this.toastr.clear();
        this.toastr.error(response.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      if (response.status === 400) {
        this.toastr.clear();
        this.toastr.error(response.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      if (response.status === 500) {
        this.toastr.clear();
        this.toastr.error(response.error.error, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      this.toastr.clear();
      this.toastr.error('Category label deleted', 'Message', {
        positionClass: 'toast-top-center'
      });
      const temp = this.categoryLables.filter(item => item._id !== this.selectedLabel._id);
      this.categoryLables = temp;
      const temp1 = this.selectedLabels.filter(item => item !== this.selectedLabel._id);
      this.selectedLabels = temp1;
      this.authService.fetchCategoryLabels(this.userData, this.platform._id).then((resp: any) => {
        if (resp.status === 404) {
          this.categoryLables = [];
          return;
        }
        this.categoryLables = resp.data;
        this.categoryLables.map((item, index) => {
          if (this.selectedLabels.includes(item._id)) {
            item.checked = true;
          }
          return item;
        });
        modal.dismiss('Cross-Click');
      }).catch(err => {
        this.categoryLables = [];
        return;
      })
    });
  }

  openLabelModal(modal, categoryLabel: any, type = 'labelsEdit', index: number) {
    this.selectedLabel = categoryLabel;
    this.selectedIndex = index;
    this.showForm = false;
    this.labelForm.reset();
    this.modalType = 'editModal';
    this.editType = type;
    this.submitted1 = true;
    this.labelForm.controls['labelName'].setValue(categoryLabel.labelName);
    this.labelForm.controls['value'].setValue(categoryLabel.value);
    this.modalService.open(modal, {size: 'md', backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  updateLabel_details(modal) {
    this.submitted1 = true;
    if (this.labelForm.invalid) {
      return;
    }
    const body = [];
    for (const key in this.labelForm.controls) {
      if (this.labelForm.controls[key].dirty) {
        body.push({
          propName: key, value: this.labelForm.controls[key].value.toLowerCase()
        })
      }
    }
    this.authService.update_label_details(this.userData, body, this.selectedLabel).then(async (response: any) => {
      if (response.status === 400) {
        this.toastr.clear();
        this.toastr.error(response.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      if (response.status === 404) {
        this.toastr.clear();
        this.toastr.error(response.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      if (response.status === 500) {
        this.toastr.clear();
        this.toastr.error(response.error.error, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      this.toastr.clear();
      this.toastr.success('Category details updated', 'Message', {
        positionClass: 'toast-top-center'
      });

      await this.authService.fetchCategoryLabels(this.userData, this.platform._id).then(async (resp: any) => {
        if (resp.status === 404) {
          this.categoryLables = [];
          return;
        }
        this.categoryLables = resp.data;
        if (this.categoryLables.length > 0) {
          await this.authService.fetchAllCategory(this.userData, this.platform).then((resp1: any) => {
            this.categorylist = resp1.data;
            const findCategory = this.categorylist.find(category => this.selectedCategory._id === category._id);
            const selectedLabels = [];
            findCategory.propertiesList.forEach(data => {
              selectedLabels.push(data._id);
            })
            this.categoryLables.map((item, index) => {
              if (selectedLabels.includes(item._id)) {
                item.checked = true;
              }
              return item;
            });
          });
        }

      }).catch(err => {
        this.categoryLables = [];
        return;
      })
      modal.dismiss('Cross-Click');
    }).catch(err => {
      this.toastr.clear();
      this.toastr.error(err.error.error, 'Error', {
        positionClass: 'toast-top-center'
      });
    })
  }

  openDeleModal(confirm: any, cate_label: any) {
    this.selectedLabel = cate_label;
    this.modalTitle = 'label';
    this.modalService.open(confirm, {size: 'md', backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
}
