<div class="card">
  <div class="card-header">
    <h4 class="card-title">Change password</h4>
  </div>
  <div class="card-content">
    <div class="card-body">
      <form [formGroup]="changepassword" (ngSubmit)="updatePassword()">
        <div class="form-row">
          <div class="col-md-6 col-12">
            <div class="form-group mb-2">
              <label for="basic-form-1">Username</label>
              <input type="text" id="basic-form-1" class="form-control square" formControlName="username">
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-6 col-12">
            <div class="form-group mb-2">
              <label>New Password</label>
              <div class="input-group">
                <input type="password" class="form-control square" #passsword formControlName="new_password">
                <div class="input-group-prepend">
                  <span class="input-group-text cursor-pointer" (click)="showPassword(passsword,'password')"><i [ngClass]="showpwd ? 'ft-eye' : 'ft-eye-off'"></i></span>
                </div>
              </div>
              <small class="text-danger" *ngIf="submitted && changepassword.controls['new_password'].hasError('required')">New password is required</small>
              <div *ngIf="changepassword.controls['new_password'].hasError('pattern')">
                <small class="text-danger">
                  Password must be at least seven characters long, contain at least one letter and one number. <br>
                  Password must have atleast one of the special characters ! @ # $ % ^ & *<br>
                  Password must not use the same character repeatedly or have any sequential characters (for example, AAAA or 1234)<br>
                  Password must not have sequential characters. <br>
                  Password must not be the same as one of your last four passwords. <br>
                </small>
              </div>
              <div *ngIf="isSequential">
                <small class="text-danger">Password must not use the same character repeatedly or have any sequential characters (for example, AAAA or 1234)</small>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-6 col-12">
            <div class="form-group mb-2">
              <label>Confirm New Password</label>
              <div class="input-group">
                <input type="password" class="form-control square" (change)="ismismatch = false" #confirmpasssword formControlName="confirm_passwrd">
                <div class="input-group-prepend">
                  <span class="input-group-text cursor-pointer" (click)="showPassword(confirmpasssword,'confirm_passwrd')">
                    <i [ngClass]="showConfirmPassword ? 'ft-eye' : 'ft-eye-off'"></i></span>
                </div>
              </div>
              <small class="text-danger" *ngIf="submitted && changepassword.controls['confirm_passwrd'].hasError('required')">Confirm password is required</small>
              <div *ngIf="changepassword.controls['confirm_passwrd'].hasError('pattern')">
                <small class="text-danger">
                  Password must be at least seven characters long, contain at least one letter and one number. <br>
                  Password must have atleast one of the special characters ! @ # $ % ^ & *<br>
                  Password must not use the same character repeatedly or have any sequential characters (for example, AAAA or 1234)<br>
                  Password must not have sequential characters. <br>
                  Password must not be the same as one of your last four passwords. <br>
                </small>
              </div>
              <div *ngIf="ismismatch">
                <small class="text-danger">Password is mismatch</small>
              </div>
            </div>
          </div>
        </div>
        <button type="submit" class="btn secondary-btn mr-2"><i class="ft-check-square mr-1"></i>Save</button>
      </form>
    </div>
  </div>
</div>
