import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {AuthService} from '../../../../shared/auth/auth.service';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {newId} from '@ng-select/ng-select/lib/id';
import {DownloadFileService} from '../../../../shared/services/downloadFile.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Order} from '../orders/order';
import {Clipboard} from '@angular/cdk/clipboard';
import {LocalStorageService} from '../../../../shared/storage/local-storage.service';

const moment = require('moment');

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {

  userData: any;
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('tableRowDetails') tableRowDetails: any;
  @ViewChild('tableResponsive') tableResponsive: any;
  hideRegForm = true;
  customer: any;
  upcomingOrders: any = [];
  showAddress = false;
  tempData: any[] = [];
  customerList: any[] = [];
  maxPageSize = 0;
  maxPageSize1 = 0;
  nextpageLength: any[] = [];
  closeResult: string;
  page = 1;
  modalType = '';
  isCollapsed = true;
  selectedCustomer: any;
  frontImage: any;
  medicareCard = '';
  penssionCard = '';
  backImage: any;
  rows = [];
  platform: any;
  pharmacy: any;
  active = 1;
  submitted = false;
  userRegisteration = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    middleName: new FormControl('',),
    lastName: new FormControl('', [Validators.required]),
    number: new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    confirm_pwd: new FormControl('', [Validators.required]),
  });

  addressForm = new FormGroup({
    address: new FormControl('', [Validators.required]),
    suburb: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    postcode: new FormControl('', [Validators.required, Validators.pattern('[0-9]{4}')]),
  })
  token_updation = {
    'orderId': '',
    'newToken': '',
    'repeats': '',
    'duration': '',
  }
  customerOrders: any = [];
  selectedOrder: Order | any;
  pharmacyUserStatus = '';
  showUrl = false;
  orderedItems = [];
  customerNotes = [];
  pharmacyNotes = [];
  pharmacyUser: any;
  applyStyle = '';
  createdDivs: any = [];
  sample = {
    'fallowUp_note': '',
    'fallowUp_timeStamp': '',
    '_id': ''
  }
  private debounceTimer: any;
  rowHeight = 0;
  licenseFront: any;
  licenseBack: any;
  medicareLicense = '';
  pensionCardNumber = ''
  dateOfBirth = '';
  today: any;
  viewScript = '';
  message = '';
  valid = false;
  searchBy = [
    {title: 'Name', value: 'customerName', type: 'filter'},
    {title: 'Phone Number', value: 'customerNumber', type: 'filter'},
    {title: 'Email', value: 'customerEmail', type: 'filter'},
  ];
  search = {title: 'Name', value: 'customerName', type: 'customer'};
  disablebtn = false;
  page1 = 1;
  page2 = 1;
  maxSize2 = 0;
  childOrder: any;
  expand = false;

  constructor(private authService: AuthService, private spinner: NgxSpinnerService, private clipboard: Clipboard,
              private router: Router, private downloadFile: DownloadFileService, private storage: LocalStorageService,
              private modalService: NgbModal, private toastr: ToastrService) {
  }

  async ngOnInit(): Promise<void> {
    this.storage.getData('script-capture').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });

    this.platform = JSON.parse(sessionStorage.getItem('platform'));
    this.today = moment(new Date()).format('YYYY-MM-DD');
    // @ts-ignore
    this.authService.productPageRefresh.subscribe((data: string) => {
      if (data !== 'select') {
        sessionStorage.setItem('pharmacyId', data);
        this.loadingScreen();
        this.refresh();
        this.authService.fetch_particular_pharmacy(this.userData).then((resp) => {
          this.pharmacy = resp;
        });
      }
      return;
    });
    this.refresh();
    this.authService.fetch_particular_pharmacy(this.userData).then((resp) => {
      this.pharmacy = resp;
    });
  }

  OnSearchBy(event) {
    this.search = this.searchBy.find((data: any) => {
      return data.value === event.target.value;
    });
    this.refresh();
  }


  copyToken(token: string) {
    this.clipboard.copy(token);
  }

  loadingScreen() {
    this.spinner.show(undefined,
      {
        zIndex: 99999,
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true,
      });
  }

  searchOrder(value) {
    if (value.trim() === '') {
      this.toastr.error('Enter ' + this.search.title, 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }

    if (this.search.title === 'Phone Number') {
      // const pattern = /\d{9,10}/;
      // if (!pattern.test(value)) {
      //   this.toastr.error('Invalid ' + this.search.title, 'Error', {
      //     positionClass: 'toast-top-center'
      //   });
      //   return;
      // }
      // api call
      this.authService.fetchCustomers(this.userData, 1, 'number', value).then((resp: any) => {
        if (resp.response === 'the total user length is Zero') {
          this.rows = [];
          this.maxPageSize = 1;
        } else {
          this.maxPageSize = resp.totalPages;
          this.rows = resp.userDetails;
        }
      });

    } else if (this.search.title === 'Email') {
      // const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|.[a-zA-z]$/;
      // if (!pattern.test(value)) {
      //   this.toastr.error('Invalid ' + this.search.title, 'Error', {
      //     positionClass: 'toast-top-center'
      //   });
      //   return;
      // }
      // api call
      this.authService.fetchCustomers(this.userData, 1, 'email', value).then((resp: any) => {
        if (resp.response === 'the total user length is Zero') {
          this.rows = [];
          this.maxPageSize = 1;
        } else {
          this.maxPageSize = resp.totalPages;
          this.rows = resp.userDetails;
        }
      });

    } else {
      // api call
      this.authService.fetchCustomers(this.userData, 1, 'name', value).then((resp: any) => {
        if (resp.response === 'the total user length is Zero') {
          this.rows = [];
          this.maxPageSize = 1;
        } else {
          this.maxPageSize = resp.totalPages;
          this.rows = resp.userDetails;
        }
      });
    }
  }

  refresh() {
    this.authService.fetchCustomers(this.userData, 1).then((resp: any) => {
      if (resp.status === 404) {
        return;
      }
      if (resp.status === 400) {
        return;
      }
      if (resp.status === 403) {
        return;
      }
      if (resp.response === 'the total user length is Zero') {
        this.maxPageSize = 0;
        this.rows = [];
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this.maxPageSize = resp.totalPages;
        this.rows = resp.userDetails;
      }
    }).catch(err => {
    })
  }

  copyToClipboard(token: string) {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(token)
        .then(() => {
          this.toastr.clear();
          this.toastr.info('Text copied to clipboard successfully', 'Copied!', {
            positionClass: 'toast-top-center'
          });
        })
        .catch(err => {
          console.error('Could not copy text: ', err);
        });
    }
  }


  filterUpdate(phone: any) {
    const res = this.customerList.filter((item) => {
      return item?.userId?.number === '+61' + phone.value;
    });
    this.rows = res;
  }

  open(content, customer) {
    this.frontImage = '';
    this.backImage = '';
    this.medicareCard = '';
    this.penssionCard = '';
    this.selectedCustomer = customer;
    this.authService.fetch_pharmacy_user(this.userData, this.selectedCustomer, this.pharmacy._id).then((pharmacyUser: any) => {
      this.selectedCustomer = pharmacyUser;
      this.loadCustomerOrders();
      this.loadCustmerPreOrders();
      this.frontImage = pharmacyUser.hasOwnProperty('licenseFront_temp_encrypted') ? atob(pharmacyUser.licenseFront_temp_encrypted) : '';
      this.backImage = pharmacyUser.hasOwnProperty('licenseBack_temp_encrypted') ? atob(pharmacyUser.licenseBack_temp_encrypted) : '';
      this.medicareCard = pharmacyUser.hasOwnProperty('medicareCardNumber') ? atob(pharmacyUser.medicareCardNumber) : '';
      this.penssionCard = pharmacyUser.hasOwnProperty('pensionCardNumber') ? atob(pharmacyUser.pensionCardNumber) : '';
    })
    this.modalService.open(content, {size: 'max', backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  openModal1(Modal1: any, medi: any, message: string, size = 'xl') {
    this.selectedOrder = medi;
    this.disablebtn = false;
    this.message = message;
    this.modalService.open(Modal1, {size: size, backdrop: 'static'});
  }


  loadCustomerOrders() {
    this.authService.user_pharmacy_order(this.userData, sessionStorage.getItem('pharmacyId'), this.selectedCustomer, this.page1, '').then((resp: any) => {
      if (resp.response === 'No orders found for this customer') {
        return;
      } else {
        this.customerOrders = resp.results.filter(data => data.status !== 'preOrder');
        this.maxPageSize1 = resp.totalPages;
      }
    })
  }

  loadCustmerPreOrders() {
    this.authService.user_pharmacy_order(this.userData, sessionStorage.getItem('pharmacyId'), this.selectedCustomer, this.page2, 'preOrder').then((resp: any) => {
      if (resp.response === 'No orders found for this customer') {
        return;
      } else {
        this.upcomingOrders = resp.results.filter((order) => {
          if (moment(order.orderDate).format('l') === moment(new Date()).format('l')) {
            order.enableBtn = true;
          } else {
            order.enableBtn = false;
          }
          order.convertedDispenseData = moment(order.orderDate).format('YYYY-MM-DD');
          order.height = (60 * order.orderedItem.length) + 110;
          order.isAfterDate = moment(order.orderDate).isSameOrBefore(moment(new Date()));
          return order;
        })
        this.maxSize2 = resp.totalPages;
      }
    })
  }

  updateToken(token, index, modal) {
    let body = [];
    if (token.trim() === '') {
      this.toastr.clear();
      this.toastr.error('Please Enter the Token', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    body.push({
      propName: 'orderedItem.' + index + '.' + 'token',
      value: token
    });

    this.authService.update_order(this.userData, body, this.selectedOrder).then((resp: any) => {
      if (resp.status === 500) {
        this.toastr.clear();
        this.toastr.error('Token updation failed', 'Error', {
          positionClass: 'toast-top-center'
        });
      } else if (resp.status === 403) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.clear();
        this.toastr.success('Token Updated Successfully', 'Updated', {
          positionClass: 'toast-top-center'
        });
        body = [];
        this.selectedOrder = resp.updatedBody;
        this.orderedItems = this.selectedOrder.orderedItem;
        this.orderedItems.map((data) => {
          this.selectedOrder.totalCost += data.price;
          this.selectedOrder.totalQuantity += data.quantity;
        });
        this.loadCustmerPreOrders();
        modal.dismiss('Cross-Click');
      }
    }).catch(err => {
      this.toastr.clear();
      this.toastr.error('Ordered items udpation failed', 'Error', {
        positionClass: 'toast-top-center'
      });
    })
  }

  downloadScript(scriptLink) {
    const s3Url = scriptLink; // Replace with your S3 URL
    this.downloadFile.downloadFileFromCloud(s3Url);
  }

  viewScript_image(uploadedScript, modal) {
    this.viewScript = uploadedScript;
    // this.downloadScript(uploadedScript)
    this.modalService.open(modal, {size: 'lg', backdrop: 'static'});
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else if (reason === 'x-key-press') {
      return 'by pressing the X key';
    } else {
      return `with: ${reason}`;
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const activeElement = document.activeElement;
    const isInputField = activeElement && (
      activeElement.tagName === 'INPUT' ||
      activeElement.tagName === 'TEXTAREA'
    );

    if (!isInputField && (event.key === 'x' || event.key === 'X')) {
      this.modalService.dismissAll('x-key-press');
    }
  }


  openConfirm(confirm, size) {
    this.modalService.open(confirm, {size: size, backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.dateOfBirth = moment(this.selectedCustomer.userId.dateOfBirth).format('DD-MM-YYYY');
    this.licenseFront = atob(this.selectedCustomer.licenseFront_temp_encrypted);
    this.licenseBack = atob(this.selectedCustomer.licenseBack_temp_encrypted);
    this.medicareLicense = atob(this.selectedCustomer.medicareCardNumber);
    this.pensionCardNumber = atob(this.selectedCustomer.pensionCardNumber);
  }


  changeStatus(status) {
    if (status === 'verified') {
      const body = {
        'status': 'verified'
      }
      this.authService.update_user(this.selectedCustomer.userId, this.userData, body).then(resp => {
        if (resp.status === 500) {
          this.toastr.clear();
          this.toastr.error('Failed to update customer status', 'Error', {
            positionClass: 'toast-top-center'
          });
          return;
        }
        if (resp.status === 400) {
          this.toastr.warning('Please upload the license for approval ', 'Warning', {
            positionClass: 'toast-top-center'
          });
          return;
        }
          this.toastr.success('Customer ' + status, 'Updated', {
            positionClass: 'toast-top-center'
          });
          this.refresh();
      }).catch(err => {
        this.toastr.error('Failed to update customer status', 'Error', {
          positionClass: 'toast-top-center'
        });
      });
    } else {
      let body = [
        {
          'propName': 'Status',
          'value': status
        }
      ]
      this.authService.update_pharmacy_user(this.selectedCustomer, this.userData, body).then(async (resp) => {
        if (resp.status === 500) {
          this.toastr.clear();
          this.toastr.error('Failed to update customer status', 'Error', {
            positionClass: 'toast-top-center'
          });
          return;
        }
        this.toastr.clear();
        this.toastr.success(status === 'blackListed' ? 'Customer blacklisted' : 'Customer ' + status, 'Updated', {
          positionClass: 'toast-top-center'
        });
        this.refresh();
      }).catch(err => {
        if (err.status === 500) {
          this.toastr.clear();
          this.toastr.error('Failed to update customer status', 'Error', {
            positionClass: 'toast-top-center'
          });
          return;
        }
        this.toastr.clear();
        this.toastr.error('Failed to update customer status', 'Error', {
          positionClass: 'toast-top-center'
        });
      });
    }
    this.modalService.dismissAll();
  }

  goTo_place_order(customer) {
    this.router.navigate(['/new-order'], {
      queryParams: {pharmacy: JSON.stringify(this.pharmacy), customer: JSON.stringify(customer), type: 'Pharmacy'}
    });
  }

  get rf() {
    return this.userRegisteration.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.userRegisteration.invalid) {
      return;
    }

    if (this.userRegisteration.value.password !== this.userRegisteration.value.confirm_pwd) {
      this.toastr.error('Password mismatch', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    const body = {
      'firstName': this.userRegisteration.value.firstName,
      'middleName': this.userRegisteration.value.middleName,
      'lastName': this.userRegisteration.value.lastName,
      'email': this.userRegisteration.value.email,
      'password': this.userRegisteration.value.password,
      'number': '+61' + parseInt(this.userRegisteration.value.number, 10),
      'twofaEnabled': true,
      'role': 'User',
      'userType': 'user',
      'platformId': this.platform._id
    }
    this.authService.register_new_user(body).then((resp: any) => {
      if (resp.status === 403) {
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      if (resp.status === 400) {
        this.toastr.error('User already Exits', 'Error', {
          positionClass: 'toast-top-center'
        });
        this.modalService.dismissAll();
      } else {
        this.toastr.success('User Registration Successfull', 'Message', {
          positionClass: 'toast-top-center'
        });
        this.customer = resp.userCreated;
        this.showAddress = true;
        this.hideRegForm = false;
        const body = {
          userId: resp.userCreated._id,
          pharmacyId: this.pharmacy._id
        }
        this.authService.createPharmacy_user(this.userData, body).then((response) => {
          this.refresh();
        }).catch(err => {
          this.toastr.error('Failed to create Pharmacy User', 'Error!', {
            positionClass: 'toast-top-center'
          });
        })
        // this.modalService.dismissAll();
      }
    }).catch(err => {
      this.toastr.error('User registration failed', 'Error', {
        positionClass: 'toast-top-center'
      });
      this.modalService.dismissAll();
    })
    this.submitted = false;
  }

  clear(event: any) {
    if (event.key === 'Backspace') {
      this.refresh();
    }
  }

  submitAddress() {
    this.submitted = true;
    if (this.addressForm.invalid) {
      return;
    }
    const body = {
      userId: this.customer._id,
      address: this.addressForm.value.address,
      suburb: this.addressForm.value.suburb,
      state: this.addressForm.value.state,
      postcode: Number(this.addressForm.value.postcode)
    }

    this.authService.create_Address(body).then((resp: any) => {
      this.toastr.info('Address created successfully', 'Updated', {
        positionClass: 'toast-top-center'
      });
      this.refresh();
      this.modalService.dismissAll();
      this.addressForm.reset();
    }).catch(err => {
      this.toastr.error('Failed to create the address', 'Error', {
        positionClass: 'toast-top-center'
      });
    });
  }


  ViewOrder(order: any, content, modalType = 'ordersHistory') {
    this.isCollapsed = true;
    this.customerNotes = [];
    this.expand = false;
    this.modalType = modalType;
    this.pharmacyNotes = []
    this.isCollapsed = true;
    this.authService.getOrder_by_Id(this.userData, order._id).then(async (response: any) => {
      this.selectedOrder = response;
      this.pharmacyUserStatus = response.userId.pharmacyUserStatus;
      this.pharmacyUser = response.userId;
      this.createdDivs = this.selectedOrder.notes.length > 0 ? this.selectedOrder.notes.sort((a, b) => {
        if (moment(a, 'hh:mm A').diff(moment(b, 'hh:mm A'))) {
          return 1;
        } else {
          return -1;
        }
      }) : [];
      this.pharmacyNotes = this.createdDivs.filter(data => !data.customerNote);
      this.customerNotes = this.createdDivs.filter(data => data.customerNote);
      this.createdDivs.map((data) => {
        data.disabled = true;
        return data;
      });
      this.orderedItems = response.orderedItem;
      this.selectedOrder.totalCost = 0;
      this.showUrl = /\bhttps?:\/\/\S+?\.(?:jpg|jpeg|png|gif|webp)\b/.test(order.uploadedScript) ? true : false;
      this.selectedOrder.totalQuantity = 0;
      response.orderedItem.map((data) => {
        this.selectedOrder.totalCost += data.price;
        this.selectedOrder.totalQuantity += data.quantity;
      });
      await this.modalService.open(content, {windowClass: 'full-screen-modal', backdrop: 'static'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    });

  }

  createDiv() {
    this.createdDivs.unshift(this.createdDivs.length + 1);
  }

  showGlobalProd(globalProductId: any, filteredResponse = false) {
    const queryParams = {data: JSON.stringify(globalProductId)};
    const urlWithQueryParams = this.router.createUrlTree(['/product-page'], {queryParams}).toString();
    window.open(urlWithQueryParams, '_blank');
  }

  validateToken(value: string) {
    const pattern = /^[a-zA-Z0-9]*$/;
    if (!pattern.test(value)) {
      this.valid = true;
    } else {
      this.valid = false;
    }
  }

  onEnterKey(note, noteType = 'Customer') {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
    this.debounceTimer = setTimeout(() => {
      this.debounceTimer = null;
      this.sendNote(note, noteType = 'Customer');
    }, 300);
  }

  sendNote(note: HTMLInputElement, noteType = 'Customer', modal = {}) {
    if (note.value.trim() === '') {
      this.toastr.clear();
      this.toastr.error('Please add a note', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    const body = {'fallowUp_note': note.value};
    this.authService.update_note(this.userData, body, this.selectedOrder, noteType === 'Customer').then((resp: any) => {
      if (resp.status === 400) {
        this.toastr.clear();
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        this.disablebtn = false;
      } else {
        this.toastr.clear();
        this.toastr.info('Note updated', 'Info', {
          positionClass: 'toast-top-center'
        });
        note.value = '';
        this.selectedOrder = resp;
        this.createdDivs = resp.notes.length > 0 ? resp.notes : [];
        this.createdDivs = this.createdDivs.filter((data) => {
          return typeof data !== 'number';
        });
        this.createdDivs = this.createdDivs.sort((a, b) => {
          if (moment(a, 'hh:mm A').diff(moment(b, 'hh:mm A'))) {
            return 1;
          } else {
            return -1;
          }
        });
        this.disablebtn = false;
        this.pharmacyNotes = this.createdDivs.filter(data => !data.customerNote);
        this.customerNotes = this.createdDivs.filter(data => data.customerNote);
      }
    }).catch(er => {
      this.disablebtn = false;
    });
    this.modalService.dismissAll();
  }

  delete_Note(note, modal) {
    if (typeof note === 'number') {
      const temp = this.createdDivs.filter((data) => {
        return typeof note !== typeof data;
      });
      this.createdDivs = temp;
    } else {
      this.authService.deleteNote(this.userData, this.selectedOrder, note).then((response: any) => {
        if (response.status === 500) {
          this.toastr.clear();
          this.toastr.error('Note deletion failed', 'Error', {
            positionClass: 'toast-top-center'
          });
        } else {
          this.toastr.clear();
          this.toastr.success('Note deleted successfully', 'Success', {
            positionClass: 'toast-top-center'
          });
          this.selectedOrder = response.order;
          this.pharmacyNotes = response.order.notes.filter(data => !data.customerNote);
          this.customerNotes = response.order.notes.filter(data => data.customerNote);
          this.createdDivs = response.order.notes.length > 0 ? response.order.notes.sort((a, b) => {
            if (moment(a, 'hh:mm A').diff(moment(b, 'hh:mm A'))) {
              return 1;
            } else {
              return -1;
            }
          }) : [];
        }
      }).catch(err => {
        this.toastr.clear();
        this.toastr.error('Note deletion failed', 'Error', {
          positionClass: 'toast-top-center'
        });
      })
    }
  }

  advanceRequest(newToken, Order, index: number, modal: any) {
    if (newToken === '') {
      this.toastr.error('Please Provide the token', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    const obj = {
      'orderId': '', 'items': []
    }
    this.token_updation.newToken = newToken === '' ? ' ' : newToken;
    this.token_updation.repeats = ''
    this.token_updation.duration = ''
    this.token_updation.orderId = Order._id;
    obj.orderId = Order._id;
    obj.items.push({
      'token': newToken,
    });
    this.reOrder(modal);

  }

  reOrder(modal: any) {
    this.authService.re_order(this.userData, this.selectedOrder, this.token_updation).then((resp: any) => {
      if (resp.status === 400) {
        this.toastr.error(resp.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
      } else if (resp.status === 500) {
        this.toastr.error('Reorder Failed', 'Error', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.success('ReOrdered Successfully', 'Success', {
          positionClass: 'toast-top-center'
        });
        this.token_updation = {
          'orderId': '',
          'newToken': '',
          'repeats': '',
          'duration': '',
        }
        this.loadCustomerOrders();
        modal.dismiss('Cross-Click');
        this.modalService.dismissAll();
      }
    });
  }


  openModal(modal: any, order: any) {
    this.selectedOrder = order;
    this.modalService.open(modal, {size: 'xl', backdrop: 'static'})
  }

  fillDetails(duration, newToken, number_of_repeats, Order, index, modal: any) {
    if (newToken === ' ') {
      this.toastr.error('Token number should not be empty in ordered item', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    if (Number(duration) < 0) {
      this.toastr.error('Invalid Repeats Interval', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    }

    this.token_updation.newToken = newToken === '' ? '' : newToken;
    this.token_updation.repeats = number_of_repeats === '' ? ' ' : number_of_repeats;
    this.token_updation.duration = duration === '' ? ' ' : duration;
    this.token_updation.orderId = Order._id;
    this.authService.re_order(this.userData, this.selectedOrder, this.token_updation).then((resp: any) => {
      if (resp.status === 400) {
        this.toastr.error('order should be in preOrder status to reorder', 'Error', {
          positionClass: 'toast-top-center'
        });
      } else if (resp.status === 500) {
        this.toastr.error('Re-order Failed', 'Error', {
          positionClass: 'toast-top-center'
        });
      } else {
        this.toastr.success('Re-ordered Successfully', 'Success', {
          positionClass: 'toast-top-center'
        });
        this.loadCustomerOrders();
        this.token_updation = {
          'orderId': '',
          'newToken': '',
          'repeats': '',
          'duration': '',
        }
        modal.dismiss('Cross-Click');
      }
    })
  }


  resetClose(modal: any) {
    this.active = 1;
    this.tableRowDetails = undefined;
    modal.dismiss('Cross-Click');
  }

  pagination(number: number) {
    this.page = Number(number)
    this.authService.fetchCustomers(this.userData, this.page).then((resp: any) => {
      this.maxPageSize = resp.totalPages;
      this.rows = resp.userDetails;
    });
  }

  viewChildOrder(orderNo, modal) {
    this.authService.searchOrder(this.userData, {_id: sessionStorage.getItem('pharmacyId')}, 'orderNumber', orderNo, 1, '').then(async (response: any) => {
      if (response.results.length === 0) {
        return;
      }
      this.childOrder = response.results[0];
      this.modalService.open(modal, {size: 'max', backdrop: 'static'});
    });
  }

  Orders_pagination(number) {
    this.page1 = number;
    this.authService.user_pharmacy_order(this.userData, sessionStorage.getItem('pharmacyId'), this.selectedCustomer, this.page1).then((resp: any) => {
      if (resp.response === 'No orders found for this customer') {
        return;
      } else {
        this.customerOrders = resp.results.filter(data => data.status !== 'preOrder');
        this.maxPageSize1 = resp.totalPages;
      }
    });
  }

  PreOrders_pagination(number, status) {
    this.page2 = number;
    this.authService.user_pharmacy_order(this.userData, sessionStorage.getItem('pharmacyId'), this.selectedCustomer, this.page2, status).then((resp: any) => {
      if (resp.response === 'No orders found for this customer') {
        return;
      } else {
        this.maxSize2 = resp.totalPages;
        this.upcomingOrders = resp.results.filter((order) => {
          if (moment(order.orderDate).format('l') === moment(new Date()).format('l')) {
            order.enableBtn = true;
          } else {
            order.enableBtn = false;
          }
          order.convertedDispenseData = moment(order.orderDate).format('YYYY-MM-DD');
          order.isAfterDate = moment(order.orderDate).isSameOrBefore(moment(new Date()));
          order.height = (60 * order.orderedItem.length) + 110;
          return order;
        })
      }
    })
  }
}
