import {Component, HostBinding, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {

  currentDate: Date = new Date();
  platform: any;
  modalType = '';

  constructor(private modalSerivce: NgbModal) {
  }

  ngOnInit(): void {
    this.platform = JSON.parse(sessionStorage.getItem('platform'));
  }

  open(content, modalType: string) {
    this.modalType = modalType;
    this.modalSerivce.open(content, {size: 'xl', backdrop: 'static'});
  }
}
