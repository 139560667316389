import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../../../shared/auth/auth.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {Platform} from '../launch-screen/platform';
import {BrowserUrl} from '../../../BrowserUrl';

const moment = require('moment');

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  selectedPaymentCard = {
    logo: 'assets/img/mastercard.png', name: 'Mastercard'
  };
  platform!: Platform;
  paymentCard = [
    {logo: 'assets/img/mastercard.png', name: 'Mastercard'},
    {logo: 'assets/img/americanexpress.jpg', name: 'American express'},
    {logo: 'assets/img/visacard.png', name: 'Visacard'},
    {logo: 'assets/img/JCB.png', name: 'JCB'},
    {logo: 'assets/img/Diners.png', name: 'Diners'},
  ];
  hidepaymentPage = false;
  hideForm = false;
  paymentData = {
    'authKey': '',
    'orderId': '',
    'billerCode': '',
    'pharmacyName': '',
    'amount': 0,
    'crn1': ''
  };
  displayAmount = '0.00';
  showPaymentBtn = false;
  showMessage = false;
  paymentBody = {
    'card': {
      'cvn': '',
      'number': '',
      'expiry': {
        'month': '',
        'year': ''
      },
      'name': ''
    }
  }
  paymentResponseData = {
    receiptNumber: '',
    month: '',
    year: '',
    cardNumber: ''
  };
  cardDetailsForm = new FormGroup({
    cardNumber: new FormControl('', [Validators.required, Validators.pattern('^\\d{14,16}$')]),
    userName: new FormControl('', [Validators.required,    Validators.pattern(/^(?!\s)[A-Za-z0-9].*$/)
    ]),
    month: new FormControl('', [Validators.required, Validators.pattern(/^\d{2}$/)]),
    year: new FormControl('', [Validators.required, Validators.pattern(/^\d{2}$/)]),
    cvn: new FormControl('', [Validators.required, Validators.pattern(/^\d{3}$/)]),
  });
  submitted = false;
  today = '';
  errorBlock = {
    showBlock: false,
    errorMessage: '', orderStatus: ''
  }
  queryParams: any;

  constructor(private activatedRoute: ActivatedRoute, private modalService: NgbModal, private authService: AuthService,
              private toastr: ToastrService, private spinner: NgxSpinnerService, private cdr: ChangeDetectorRef, private router: Router) {
  }


  async ngOnInit(): Promise<any> {

    history.pushState(null, '', window.location.href); // Push state to history

    window.addEventListener('popstate', (event) => {
      // When back button is clicked, push state again to avoid back navigation
      history.pushState(null, '', window.location.href);
    });
    const body = {'url': BrowserUrl};
    this.authService.fetchPlatForm(body).then((resp: any) => {
      if (typeof resp['data'] !== 'string') {
        sessionStorage.setItem('platform', JSON.stringify(resp.data));
        this.platform = resp.data;
        this.cdr.detectChanges();
        const root = document.documentElement;
        root.style.setProperty('--primary-color', resp.data.primaryColour);
        root.style.setProperty('--secondary-color', resp.data.secondaryColour);
      }
    });
    this.activatedRoute.queryParams.subscribe(async (data) => {
      if (Object.keys(data).includes('authKey')) {
        this.queryParams = data;
        sessionStorage.setItem('orderId', data.orderId);
        this.authService.checkOrderPaymentStatus(data.orderId).then((paymentResponse) => {
          if (paymentResponse.status === 400) {
            this.errorBlock.showBlock = true;
            this.errorBlock.errorMessage = paymentResponse.error.message;
            this.errorBlock.orderStatus = paymentResponse.error.orderStatus;
            if (paymentResponse.error.orderStatus === 'paymentProcess') {
              this.showPaymentBtn = true;
            }
            this.hidepaymentPage = false;
            this.hideForm = true;
            this.cdr.detectChanges();
            return;
          }
          this.paymentData.amount = paymentResponse.amount;
          //adding to display amount to handle formatted display.
          this.displayAmount = parseFloat(paymentResponse.amount).toFixed(2);
          this.paymentData.authKey = paymentResponse.authKey;
          this.paymentData.orderId = data.orderId;
          this.paymentData.billerCode = paymentResponse.billerCode;
          this.paymentData.crn1 = paymentResponse.merchantNumberOrCRN;
          this.paymentData.pharmacyName = paymentResponse.pharmacyName;
          this.cdr.detectChanges();  // Trigger UI update after data assignment
        });
      } else {
        this.paymentData = JSON.parse(atob(data.token));
        this.queryParams = this.paymentData;
        sessionStorage.setItem('orderId', this.paymentData.orderId);
        this.cdr.detectChanges();  // Trigger UI update after data assignment
        this.authService.checkOrderPaymentStatus(this.paymentData.orderId).then((paymentResponse) => {
          if (paymentResponse.status === 400) {
            this.errorBlock.showBlock = true;
            this.errorBlock.errorMessage = paymentResponse.error.message;
            this.errorBlock.orderStatus = paymentResponse.error.orderStatus;
            this.hidepaymentPage = false;
            if (paymentResponse.error.orderStatus === 'paymentProcess') {
              this.showPaymentBtn = true;
            }
            this.hideForm = true;
            this.cdr.detectChanges();
            return;
          }
          this.paymentData.amount = paymentResponse.amount;
          //adding to display amount to handle formatted display.
          this.displayAmount = parseFloat(paymentResponse.amount).toFixed(2);
          this.paymentData.authKey = paymentResponse.authKey;
          this.paymentData.orderId = data.orderId;
          this.queryParams = paymentResponse;
          this.paymentData.billerCode = paymentResponse.billerCode;
          this.paymentData.crn1 = paymentResponse.merchantNumberOrCRN;
          this.paymentData.pharmacyName = paymentResponse.pharmacyName;
          this.cdr.detectChanges();  // Trigger UI update after data assignment
        });

      }
    });
    this.today = moment(new Date()).format('DD/MM/YYYY') + ' ' + moment(new Date()).format('LT');
  }

  addPaymentDetails(confirmation) {
    this.submitted = true;
    if (this.cardDetailsForm.invalid) {
      return;
    }
    this.paymentBody.card.cvn = this.cardDetailsForm.value.cvn;
    this.paymentBody.card.number = this.cardDetailsForm.value.cardNumber;
    this.paymentBody.card.expiry.month = this.cardDetailsForm.value.month;
    this.paymentBody.card.expiry.year = this.cardDetailsForm.value.year;
    this.paymentBody.card.name = this.cardDetailsForm.value.userName;
    this.nextStep(confirmation)
  }

  nextStep(modal) {
    this.modalService.open(modal, {size: 'md', backdrop: 'static'});
  }

  async make_payment(modal) {
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    await this.authService.makePayment(this.paymentData.authKey, this.paymentBody).then(async (paymentResponse) => {
      if (paymentResponse.status === 404) {
        this.spinner.hide();
        this.hidepaymentPage = false;
        this.hideForm = true;
        modal.dismiss('Cross-Click');
        this.showPaymentBtn = true;
        this.errorBlock.errorMessage = 'Auth Key is expired';
        this.errorBlock.showBlock = true;
        return;
      }
      if (paymentResponse.status === 400) {
        this.spinner.hide();
        this.hidepaymentPage = false;
        this.hideForm = true;
        modal.dismiss('Cross-Click');
        this.errorBlock.errorMessage = 'Invalid Credentials';
        this.errorBlock.showBlock = true;
        this.showPaymentBtn = true;
        return;
      }
      this.hideForm = true;
      modal.dismiss('Cross-Click');
      this.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      await this.authService.checkPayment_Status(this.paymentData.authKey).then(async (statusResponse) => {
        if (statusResponse.status === 404) {
          this.toastr.clear();
          this.toastr.error(statusResponse.error.message, 'Error', {
            positionClass: 'toast-top-center'
          });
          this.spinner.hide();
          return;
        }
        if (statusResponse.status === 400) {
          this.toastr.clear();
          this.hidepaymentPage = false;
          this.hideForm = true;
          this.showPaymentBtn = true;
          this.errorBlock.errorMessage = Object.keys(statusResponse.error).includes('description') ? statusResponse.error.description : statusResponse.error.message;
          this.errorBlock.orderStatus = statusResponse.error.status;
          this.errorBlock.showBlock = true;
          this.spinner.hide();
          return;
        }
        this.hideForm = true;
        this.toastr.clear();
        this.toastr.success('Transaction Status ' + statusResponse.description, 'Message', {
          positionClass: 'toast-top-center'
        });

        this.hidepaymentPage = true;
        this.paymentResponseData.year = statusResponse.data.txn.paymentMethod.card.expiry.year;
        this.paymentResponseData.month = statusResponse.data.txn.paymentMethod.card.expiry.month;
        this.paymentResponseData.cardNumber = statusResponse.data.txn.paymentMethod.card.number;
        this.paymentResponseData.receiptNumber = `Thank you! Your payment was successfully processed for <br> Receipt ${statusResponse.data.txn.receiptNumber} You may now close this window.`;
        this.spinner.hide();
        if (sessionStorage.getItem('script-capture')) {
          this.closewindow();
        } else {
          this.closewindow();
        }

      }).catch(err => {
        this.toastr.clear();
        this.toastr.error(err.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        this.errorBlock.errorMessage = err.error.message;
        this.errorBlock.orderStatus = err.error.status;
        this.errorBlock.showBlock = true;
        this.spinner.hide();
      });
    }).catch(err => {
      this.toastr.clear();
      this.toastr.error(err.error.message, 'Error', {
        positionClass: 'toast-top-center'
      });
      this.spinner.hide();
    })
  }

  async retryPayment() {
    const paymentInitBody = {
      'amount': this.paymentData.amount,
      'crn1': '',
      'merchantReference': '',
      'emailAddress': '',
      'storeCard': false,
      'url': ''
    }
    this.submitted = false;
    this.cardDetailsForm.reset();
    this.authService.initialize_payment(paymentInitBody, sessionStorage.getItem('orderId'), 'false', 'true', 'false', 'true').then((paymentResponse) => {
      if (paymentResponse.status === 400) {
        this.toastr.clear();
        this.toastr.error(typeof paymentResponse.error.message === 'string' ? paymentResponse.error.message : 'Invalid fields', 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      if (paymentResponse.status === 404) {
        this.toastr.clear();
        this.toastr.error(paymentResponse.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      this.paymentData.authKey = paymentResponse.authKey;
      this.errorBlock.showBlock = false;
      this.errorBlock.errorMessage = ''
      this.errorBlock.orderStatus = ''
      this.hidepaymentPage = false;
      this.hideForm = false;
      this.showPaymentBtn = false;
      this.cdr.detectChanges();
    }).catch(err => {
      this.toastr.clear();
      this.toastr.error(err.error.message, 'Error', {
        positionClass: 'toast-top-center'
      });
    })
  }

  selectedCard(card) {
    this.cardDetailsForm.reset();
    this.submitted = false;
    this.selectedPaymentCard.logo = card.logo;
    this.selectedPaymentCard.name = card.name;
  }

  closewindow() {
      this.hideForm = true;
      this.hidepaymentPage = true;
      this.errorBlock.showBlock = false;
      this.showMessage = false;
      this.cdr.detectChanges();
  }

}
