import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, FormsModule, Validators} from '@angular/forms';
import {AuthService} from '../../../shared/auth/auth.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MonthFormatPipe} from '../../../shared/pipes/month-format.pipe';
import {element} from 'protractor';
import {NgxSpinnerService} from 'ngx-spinner';

const moment = require('moment');

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  model = null;
  selectedState = null;
  submitted = false;
  userRegisteration = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    middleName: new FormControl('',),
    lastName: new FormControl('', [Validators.required]),
    number: new FormControl('', [Validators.required, Validators.pattern('[0-9]{10}|[0-9]{9}')]),
    landLineNumber: new FormControl('', [Validators.pattern('[0-9]{10}|[0-9]{9}')]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?^&]{7,}$/)]),
    confirm_pwd: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?^&]{7,}$/)]),
    addressType: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    suburb: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    postcode: new FormControl('', [Validators.required, Validators.pattern('[0-9]{4}')]),
  });

  platform: any;
  reponse: any;
  showAddress = false;
  dateOfBirth = null;
  ismisMatch = false;
  isValid_Date = true;
  isSequential = false;
  isFuture_Date = false;
  showpwd = false;
  isFuturDate = false;
  modalType = '';
  showConfirmPassword = false;
  states = ['NSW', 'QLD', 'ACT', 'VIC', 'NT', 'WA', 'TAS', 'SA'];
  maxDate: any;

  constructor(private authService: AuthService, private toastr: ToastrService, private router: Router,
              private modalService: NgbModal, private monthPipe: MonthFormatPipe, private spinner: NgxSpinnerService) {
  }


  get rf() {
    return this.userRegisteration.controls;
  }


  ngOnInit(): void {
    this.platform = JSON.parse(sessionStorage.getItem('platform'));
    this.maxDate = {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()};
  }


  onSubmit(checkbox, consentForm) {
    this.submitted = true;
    let TACAccepted = false;
    let PSAccepted = false;
    let consentAccepted = false;


    if (!this.isValidDate(this.model) && typeof this.model === 'string') {
      this.isValid_Date = false;
      return;
    }
    const date = {
      ...this.model
    }
    this.isFuture_Date = this.isFutureDate(this.monthPipe.transform(date));
    if (this.isFuture_Date) {
      return;
    }
    this.isValid_Date = true;
    if (this.userRegisteration.value.password !== this.userRegisteration.value.confirm_pwd) {
      this.ismisMatch = true;
      return;
    }
    if (this.hasSequentialCharacters(this.userRegisteration.value.password)) {
      this.isSequential = true;
      return;
    }

    this.ismisMatch = false;
    this.isSequential = false;
    if (!checkbox.checked) {
      this.toastr.clear();
      this.toastr.error('Please Tick the Checkbox to accept terms and conditions', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    } else {
      TACAccepted = true;
      PSAccepted = true;
      consentAccepted = true;
    }

    if (!consentForm.checked) {
      consentAccepted = false;
    } else {
      consentAccepted = true;
    }
    const body = {
      'firstName': this.userRegisteration.value.firstName,
      'middleName': this.userRegisteration.value.middleName || '',
      'lastName': this.userRegisteration.value.lastName,
      'email': this.userRegisteration.value.email,
      'password': this.userRegisteration.value.password,
      'number': '+61' + this.userRegisteration.value.number,
      // tslint:disable-next-line:max-line-length
      'landLineNumber': this.userRegisteration.value.landLineNumber === '' ? ' ' : '+61' + parseInt(this.userRegisteration.value.landLineNumber, 10),
      'twofaEnabled': false,
      'role': 'User',
      'userType': 'user',
      'dateOfBirth': moment(this.monthPipe.transform(this.model)).format('YYYY-MM-DD'),
      'platformId': this.platform._id,
      PSAccepted: PSAccepted,
      TACAccepted: TACAccepted,
      consentAccepted: true
    }
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    this.authService.register_new_user(body).then(async (resp: any) => {
      this.reponse = resp.userCreated;
      if (resp.status === 400) {
        this.toastr.clear();
        this.toastr.error('User already Exits', 'Error', {
          positionClass: 'toast-top-center'
        });
        this.spinner.hide();
      } else if (resp.status === 500) {
        this.toastr.clear();
        this.toastr.error('User registration failed', 'Error', {
          positionClass: 'toast-top-center'
        });
        this.spinner.hide();
      } else {
        this.toastr.clear();
        this.toastr.success('User Registration Successfull', 'Message', {
          positionClass: 'toast-top-center'
        });
        this.spinner.hide();
        const addressBody = {
          userId: this.reponse._id,
          addressType: this.userRegisteration.value.addressType,
          address: this.userRegisteration.value.address,
          suburb: this.userRegisteration.value.suburb,
          state: this.userRegisteration.value.state,
          // tslint:disable-next-line:radix
          postcode: parseInt(this.userRegisteration.value.postcode)
        }
        await this.authService.create_Address(addressBody).then((addressResp: any) => {
          this.showAddress = true;
          this.submitted = false;
        });
        this.router.navigate(['/pages/launch']);
      }
    }).catch(err => {
      this.toastr.error('User registration failed', 'Error', {
        positionClass: 'toast-top-center'
      });
      this.spinner.hide();
    })
  }

  hasSequentialCharacters(password) {
    const length = password.length;
    // Loop through the password to check for sequential letters and numbers
    for (let i = 0; i < length - 1; i++) {
      const currentChar = password.charCodeAt(i);
      // Check for ascending sequences of numbers/letters
      if (password.charCodeAt(i + 1) === currentChar) {
        return true;
      }
      // Check for descending sequences of numbers/letters
      if (password.charCodeAt(i + 1) === currentChar) {
        return true;
      }

      // Check if the password is repeated
      if (password.charAt(i + 1) === password.charAt(i)) {
        return true;
      }
    }
    return false;
  }

  showPassword(password, passwordType) {
    if (passwordType === 'password') {
      this.showpwd = !this.showpwd;
      password.type = this.showpwd ? 'text' : 'password';
    } else {
      this.showConfirmPassword = !this.showConfirmPassword;
      password.type = this.showConfirmPassword ? 'text' : 'password';
    }
  }

  openModal(smallModal, type) {
    this.modalType = type;
    this.modalService.open(smallModal, {size: 'xl', backdrop: 'static'})
  }

  isValidDate(date) {
    const regex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
    return regex.test(date);
  }

  isFutureDate(inputDate) {
    const now = moment(); // Current date and time
    const date = moment(inputDate).format('YYYY-MM-DD');
    const dateToCheck = moment(date, 'YYYY-MM-DD', true);
    if (!dateToCheck.isValid()) {
      return !dateToCheck.isValid();
    }

    return dateToCheck.isAfter(now, 'day');
  }
}
