<section id="shopping-cart">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Order History</h4>
          <div class="row mt-2">
            <div class="col-md-3 col-12">
              <select class="form-control" (change)="OnSearchBy($event)">
                <option *ngFor="let ele of searchBy;" [value]="ele.value">{{ ele.title }}</option>
              </select>
            </div>
            <div class="col-md-3 col-12">
              <fieldset>
                <div class="input-group" *ngIf="search.title === 'Name'">
                  <input type="text" #Nametextbox class="form-control"
                         (keyup.enter)="searchOrder(Nametextbox.value)"
                         (keyup.backspace)="allOrders('All')"
                         placeholder="Enter Name">
                  <div class="input-group-append">
                    <button class="btn light-gray"
                            (click)="searchOrder(Nametextbox.value)"
                            type="button"><i class="ft-search"></i></button>
                  </div>
                </div>

                <div class="input-group" *ngIf="search.title === 'Phone Number'">
                  <input type="text" #Phonetextbox class="form-control" (keyup.enter)="searchOrder(Phonetextbox.value)"
                         maxlength="10" (keyup.backspace)="allOrders('All')"
                         placeholder="Enter Phone Number">
                  <div class="input-group-append">
                    <button class="btn light-gray"
                            (click)="searchOrder(Phonetextbox.value)"
                            type="button"><i class="ft-search"></i></button>
                  </div>
                </div>
                <div class="input-group" *ngIf="search.title === 'Email'">
                  <input type="text" #Emailtextbox class="form-control" (keyup.enter)="searchOrder(Emailtextbox.value)"
                         (keyup.backspace)="allOrders('All')"
                         placeholder="Enter Email">
                  <div class="input-group-append">
                    <button class="btn light-gray"
                            (click)="searchOrder(Emailtextbox.value)"
                            type="button"><i class="ft-search"></i></button>
                  </div>
                </div>
                <div class="input-group" *ngIf="search.title === 'Order No'">
                  <input type="text" #OrderNotextbox class="form-control" (keyup.backspace)="allOrders('All')"
                         (keyup.enter)="searchOrder(OrderNotextbox.value)" placeholder="Enter Order No">
                  <div class="input-group-append">
                    <button class="btn light-gray"
                            (click)="searchOrder(OrderNotextbox.value)"
                            type="button"><i class="ft-search"></i></button>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="col-md-6 col-12">
              <div class="input-group float-right">
                <div class="input-group-prepend dp-hidden">
                  <input
                    name="datepicker"
                    class="form-control dp-hidden"
                    ngbDatepicker
                    #datepicker="ngbDatepicker"
                    [autoClose]="'outside'"
                    (dateSelect)="onDateSelection($event)"
                    [displayMonths]="1"
                    [dayTemplate]="t"
                    outsideDays="hidden"
                    [startDate]="fromDate!"
                    tabindex="-1"
                  />
                  <ng-template #t let-date let-focused="focused">
					<span
            class="custom-day"
            [class.focused]="focused"
            [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)"
            (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null"
          >
						{{ date.day }}
					</span>
                  </ng-template>
                </div>
                <input
                  #dpFromDate
                  class="form-control"
                  placeholder="yyyy-mm-dd"
                  name="dpFromDate"
                  [value]="from + ' - ' + to"
                  (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
                />
                <div class="input-group-append">
                  <div class="input-group-text" (click)="datepicker.toggle()">
                    <i class="fa fa-calendar" style="cursor: pointer;"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-12">
            <div class="d-flex align-items-end justify-content-end">
              <button class="float-right primary-color btn mx-1 btn-status-padding completed"
                      [ngClass]="globalOrderStatus === 'completed'? 'secondary-hover text-under-line' : 'primary-hover'"
                      (click)="filter('completed')">Completed
              </button>
              <button class="float-right mx-1 btn-status-padding"
                      [ngClass]="globalOrderStatus === 'cancelled'? 'btn-danger btn text-under-line'
                          : 'bg-light-danger btn'"
                      (click)="filter('cancelled')">Cancelled
              </button>
            </div>
          </div>
        </div>
        <div class="card-content">
          <div class="title text-center mt-2">
                 <span class="text-uppercase card-title heading-width"
                       *ngIf="rows.length > 0">{{ globalOrderStatus }}</span>
            <h3 class="card-title" *ngIf="rows.length === 0">{{ message }}</h3>
          </div>

          <div class="card-body table-responsive">
            <table class="table text-center m-0 mt-1">
              <thead>
              <tr>
                <th>Order no</th>
                <th>Date and Time</th>
                <th>Order Total</th>
                <th>Customer</th>
                <th>Status</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let order of rows">
                <td>{{ order?.orderNo }}</td>
                <td>{{ order?.orderDate  | date: 'mediumDate' }} {{ order?.orderDate  | date: 'shortTime' }}</td>
                <td>$ {{ order?.totalAmount }}</td>
                <td>{{ order?.name }}</td>
                <td *ngIf="order.status !== 'preOrder'">
                  <span class="badge badge-pill cursor-pointer text-capitalize"
                        [ngClass]="{'bg-light-info': order?.status === 'new',
                'bg-light-secondary': order?.status === 'pending',
                'shipped-light': order?.status === 'shipped',
                'primary-hover': order?.status === 'completed',
                'bg-light-danger': order?.status === 'cancelled',
                'navy-light': order?.status === 'dispense',
                'payment-process': order?.status === 'paymentProcess',
                'yellow-light': order?.status === 'refund'
                }"
                  >
                  {{ order?.status === 'paymentProcess' ? 'Payment Process' : order?.status }}</span>
                </td>
                <td *ngIf="order.status === 'preOrder'">
                  <span class="badge badge-pill cursor-pointer text-capitalize">Preorder</span>
                </td>
                <td>
                  <button class="badge secondary-btn btn"
                          (click)="viewOrder(order,ViewOrder)">View
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-footer">
          <div class="card-footer">
            <ngb-pagination class="float-right" [collectionSize]="this.maxPageSize * 10"
                            (pageChange)="pagination($event)"
                            [(page)]="page" [maxSize]="5" [rotate]="true" [boundaryLinks]="true">
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #ViewOrder let-modal>
  <div class="modal-header">
    <h4 class="modal-title">View Order - #{{ selectedOrder.orderNo }}</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <h5 class="font-weight-bold font-italic">User Information</h5>
    <hr>
    <div class="row">
      <div class="col-md-12 col-xl-9 col-12" style="overflow-y: scroll !important;">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-8 col-12">
            <div class="row">
              <div class="col-md-6 col-lg-3 col-sm-12 col-xl-4 col-12">
                <label class="font-weight-bold">Name</label>
                <p>{{ selectedOrder?.name }}</p>
              </div>
              <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                <label class="font-weight-bold">Mobile Number</label>
                <p>{{ selectedOrder?.mobile }}</p>
              </div>
              <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                <label class="font-weight-bold">Email</label>
                <p>{{ selectedOrder?.email }}</p>
              </div>
              <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                <label class="font-weight-bold">Order By</label><br>
                <p>{{ selectedOrder?.orderedBy }}</p>
              </div>
              <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                <label class="font-weight-bold">Ordered Date and Time</label><br>
                <p>{{ selectedOrder?.orderDate | date:'mediumDate' }} {{ selectedOrder?.orderDate | date:'shortTime' }}</p>
              </div>
              <div class="col-md-6 col-lg-3 col-xl-4 col-12"
                   *ngIf="selectedOrder?.status === 'cancelled' && (selectedOrder?.rejectReason !== '' || selectedOrder?.cancellation_reason.reason !== '')">
                <label class="font-weight-bold">Cancellation Reason</label><br>
                <p *ngIf="selectedOrder?.rejectReason !== ''">{{ selectedOrder?.rejectReason }}</p>
                <p
                  *ngIf="selectedOrder?.cancellation_reason.reason !== ''">{{ selectedOrder?.cancellation_reason.reason }}</p>
              </div>

              <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                <label class="font-weight-bold">Delivery Type</label><br>
                <span class="badge bg-light-primary">{{ selectedOrder?.pickup ? 'Pickup' : 'Delivery' }}</span>
                <span class="ml-2">{{ !selectedOrder?.pickup ? selectedOrder?.deliveryInstruction : '' }}</span>
              </div>
              <div class="col-md-6 col-lg-3 col-xl-4 col-12">
                <label class="font-weight-bold">Status</label><br>
                <span
                  class="badge bg-light-success btn text-capitalize" [ngClass]="{'bg-light-success btn' : pharmacyUser?.pharmacyUserStatus === 'verified','bg-light-dark btn' : pharmacyUser?.pharmacyUserStatus === 'blacklisted',
                            'bg-light-danger btn': pharmacyUser?.pharmacyUserStatus === 'unverified'}">
                              {{ pharmacyUser?.pharmacyUserStatus }}</span>
              </div>
              <div class="col-md-6 col-lg-3 col-xl-4  col-12 pt-1">
                <label class="font-weight-bold">Order Status</label><br>
                <span
                  *ngIf="selectedOrder.partialShipmentStatus !== 'shipped' && selectedOrder.partialShipmentStatus !== 'completed'"
                  class="text-capitalize">{{ selectedOrder.status }}</span>
                <span
                  *ngIf="selectedOrder.partialShipmentStatus === 'shipped' || selectedOrder.partialShipmentStatus === 'completed'"
                  class="text-capitalize">{{ selectedOrder.partialShipmentStatus }}
            </span>
              </div>
              <div class="col-md-6 col-lg-3 col-xl-4  col-12 pt-1">
                <label class="font-weight-bold">Address</label>
                <p>
                  <b>{{ selectedOrder?.addressType }}</b> {{ selectedOrder?.address + ' ' + selectedOrder?.suburb + ' ' + selectedOrder?.state + ' ' + selectedOrder?.postcode }}
                </p>
              </div>
              <div class="col-md-6 col-lg-3 col-xl-4  col-12"
                   *ngIf="selectedOrder.pickup && selectedOrder?.pickup_name !== ''">
                <label class="font-weight-bold">Pickup Name</label><br>
                <p>{{ selectedOrder?.pickup_name }} - {{ selectedOrder?.relationship_with_customer }}</p>
              </div>
              <div class="col-md-6 col-lg-3 col-xl-4  col-12"
                   *ngIf="this.selectedOrder.uploadedScript!== '' && this.selectedOrder.uploadedScript!== ' '">
                <label class="font-weight-bold">{{ showUrl ? 'Script Image' : 'Script URL' }}
                  <i
                    *ngIf="showUrl"
                    class="ml-2 ft-download-cloud cursor-pointer font-medium-1" ngbTooltip="Download Script"
                    (click)="downloadScript(this.selectedOrder?.uploadedScript)"></i>
                </label>
                <br>
                <div *ngIf="showUrl">
                  <img class="cursor-pointer" src="{{this.selectedOrder?.uploadedScript}}" alt="" width="60"
                       height="40"
                       (click)="isCollapsed = !isCollapsed"
                       [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                  <div id="collapseExample" [ngbCollapse]="isCollapsed">
                    <img src="{{this.selectedOrder?.uploadedScript}}" alt="" width="400" height="300">
                  </div>
                </div>
                <p *ngIf="!showUrl">{{ this.selectedOrder?.uploadedScript }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-12 col-xl-4 col-12">
            <div class="chat-container d-none d-sm-none d-xl-block d-md-none d-lg-none">
              <div class="chat-header bg-secondary">Customer Follow Up Note <i (click)="expand = !expand" [ngClass]="!expand ? 'ft-maximize' : 'ft-minimize'" class="cursor-pointer float-right"></i></div>
              <div [ngClass]="!expand ? 'chat-box' : 'expand-chat-box'">
                <div class="messages">
                  <div class="text-left" *ngFor="let note of this.customerNotes; let i = index">
                    <a class="mr-1 float-right" placement="top" ngbTooltip="Delete Note"
                       *ngIf="customerNotes.length > 0 && this.userData.auth.data.userType !== 'staff'"
                       (click)="delete_Note(note,modal)"><i class="ft-trash text-danger"></i></a>
                    {{ note.fallowUp_note }}
                    <p
                      class="mt-0 ml-1">{{ note.fallowUp_timeStamp | date:'mediumDate' }} {{ note.fallowUp_timeStamp | date:'shortTime' }}
                      {{ note.note_addedBy_userName }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-xl-none">
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-5 pt-2">
                  <div class="chat-container">
                    <div class="chat-header bg-secondary">Customer Follow Up Note <i (click)="expand = !expand" [ngClass]="!expand ? 'ft-maximize' : 'ft-minimize'" class="cursor-pointer float-right"></i></div>
                    <div [ngClass]="!expand ? 'chat-box' : 'expand-chat-box'">
                      <div class="messages">
                        <div class="text-left" *ngFor="let note of this.customerNotes; let i = index">
                          <a class="mr-1 float-right" placement="top" ngbTooltip="Delete Note"
                             *ngIf="customerNotes.length > 0 && this.userData.auth.data.userType !== 'staff'"
                             (click)="delete_Note(note,modal)"><i class="ft-trash text-danger"></i></a>
                          {{ note.fallowUp_note }}
                          <p
                            class="mt-0 ml-1">{{ note.fallowUp_timeStamp | date:'mediumDate' }} {{ note.fallowUp_timeStamp | date:'shortTime' }}
                            {{ note.note_addedBy_userName }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-5 pt-2">
                  <div class="chat-container">
                    <div class="chat-header bg-secondary">Pharmacy Notes</div>
                    <div class="chat-box">
                      <div class="messages">
                        <div class="text-left" *ngFor="let note of this.pharmacyNotes; let i = index">
                          <a class="mr-1 float-right" placement="top" ngbTooltip="Delete Note"
                             *ngIf="pharmacyNotes.length > 0 && this.userData.auth.data.userType !== 'staff'"
                             (click)="delete_Note(note,modal)"><i class="ft-trash text-danger"></i></a>
                          {{ note.fallowUp_note }}
                          <p
                            class="mt-0 ml-1">{{ note.fallowUp_timeStamp | date:'mediumDate' }} {{ note.fallowUp_timeStamp | date:'shortTime' }}
                            {{ note.note_addedBy_userName }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
        <br>
        <div class="d-flex">
          <h5 class="font-weight-bold font-italic">User Script</h5>
        </div>
        <div class="table-responsive">
          <table class="table items-table">
            <thead>
            <tr>
              <!--          <th class="text-left">QR Code</th>-->
              <th>Token Number</th>
              <th>Script Medication</th>
              <th>Match status</th>
              <th>Product Name</th>
              <th>Quantity</th>
              <th
                *ngIf="selectedOrder?.status === 'pendingShipment' && !selectedOrder?.pickup ">
                Partial Shipped Quantity
              </th>
              <th
                *ngIf="selectedOrder?.status === 'pendingShipment' && !selectedOrder?.pickup ">
                Remaining Quantity
              </th>
              <th>Price</th>
              <ng-container *ngIf="selectedOrder.status === 'new'">
                <th>Actions</th>
              </ng-container>
              <th>Backorder</th>

            </tr>
            </thead>
            <tr *ngFor="let item of this.orderedItems;let i = index;">
              <!--          <td class="py-0">-->
              <!--            <qrcode [qrdata]="item?.token" [width]="60" [errorCorrectionLevel]="'M'"></qrcode>-->
              <!--          </td>-->
              <td> <span (click)="copyToken(item?.token)" class="text-UpperCase">
                        <i class="cursor-pointer ft-copy mr-1 " ngbTooltip="Token copied" triggers="click:blur"></i>
                      </span>{{ item?.token }}
              </td>
              <td>{{ item?.product_name === '' ? item?.product_brand_name : item?.product_name.includes(item?.medication_strength) ? item?.product_name : item?.product_name + ' ' + item?.medication_strength }}</td>
              <td><span class="text-capitalize badge"
                        [ngClass]="{
                  'bg-light-success':item?.matchStatus === 'Match',
                  'bg-light-danger':item?.matchStatus === 'Unmatched',
                  'bg-light-info':item?.matchStatus === 'Partial match'
                  }"
              >{{ item?.matchStatus }}</span></td>
              <td class=" cursor-pointer"
                  (click)="showGlobalProd(item.globalProductId)">{{ item.GlobalProductOriginalName === undefined ? item?.product_name : item?.GlobalProductOriginalName === '' ? 'Unmatched' : item?.GlobalProductOriginalName }}
              </td>
              <td>{{ item?.quantity }}</td>
              <td
                *ngIf="selectedOrder?.status === 'pendingShipment' && !selectedOrder?.pickup ">{{ item?.shippedQuantity }}
              </td>
              <td
                *ngIf="selectedOrder?.status === 'pendingShipment' && !selectedOrder?.pickup ">{{ item?.remainingQuantity }}
              </td>
              <td>{{ item?.note }}</td>
              <td *ngIf="item.isBackOrder" class="text-center">
                 <span
                   class="badge bg-light-warning btn text-capitalize">Backorder</span>
              </td>
            </tr>
            <tr>
              <td colspan="5" class="text-right"><b>Item Total</b></td>
              <td>${{ selectedOrder?.itemsCost | number:'1.2-2' }}</td>
            </tr>
            <tr *ngIf="!selectedOrder?.pickup">
              <td colspan="5" class="text-right"><b>Delivery Charge</b></td>
              <td>
                ${{ selectedOrder.freightCharge }}
              </td>
            </tr>
            <tr>
              <td colspan="5" class="text-right"><b>Total Amount:</b></td>
              <td><p class="font-weight-bold font-medium-1">${{ selectedOrder?.totalAmount | number:'1.2-2' }}<br>
                <span
                  class="font-weight-normal font-small-3">*Includes GST of ${{ selectedOrder.taxAmount | number:'1.2-2' }}</span>
              </p></td>
            </tr>
            <tbody>
            </tbody>
          </table>
        </div>
        <br>
        <h5 class="font-weight-bold font-italic">Pharmacy Details</h5>
        <hr>
        <div class="row">
          <div class="col-md-4 col-sm-12 col-lg-4 col-12">
            <div class="col-12">
              <label class="font-weight-bold">Pharmacy Name</label>
              <p>{{ selectedOrder?.pharmacyId?.pharmacy_name }}</p>
            </div>
            <div class="col-12 mt-2">
              <label class="font-weight-bold">Mobile Number</label>
              <p>{{ selectedOrder?.pharmacyId?.phoneNumber }}</p>
            </div>
          </div>
          <div class="col-md-4 col-sm-12 col-lg-4 col-12">
            <div class="col-12">
              <label class="font-weight-bold">Email</label>
              <p>{{ selectedOrder?.pharmacyId?.email }}</p>
            </div>
            <div class="col-12">
              <label class="font-weight-bold">Pharmacy Address</label>
              <p>{{
                  selectedOrder?.pharmacyId?.address + ' ' + selectedOrder?.pharmacyId?.suburb + ' ' + selectedOrder?.pharmacyId?.state +
                  ' ' + selectedOrder?.pharmacyId?.postcode
                }}</p>
            </div>
          </div>
        </div>
        <!--Payment Details-->
        <br>
        <h5 class="font-weight-bold font-italic">Payment Details
        </h5>
        <div
          *ngIf="selectedOrder.status === 'paymentProcess' || selectedOrder.status === 'pendingShipment'  || selectedOrder.status === 'dispense' || selectedOrder.status === 'shipped' || selectedOrder.status === 'completed'">
          <hr>
          <div class="table-responsive" *ngIf="selectedOrder?.orderPaymentDetails.length > 0">
            <table class="table table-bordered m-0">
              <thead class="table-header">
              <tr>
                <th>Order No</th>
                <th>Amount</th>
                <th>Order Date</th>
                <th>Payment Method</th>
                <th>Transaction Number</th>
                <th>Date & Time</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let payment of selectedOrder.orderPaymentDetails">
                <td>{{ payment?.paymentAdditionalDetails[0]?.orderNumber }}</td>
                <td>$ {{ payment?.orderPayment_Amount }}</td>
                <td>
                  <p>{{ selectedOrder?.orderDate | date:'mediumDate' }} {{ selectedOrder?.orderDate | date:'shortTime' }}</p>
                </td>
                <td>{{ payment?.orderPayment_method === 'Internet' ? 'Online Payment' : payment?.orderPayment_method }}</td>
                <td>{{ payment?.orderPayment_reference }}</td>
                <td>{{ payment?.orderPayment_initiated_date | date:'mediumDate' }} {{ payment?.orderPayment_initiated_date | date:'shortTime' }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!--      Additional Cost Details-->
        <br>
        <h5 class="font-weight-bold font-italic" *ngIf="selectedOrder?.additionalCosts.length > 0">Additional
          Costs</h5>
        <div class="row" *ngIf="selectedOrder?.additionalCosts.length > 0">
          <div class="col-md-12 col-12">
            <div class="table-responsive">
              <table class="table table-bordered m-0">
                <thead>
                <tr>
                  <th>Amount</th>
                  <th>Description</th>
                  <th>Payment Status</th>
                  <th>Amount Added By</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let extracost of selectedOrder?.additionalCosts;let i = index;">
                  <td>$ {{ extracost.amount }}</td>
                  <td>{{ extracost.description }}</td>
                  <td>   <span [ngClass]="extracost.paid ? 'bg-light-success' : 'bg-light-secondary'"
                               class="badge ">{{ extracost.paid ? 'Paid' : 'Un-Paid' }}</span></td>
                  <td>{{ extracost.amount_addedBy_userName }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!--      Delivery Details-->
        <br>
        <div *ngIf="selectedOrder?.shippingDetails.length > 0">
          <h5 class="font-weight-bold font-italic">Delivery Details</h5>
          <hr>
          <div class="table-responsive">
            <table class="table table-bordered m-0">
              <thead class="table-header">
              <tr>
                <th>Order No</th>
                <th>Order Date</th>
                <th>Carrier</th>
                <th>Connote Number</th>
                <th>Delivery Date & Time</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let ele of selectedOrder?.shippingDetails">
                <td class="cursor-pointer"
                    (click)="viewChildOrder(ele?.itemShippedDetails[0]?.orderNumber,productsShipped)">
                  <span style="text-decoration: underline;">{{ ele?.itemShippedDetails[0]?.orderNumber }}</span>
                </td>
                <td>
                  <p>{{ selectedOrder?.orderDate | date:'mediumDate' }} {{ selectedOrder?.orderDate | date:'shortTime' }}</p>
                </td>
                <td>{{ ele?.freightCarrier }}</td>
                <td>{{ ele?.trackingNo }}</td>
                <td>{{ ele?.deliveredDate  | date:'mediumDate' }} {{ ele?.deliveredDate  | date:'shortTime' }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-xl-3 col-12">
        <div class="chat-container mx-1 my-1 d-none d-sm-none d-xl-block d-md-none d-lg-none">
          <div class="chat-header bg-secondary">Pharmacy Notes</div>
          <div class="pharmacy-chat-box">
            <div class="messages">
              <div class="text-left" *ngFor="let note of this.pharmacyNotes; let i = index">
                <a class="mr-1 float-right" placement="top" ngbTooltip="Delete Note"
                   *ngIf="customerNotes.length > 0 && this.userData.auth.data.userType !== 'staff'"
                   (click)="delete_Note(note,modal)"><i class="ft-trash text-danger"></i></a>
                {{ note.fallowUp_note }}
                <p
                  class="mt-0 ml-1">{{ note.fallowUp_timeStamp | date:'mediumDate' }} {{ note.fallowUp_timeStamp | date:'shortTime' }}
                  {{ note.note_addedBy_userName }}
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</ng-template>

<!--view script image-->
<ng-template #view_script_image let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Script Image</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="text-center">
      <img [src]="viewScript" alt="" width="100%" height="auto">
    </div>
  </div>
</ng-template>



<ng-template #productsShipped let-modal let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Shipped Products</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="table-responsive">
      <table class="table items-table">
        <thead>
        <tr>
          <!--          <th class="text-left">QR Code</th>-->
          <th>Token Number</th>
          <th>Script Info</th>
          <th>Brand Name</th>
          <th>Product Name</th>
          <th>Repeats</th>
          <th>Quantity</th>
          <th>
            Partial Shipped Quantity
          </th>
          <th>
            Remaining Quantity
          </th>
          <th>Price</th>
          <th>Instruction</th>
          <th>Note</th>
          <ng-container *ngIf="childOrder.status === 'new'">
            <th>Actions</th>
          </ng-container>
          <th>Backorder</th>

        </tr>
        </thead>
        <tr *ngFor="let item of this.childOrder.orderedItem;let i = index;">
          <!--          <td class="py-0">-->
          <!--            <qrcode [qrdata]="item?.token" [width]="60" [errorCorrectionLevel]="'M'"></qrcode>-->
          <!--          </td>-->
          <td> <span (click)="copyToken(item?.token)" class="text-UpperCase">
                        <i class="cursor-pointer ft-copy mr-1 " ngbTooltip="Token copied" triggers="click:blur"></i>
                      </span>{{ item?.token }}
          </td>
          <td class="text-center">
            <div class="d-flex">
              <img *ngIf="item?.scriptData?.uploadedScript !== '' && item?.scriptData?.type !== 'url'"
                   class="cursor-pointer"
                   src="{{ item?.scriptData?.uploadedScript }}"
                   alt="" width="60" height="40"
                   (click)="viewScript_image(item?.scriptData?.uploadedScript,view_script_image)">
              <span ngbTooltip="Download ScriptImage"
                    *ngIf="item?.scriptData?.uploadedScript !== '' && item?.scriptData?.type !== 'url'"
                    class="ft-download-cloud cursor-pointer ml-2"
                    (click)="downloadScript(item?.scriptData?.uploadedScript)"></span>
            </div>
            <a *ngIf="item?.scriptData?.type === 'url'" target="_blank"
               href="{{item?.scriptData?.uploadedScript}}" class="text-primary">
                <span style="text-decoration: underline" ngbTooltip="{{item?.scriptData?.uploadedScript}}"
                      placement="top">Click Here</span>
            </a>
          </td>
          <td>{{ item?.product_brand_name }}</td>
          <td class=" cursor-pointer"
              (click)="showGlobalProd(item.globalProductId)">{{ item?.product_name }}
          </td>
          <td>{{ item?.repeats }}</td>
          <td>{{ item?.quantity }}</td>
          <td>{{ item?.shippedQuantity }}</td>
          <td>{{ item?.remainingQuantity }}</td>
          <td>${{ item?.price }}</td>
          <td>{{ item?.instruction }}</td>
          <td>{{ item?.note }}</td>
          <td *ngIf="item.isBackOrder" class="text-center">
                 <span
                   class="badge bg-light-warning btn text-capitalize">Backorder</span>
          </td>
        </tr>
        <tbody>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>
