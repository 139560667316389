import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../../shared/auth/auth.service';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {Json2csvService} from '../../../../shared/services/json2csv.service';
import {ColumnMode, DatatableComponent} from '@swimlane/ngx-datatable';
import {LocalStorageService} from '../../../../shared/storage/local-storage.service';

@Component({
  selector: 'app-global-prod-list',
  templateUrl: './global-prod-list.component.html',
  styleUrls: ['./global-prod-list.component.scss']
})
export class GlobalProdListComponent implements OnInit {

  @ViewChild(DatatableComponent) table: DatatableComponent;
  productList: any[] = [];

  userData: any;
  showUpload = false;
  file = null;
  closeResult = '';
  selectedProduct: any;
  submitted = false;
  showBox = false;
  limit = 0

  globalProdcutForm = new FormGroup({
    brandName: new FormControl('', [Validators.required]),
    originalName: new FormControl('', [Validators.required]),
    category: new FormControl('', [Validators.required]),
    originalCost: new FormControl('', [Validators.required, Validators.pattern('[0-9.]*')]),
    description: new FormControl(''),
    originalData: new FormControl(''),
    //price_per_mg: new FormControl('', [Validators.pattern('[0-9.]*')]),
    //size_amount: new FormControl('', [Validators.pattern('[0-9.]*'), Validators.required]),
    size_measure: new FormControl(''),
    TGAProductCategory: new FormControl(''),
    productType: new FormControl('', [Validators.required]),
    aromas: new FormControl(''),
    THCLabel: new FormControl(''),
    CBDLabel: new FormControl(''),
    productSchedule: new FormControl(''),
    productSpecies: new FormControl(''),
    barCode: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9]+$')])
  });
  pageTracker = 1;
  maxPageSize = 0;

  medicationStrengths = [];
  MedicationStrength = {
    value: null, isValid: false, submitted: false
  }
  erxProdNames = [];
  ErxProductName = {
    value: null, isValid: false, submitted: false
  };

  stockForm = new FormGroup({
    productName: new FormControl('', [Validators.required]),
    productSKU: new FormControl('', [Validators.pattern('^[a-zA-Z0-9]+$')]),
    stock: new FormControl('', [Validators.required]),
    cost: new FormControl('', [Validators.required, Validators.pattern('[0-9.]*')]),
  });

  categoryList: any[] = [];
  selectedCategory: any;
  selectedPharmacy: any;
  platform: any;
  createdProd: any;
  globalProdselected: any;
  expand = false;
  Pharmacy_productList: any = [];
  product: any;
  response: any = [];
  limitRecords = 10;
  check = false;
  searchBy = 'pagination';
  prodcutName = '';
  flag = 0;
  disableBtn = false;
  imageFile = null;
  pdfFile: any[] = [];
  file64 = {
    url: '', name: ''
  }
  images = [];
  fileValidation = {
    pdftye: false, image: false
  }
  categoryName = '';

  constructor(private authService: AuthService, private modalService: NgbModal, private spinner: NgxSpinnerService,
              private router: Router, private jsonToCSV: Json2csvService, private storage: LocalStorageService,
              private toastr: ToastrService) {
  }

  async ngOnInit(): Promise<void> {
    this.storage.getData('script-capture').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.platform = JSON.parse(sessionStorage.getItem('platform'));
    this.loadGlobalProd();
    this.loadCategories();
    this.loadProducts();
  }

  loadGlobalProd() {
    this.pageTracker = 1;
    this.authService.fetch_global_prods(this.userData, this.platform, this.pageTracker, this.limitRecords).then((resp: any) => {
      this.productList = resp.results.filter(prod => {
        return !prod.deleted;
      });
      this.maxPageSize = resp.totalPages;
    }).catch(err => {
    });
  }

  loadProducts() {
    const pharmacyId = sessionStorage.getItem('pharmacyId');
    this.authService.fetchProductsList_of_pharmacy(this.userData, pharmacyId, 1, "", this.limitRecords).then((resp: any) => {
      this.Pharmacy_productList = resp;
    }).catch(err => {
    });
  }


  loadCategories() {
    this.authService.fetch_catetories(this.userData, this.platform).then((resp) => {
      this.categoryList = resp.data;
    });
  }


  openContent(content, product, size) {
    this.submitted = false;
    this.stockForm.reset();
    this.selectedProduct = product;
    this.stockForm.controls['productSKU'].setValue(product.hasOwnProperty('barCode') ? product.barCode : '');
    this.modalService.open(content, {size: size, backdrop: 'static'})
  }

  open(content, product, size) {
    this.globalProdcutForm.reset();
    this.submitted = true;
    this.images = [];
    this.disableBtn = false;
    this.file64.url = '';
    this.file64.name = '';
    this.imageFile = null;
    this.ErxProductName = {
      value: null, isValid: false, submitted: false
    };
    this.MedicationStrength = {
      value: null, isValid: false, submitted: false
    };
    this.erxProdNames = [];
    this.medicationStrengths = [];
    this.authService.fetch_particularglobalProdcut(this.userData, product._id, this.platform).then((response: any) => {
      this.selectedProduct = response.normalResponse[0];
      this.createdProd = this.selectedProduct;
      this.globalProdcutForm.controls['originalName'].setValue(this.selectedProduct.originalName);
      this.globalProdcutForm.controls['barCode'].setValue(this.selectedProduct.hasOwnProperty('barCode') ? this.selectedProduct.barCode : '');
      this.erxProdNames = this.selectedProduct.erxProductName.length === 0 ? [] : this.selectedProduct.erxProductName;
      this.medicationStrengths= this.selectedProduct.medicationStrength.length === 0 ? [] : this.selectedProduct.medicationStrength;
      this.globalProdcutForm.controls['brandName'].setValue(this.selectedProduct.brandName);
      this.globalProdcutForm.controls['originalCost'].setValue(this.selectedProduct.originalCost);
      this.selectedCategory = this.selectedProduct.category;
      //this.globalProdcutForm.controls['price_per_mg'].setValue(this.selectedProduct.price_per_mg);
      this.globalProdcutForm.controls['size_measure'].setValue(this.selectedProduct.size_measure);
      //this.globalProdcutForm.controls['size_amount'].setValue(this.selectedProduct.size_amount);
      this.globalProdcutForm.controls['description'].setValue(this.selectedProduct.description);
      this.globalProdcutForm.controls['originalData'].setValue(this.selectedProduct.originalData);
      this.globalProdcutForm.controls['TGAProductCategory'].setValue(this.selectedProduct.TGAProductCategory);
      this.globalProdcutForm.controls['THCLabel'].setValue(this.selectedProduct.THCLabel);
      this.globalProdcutForm.controls['CBDLabel'].setValue(this.selectedProduct.CBDLabel);
      this.globalProdcutForm.controls['productSchedule'].setValue(this.selectedProduct.productSchedule);
      this.globalProdcutForm.controls['productSpecies'].setValue(this.selectedProduct.productSpecies);
      this.globalProdcutForm.controls['aromas'].setValue(this.selectedProduct.aromas);
      this.globalProdcutForm.controls['productType'].setValue(this.selectedProduct.productType);
      this.modalService.open(content, {size: size, backdrop: 'static'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    })

  }

  deleteFile(url: any) {
    this.authService.deleteFile(this.userData, url, this.selectedProduct).then((resp: any) => {
      this.toastr.clear();
      this.toastr.error('File deleted',
        'Error', {
          positionClass: 'toast-top-center'
        });
      this.authService.fetch_particularglobalProdcut(this.userData, this.selectedProduct._id, this.platform).then((response: any) => {
        this.selectedProduct = response[0];
      })
    })
  }

  openModal_1(content, global_prod, size) {
    this.submitted = false;
    this.globalProdselected = global_prod;
    this.modalService.open(content, {size: size, backdrop: 'static'})
  }


  showProduct(global_prod, filteredResponse = false) {
    this.globalProdselected = global_prod
    const queryParams = {data: JSON.stringify(this.globalProdselected._id)};
    const urlWithQueryParams = this.router.createUrlTree(['/product-page'], {queryParams}).toString();
    window.open(urlWithQueryParams, '_blank');
  }

  downloadFiles(file, index) {
    const download = document.createElement('a') as HTMLAnchorElement;
    // Set the href and download attributes
    download.setAttribute('href', file.url);
    download.setAttribute('download', file.fileName); // Optional: specify a filename
    // Trigger the download by clicking the anchor tag programmatically
    download.click();
  }

  openCreateModal(create) {
    this.images = [];
    this.pdfFile = [];
    this.ErxProductName = {
      value: null, isValid: false, submitted: false
    };
    this.MedicationStrength = {
      value: null, isValid: false, submitted: false
    }
    this.erxProdNames = [];
    this.medicationStrengths = [];
    this.selectedCategory = null;
    this.file64.url = '';
    this.file64.name = '';
    this.fileValidation.image = false;
    this.fileValidation.pdftye = false;
    this.disableBtn = false;
    this.showUpload = false;
    this.file = null
    this.imageFile = null;
    this.globalProdcutForm.reset();
    this.submitted = false;
    this.modalService.open(create, {size: 'xl', backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  addErxProdNames() {
    this.ErxProductName.submitted = true;
    if (!this.ErxProductName.value || this.ErxProductName.value.trim() === '') {
      return;
    }
    if (!this.erxProdNames.includes(this.ErxProductName.value)) {
      this.erxProdNames = [...this.erxProdNames, this.ErxProductName.value];
    }
    this.ErxProductName.value = null;
    this.ErxProductName.submitted = false;
  }


  removeProdName(prodName: string) {
    const temp = this.erxProdNames.filter(data => data !== prodName);
    this.erxProdNames = temp;
  }

  addMedicationStrength() {
    this.MedicationStrength.submitted = true;
    if (!this.MedicationStrength.value || this.MedicationStrength.value.trim() === '') {
      return;
    }
    if (!this.medicationStrengths.includes(this.MedicationStrength.value)) {
      this.medicationStrengths = [...this.medicationStrengths, this.MedicationStrength.value];
    }
    this.MedicationStrength.value = null;
    this.MedicationStrength.submitted = false;
  }


  removeMedicationStrength(medication: string) {
    const temp = this.medicationStrengths.filter(data => data !== medication);
    this.medicationStrengths = temp;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  get stk() {
    return this.globalProdcutForm.controls;
  }

  get phar() {
    return this.stockForm.controls;
  }

  deleteProduct(modal) {
    this.authService.deleteProduct(this.userData, this.selectedProduct).then((resp: any) => {
      this.toastr.clear();
      this.toastr.error('Product deleted successfully.', 'Message', {
        positionClass: 'toast-top-center'
      });
      this.currentPage();
      modal.dismiss('Cross-Click');
    });
  }


  uploadCSv(file: any) {
    this.file = file.target.files[0];
    this.authService.upload_csv(this.userData, this.file, this.platform).then((res: any) => {
      this.toastr.success('Product details uploaded successfully', 'Success', {
        positionClass: 'toast-top-center'
      });
      // this.loadProducts();
      this.modalService.dismissAll();
    }).catch(err => {
      this.toastr.error('Product details upload failed', 'Error', {
        positionClass: 'toast-top-center'
      });
    });
  }

  openImportModal(importcsv) {
    this.modalService.open(importcsv, {size: 'md'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  download() {
    const arr = [];
    let obj = {
      'BrandName': '',
      'ProductName': '',
      'price': '',
      'productSKU': '',
      'stockAvailable': '',
      'originalName': '',
      'repeats': '',
      'intervalPeriod': ''
    };
    let pharmacy = sessionStorage.getItem('pharmacyId');
    this.authService.fill_exel(this.userData, this.platform, pharmacy).then((resp: any) => {
      this.response = resp.result;
      this.response.filter((data: any) => {
        obj.BrandName = data.brandName;
        obj.ProductName = data.productName;
        obj.productSKU = data.productSKU;
        obj.stockAvailable = data.stockAvailable;
        obj.price = data.price;
        obj.originalName = data.originalName;
        obj.repeats = '';
        obj.intervalPeriod = '';
        arr.push(obj);
        obj = {
          'BrandName': '',
          'ProductName': '',
          'price': '',
          'productSKU': '',
          'stockAvailable': '',
          'originalName': '',
          'repeats': '',
          'intervalPeriod': ''
        };
      });
      this.jsonToCSV.downloadCsv(arr, 'SampleProducts.csv');
    })

  }


  Create_GlobalPharmacyProd(modal) {
    this.submitted = true;
    if (this.globalProdcutForm.invalid) {
      return;
    }
    const body = {
      brandName: this.globalProdcutForm.value.brandName.trim(),
      originalName: this.globalProdcutForm.value.originalName.trim(),
      originalCost: Number(this.globalProdcutForm.value.originalCost.trim()),
      platformId: this.platform._id,
      category: this.globalProdcutForm.value.category.trim(),
      description: this.globalProdcutForm.value.description || '',
      //price_per_mg: Number(this.globalProdcutForm.value.size_amount.trim()),
      //size_amount: Number(this.globalProdcutForm.value.size_amount.trim()),
      size_measure: this.globalProdcutForm.value.size_measure.trim().toLowerCase(),
      originalData: this.globalProdcutForm.value.originalData || '',
      TGAProductCategory: this.globalProdcutForm.value.TGAProductCategory || '',
      productType: this.globalProdcutForm.value.productType || '',
      aromas: this.globalProdcutForm.value.aromas || '',
      THCLabel: this.globalProdcutForm.value.THCLabel || '',
      CBDLabel: this.globalProdcutForm.value.CBDLabel || '',
      productSchedule: this.globalProdcutForm.value.productSchedule || '',
      productSpecies: this.globalProdcutForm.value.productSpecies || '',
      erxProductName: this.erxProdNames,
      medicationStrength: this.medicationStrengths,
      barCode: this.globalProdcutForm.value.barCode || '',
    }
    this.disableBtn = true;
    this.authService.create_global_prod(this.userData, body).then(async (resp: any) => {
      if (resp.status === 400) {
        this.toastr.clear();
        this.toastr.error(resp.error.hasOwnProperty('message') ? resp.error.message : resp.error.error, 'Error', {
          positionClass: 'toast-top-center'
        });
        this.disableBtn = false;
        this.showUpload = false;
        return;
      }
      if (resp.status === 500) {
        this.toastr.clear();
        this.toastr.error('Product creation failed.', 'Error', {
          positionClass: 'toast-top-center'
        });
        this.disableBtn = false;
        this.showUpload = false;
        return;
      }
      this.createdProd = resp.product_created;
      if (this.imageFile !== null) {
        this.spinner.show(undefined,
          {
            type: 'ball-triangle-path',
            size: 'medium',
            bdColor: 'rgba(0, 0, 0, 0.8)',
            color: '#fff',
            fullScreen: true
          });
        await this.authService.upload_product_image(this.userData, this.imageFile.target.files[0], this.createdProd).then((image_file: any) => {
          this.spinner.hide();
          modal.dismiss('Cross-Click');
        }).catch(err => {
          this.spinner.hide();
          this.toastr.clear();
          this.toastr.error(err.error.message, 'Error', {
            positionClass: 'toast-top-center'
          });
          modal.dismiss('Cross-Click');
        })
      }

      if (this.pdfFile.length > 0) {
        this.spinner.show(undefined,
          {
            type: 'ball-triangle-path',
            size: 'medium',
            bdColor: 'rgba(0, 0, 0, 0.8)',
            color: '#fff',
            fullScreen: true
          });
        await this.authService.uploadPdf(this.userData, this.pdfFile, this.createdProd).then((pdffiles: any) => {
          this.spinner.hide();
          this.loadGlobalProd();
          modal.dismiss('Cross-Click');
        }).catch(err => {
          this.spinner.hide();
          this.toastr.clear();
          this.toastr.error(err.error.message, 'Error', {
            positionClass: 'toast-top-center'
          });
          modal.dismiss('Cross-Click');
        })
      }
      this.toastr.clear();
      this.toastr.success('Product created Successfully', 'Success', {
        positionClass: 'toast-top-center'
      });
      modal.dismiss('Cross-Click');
      this.loadGlobalProd();
      this.showUpload = true;
      this.disableBtn = false;
    }).catch(err => {
      this.disableBtn = false;
      this.showUpload = false;
    });

  }

  splitText(text: string, charLimit: number): string[] {
    if (!text) {
      return [];
    }
    return text.match(new RegExp(`.{1,${charLimit}}`, 'g')) || [];
  }


  async uploadImage(file: any, fileType = 'image', uploadedFile: HTMLInputElement) {
    if (fileType === 'image') {
      if (file.target.files[0].type !== 'image/png' && file.target.files[0].type !== 'image/jpg' && file.target.files[0].type !== 'image/jpeg') {
        this.fileValidation.image = true;
        this.disableBtn = true;
        uploadedFile.value = '';
        return;
      }
      this.imageFile = file;
      this.file64.url = await this.fileToBase64(file.target.files[0]);
      this.file64.name = file.target.files[0].name
      this.fileValidation.image = false;
      this.disableBtn = false;
    } else {
      for (const fileData of file.target.files) {
        this.pdfFile.push(fileData);
      }
      for (const filedata of file.target.files) {
        if (filedata.type !== 'application/pdf') {
          this.images.push({
            url: await this.fileToBase64(filedata),
            name: filedata.name
          })
        } else {
          this.images.push({
            url: 'https://png.pngtree.com/png-clipart/20220612/original/pngtree-pdf-file-icon-png-png-image_7965915.png',
            name: filedata.name
          })
        }
      }
      // const checkValid = file.target.files.some(filedata => filedata.type !== 'application/pdf')
      // if (checkValid) {
      //   this.fileValidation.pdftye = true;
      //   this.disableBtn = true;
      //   uploadedFile.value = '';
      //   return;
      // }
      this.fileValidation.pdftye = false;
      this.disableBtn = false;
    }
  }

  async updateGlobalPrds(modal) {
    this.submitted = true;
    if (this.globalProdcutForm.invalid) {
      return;
    }

    const body = [];
    for (const key in this.globalProdcutForm.controls) {
      if ((key === 'originalCost' && this.globalProdcutForm.controls[key].dirty)) {
        body.push({propName: key, value: parseFloat(this.globalProdcutForm.controls[key].value)})
      } else if (this.globalProdcutForm.controls[key].dirty) {
        body.push({propName: key, value: this.globalProdcutForm.controls[key].value})
      }
    }

    if (JSON.stringify(this.erxProdNames) !== JSON.stringify(this.selectedProduct.erxProductName)) {
      body.push({
        propName: 'erxProductName',
        value: this.erxProdNames.length === 0 ? [] : this.erxProdNames
      });
    }

    if (JSON.stringify(this.medicationStrengths) !== JSON.stringify(this.selectedProduct.medicationStrength)) {
      body.push({
        propName: 'medicationStrength',
        value: this.medicationStrengths.length === 0 ? [] : this.medicationStrengths
      });
    }
    if (this.imageFile !== null) {
      this.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true,
        });
      this.disableBtn = true;
      await this.authService.upload_product_image(this.userData, this.imageFile.target.files[0], this.selectedProduct).then((image_file: any) => {
        this.spinner.hide();
        if (body.length === 0) {
          this.toastr.success('Product image updated', 'Success', {
            positionClass: 'toast-top-center'
          });
        }
        this.currentPage();
        this.disableBtn = false;
        modal.dismiss('Cross-Click');
      }).catch(err => {
        this.spinner.hide();
        this.toastr.error(err.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        this.disableBtn = false;
        modal.dismiss('Cross-Click');
      })
    }


    if (this.pdfFile.length > 0) {
      this.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      this.disableBtn = true;
      await this.authService.uploadPdf(this.userData, this.pdfFile, this.selectedProduct).then((pdffiles: any) => {
        this.spinner.hide();
        this.toastr.success('Pdf files uploaded', 'Success', {
          positionClass: 'toast-top-center'
        });
        this.currentPage();
        modal.dismiss('Cross-Click');
      }).catch(err => {
        this.disableBtn = false;
        this.spinner.hide();
        this.toastr.error(err.error.message, 'Error', {
          positionClass: 'toast-top-center'
        });
        modal.dismiss('Cross-Click');
        return;
      })
    }

    if (body.length > 0) {
      this.authService.update_global_products(this.userData, this.selectedProduct, body).then(async (resp) => {
        if (resp.status === 400) {
          this.toastr.clear();
          this.toastr.error(resp.error.message, 'Message', {
            positionClass: 'toast-top-center'
          });
          modal.dismiss('Cross-Click');
          return;
        }
        if (resp.hasOwnProperty('warning')) {
          if (resp.warning.fields.length > 0) {
            this.toastr.clear();
            this.toastr.warning(resp.warning.message + '\n' + resp.warning.fields.join(' '), 'Message', {
              positionClass: 'toast-top-center'
            });
          }
          modal.dismiss('Cross-Click');
          return;
        }
        this.showUpload = true;
        this.currentPage();
        this.images = [];
        this.pdfFile = [];
        this.toastr.clear();
        this.toastr.success('Product details updated', 'Success', {
          positionClass: 'toast-top-center'
        });
        modal.dismiss('Cross-Click');
      }).catch(err => {
        this.toastr.clear();
        this.toastr.error('Product details updation failed', 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      })
    }
  }

  fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      // Define onload event handler
      reader.onload = () => {
        const result = reader.result;
        if (typeof result === 'string') {
          resolve(result);
        } else {
          reject(new Error('Failed to convert file to base64'));
        }
      };

      // Define onerror event handler
      reader.onerror = (error) => {
        reject(error);
      };

      // Read file as data URL
      reader.readAsDataURL(file);
    });
  }

  OnSubmit() {
    this.submitted = true;
    if (this.stockForm.invalid) {
      return;
    }

    const check = this.productList.some((prod) => {
      if (prod.productName === this.stockForm.value.productName) {
        return true;
      } else {
        return false;
      }
    });

    if (check) {
      this.toastr.error('Product already exists.', 'Error', {
        positionClass: 'toast-top-center'
      });
      this.modalService.dismissAll();
      this.stockForm.reset();
      this.submitted = false;
      return;
    }

    const body = {
      'productSKU': this.stockForm.value.productSKU || '',
      'stock': this.stockForm.value.stock,
      'cost': parseFloat(this.stockForm.value.cost),
      'productName': this.stockForm.value.productName || '',
      'isDiscontinued': false
    }
    this.authService.createProduct(this.userData, body, this.selectedProduct).then((resp: any) => {
      this.toastr.success('Product added successfully', 'Success', {
        positionClass: 'toast-top-center'
      });
      this.submitted = false;
      this.modalService.dismissAll();
      this.router.navigate(['/list']);

    }).catch(err => {
    });
  }


  pagination(number: number) {
    this.pageTracker = number;
    if (this.categoryName !== '') {
      this.authService.fetch_global_prods(this.userData, this.platform, this.pageTracker, this.limitRecords, this.categoryName).then((resp: any) => {
        this.productList = resp.results.filter(prod => {
          return !prod.deleted;
        });
        this.maxPageSize = resp.totalPages;
      }).catch(err => {
      });
    } else {
      if (this.searchBy === 'search') {
        this.authService.search_global_prd_Name(this.userData, this.prodcutName, this.pageTracker, this.limitRecords).then((resp: any) => {
          if (resp.status === 404) {
            this.toastr.clear();
            this.toastr.error('Product not found', 'Error', {
              positionClass: 'toast-top-center'
            });
            return;
          }
          this.productList = resp.results.filter(prod => {
            return !prod.deleted;
          });
          this.maxPageSize = resp.totalPages;
        })
      } else {
        this.authService.fetch_global_prods(this.userData, this.platform, this.pageTracker, this.limitRecords).then((resp: any) => {
          this.productList = resp.results.filter(prod => {
            return !prod.deleted;
          });
          this.maxPageSize = resp.totalPages;
        }).catch(err => {
        });
      }
    }
  }


  currentPage() {
    this.authService.fetch_global_prods(this.userData, this.platform, this.pageTracker, this.limitRecords).then((resp: any) => {
      this.productList = resp.results.filter(prod => {
        return !prod.deleted;
      });
    }).catch(err => {
    });
  }

  filterUpdate(prdName: string) {
    this.flag = 0;
    this.searchBy = 'search';
    this.prodcutName = prdName.trim();
    if (prdName.trim() === '') {
      this.toastr.clear();
      this.toastr.error('Enter Product Name.', 'Info', {
        positionClass: 'toast-top-center'
      });
      return;
    }
    this.authService.search_global_prd_Name(this.userData, prdName.trim(), 1).then((resp: any) => {
      if (resp.status === 404) {
        this.toastr.clear();
        this.toastr.error('Product not found', 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      if (resp.status === 400) {
        this.toastr.clear();
        this.toastr.error(resp.error.hasOwnProperty('message') ? resp.error.message : resp.error.error, 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      this.productList = resp.results.filter(prod => {
        return !prod.deleted;
      });
      if (this.productList.length === 0) {
        this.toastr.clear();
        this.toastr.error('Product not found', 'Error', {
          positionClass: 'toast-top-center'
        });
        return;
      }
      this.maxPageSize = resp.totalPages;
    });
  }

  OnChangeLimit(event) {
    this.searchBy = 'pagination';
    this.limit = 0;
    this.pageTracker = 1;
    this.limitRecords = Number(event.target.value);
    if (this.categoryName === '') {
      this.authService.fetch_global_prods(this.userData, this.platform, this.pageTracker, this.limitRecords).then((resp: any) => {
        this.productList = resp.results.filter(prod => {
          return !prod.deleted;
        });
      }).catch(err => {
      });
    } else {
      this.authService.fetch_global_prods(this.userData, this.platform, this.pageTracker, this.limitRecords, this.categoryName).then((resp: any) => {
        this.productList = resp.results.filter(prod => {
          return !prod.deleted;
        });
      }).catch(err => {
      });
    }

  }


  OnChangeCategory(category) {
    this.pageTracker = 1;
    this.categoryName = category.target.value;
    if (this.categoryName !== 'All') {
      this.authService.fetch_global_prods(this.userData, this.platform, this.pageTracker, this.limitRecords, this.categoryName).then((resp: any) => {
        if (resp.length === 0) {
          this.productList = [];
          this.maxPageSize = 0;
          return;
        }
        this.productList = resp.results.filter(prod => {
          return !prod.deleted;
        });
        this.maxPageSize = resp.totalPages;
      }).catch(err => {
        this.productList = [];
      });
    } else {
      this.categoryName = '';
      this.authService.fetch_global_prods(this.userData, this.platform, this.pageTracker, this.limitRecords).then((resp: any) => {
        this.productList = resp.results.filter(prod => {
          return !prod.deleted;
        });
        this.maxPageSize = resp.totalPages;
      }).catch(err => {
        this.productList = [];
      });
    }

  }


  clearText() {
    this.flag = this.flag + 1;
    if (this.flag === 1) {
      this.loadGlobalProd();
      this.searchBy = 'pagination';
    }
  }

  remove(type = 'image', item = {url: '', name: ''}, pdf) {
    if (type === 'image') {
      this.imageFile = null;
      this.file64 = {
        url: '', name: ''
      }
      pdf.value = ''
    } else {
      const inputFiles = this.pdfFile.filter(fildata => fildata.name !== item.name);
      this.pdfFile = inputFiles;
      const temp = this.images.filter(data => data.name !== item.name);
      this.images = temp;
      pdf.value = ''
    }

  }

  deleteGlobalProdImage() {
    this.authService.update_global_products(this.userData, this.selectedProduct, [{
      propName: 'productImage', value: ''
    }]).then(async (resp) => {
      await this.authService.fetch_particularglobalProdcut(this.userData, this.selectedProduct._id, this.platform).then((response: any) => {
        this.selectedProduct = response[0];
        this.currentPage();
      });
    }).catch(err => {
      this.toastr.error('Product details updation failed', 'Error', {
        positionClass: 'toast-top-center'
      });
      return;
    });
  }
}
