<section id="shopping-cart">
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Messages</h4>
      <div class="row mt-1">
        <div class="col-md-4 col-sm-12 col-lg-3 col-xl-3 col-12">
          <select class="form-control square size" (change)="SelectPharmacy($event)">
            <option selected disabled>Select Pharmacy</option>
            <option *ngFor="let pharmacy of pharmacyList"
                    value="{{pharmacy._id}}">{{ pharmacy.pharmacy_name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-3">
          <div class="card shadow-lg" *ngFor="let note of notes">
            <div class="card-header font-weight-bold text-success">{{ note.document_note }}</div>
            <div class="card-body">
              <p class="text-right">{{ note.convertedTimeStamp }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
