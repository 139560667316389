<!--Statistics cards Starts-->

  <div class="row" (resized)="onResized($event)" *ngIf="result=== 'unselect'">
    <div class="col-xl-3 col-lg-6 col-md-6 col-12">
      <div class="card card-size gradient-purple-love">
        <div class="card-content">
          <div class="card-body py-0">
            <div class="media pb-1">
              <div class="media-body white text-left">
                <h3 class="font-large-1 white mb-0">{{this.todays === 0 ? 0 : this.todays}}</h3>
                <span> Total <select  class="form-control data-input px-0 py-0 white"
                                     (change)="onChange($event,'today')">
                <option class="text-dark" *ngFor="let ordStatus of orderStatusArr"
                        [value]="ordStatus.id">{{ordStatus.title}}</option>
              </select> orders for Today</span>
              </div>
              <div class="media-right white text-right">
                <i class="ft-calendar font-large-1"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-md-6 col-12">
      <div class="card card-size gradient-ibiza-sunset">
        <div class="card-content">
          <div class="card-body py-0">
            <div class="media pb-1">
              <div class="media-body white text-left">
                <h3 class="font-large-1 white mb-0">{{this.yesterDay}}</h3>
                <span> Total <select class="form-control white data-input px-0 py-0"
                                     (change)="onChange($event,'yesterday')">
                <option class="text-dark" *ngFor="let ordStatus of orderStatusArr"
                        [value]="ordStatus.id">{{ordStatus.title}}</option>
              </select> orders for Yesterday</span>
              </div>
              <div class="media-right white text-right">
                <i class="ft-calendar font-large-1"></i>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-3 col-lg-6 col-md-6 col-12">
      <div class="card card-size gradient-mint">
        <div class="card-content">
          <div class="card-body py-0">
            <div class="media pb-1">
              <div class="media-body white text-left">
                <h3 class="font-large-1 white mb-0">{{this.this_week}}</h3>
                <span> Total <select class="form-control data-input px-0 white py-0"
                                     (change)="onChange($event,'this week')">
                <option class="text-dark" *ngFor="let ordStatus of orderStatusArr"
                        [value]="ordStatus.id">{{ordStatus.title}}</option>
              </select> orders for This week</span>
              </div>
              <div class="media-right white text-right">
                <i class="ft-calendar font-large-1"></i>
              </div>
            </div>

          </div>
          <div id="Widget-line-chart" class=" WidgetlineChart WidgetlineChartshadow mb-2">
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-md-6 col-12">
      <div class="card card-size gradient-king-yna">
        <div class="card-content">
          <div class="card-body py-0">
            <div class="media pb-1">
              <div class="media-body white text-left">
                <h3 class="font-large-1 white mb-0">{{this.month_toDate}}</h3>
                <span> Total <select class="form-control data-input white px-0 py-0"
                                     (change)="onChange($event,'month_toDate')">
                <option class="text-dark" *ngFor="let ordStatus of orderStatusArr"
                        [value]="ordStatus.id">{{ordStatus.title}}</option>
              </select>orders for Month to Date</span>
              </div>
              <div class="media-right white text-right">
                <i class="ft-calendar font-large-1"></i>
              </div>
            </div>

          </div>
          <div id="Widget-line-chart" class=" WidgetlineChart WidgetlineChartshadow mb-2">
          </div>
        </div>
      </div>
    </div>
  </div>

<div class="row" (resized)="onResized($event)" *ngIf="result === 'select'">
  <div class="col-xl-3 col-lg-6 col-md-6 col-12" *ngIf="userData.auth.data.role === 'Super Admin'">
    <div class="card card-size gradient-purple-love">
      <div class="card-content">
        <div class="card-body py-0">
          <div class="media pb-1">
            <div class="media-body white text-left">
              <h3 class="font-large-1 white mb-0">{{this.superAdminUpdates?.usersCount}}</h3>
              <span> Users</span>
            </div>
            <div class="media-right white text-right">
              <i class="ft-users font-large-1"></i>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-lg-6 col-md-6 col-12" *ngIf="userData.auth.data.role === 'Super Admin'">
    <div class="card card-size gradient-ibiza-sunset">
      <div class="card-content">
        <div class="card-body py-0">
          <div class="media pb-1">
            <div class="media-body white text-left">
              <h3 class="font-large-1 white mb-0">{{this.superAdminUpdates?.pharmacyCount}}</h3>
              <span>Pharmacies</span>
            </div>
            <div class="media-right white text-right">
              <i class="ft-home font-large-1"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-3 col-lg-6 col-md-6 col-12" *ngIf="userData.auth.data.role === 'Super Admin'">
    <div class="card card-size gradient-mint">
      <div class="card-content">
        <div class="card-body py-0">
          <div class="media pb-1">
            <div class="media-body white text-left">
              <h3 class="font-large-1 white mb-0">{{this.superAdminUpdates?.productCount}}</h3>
              <span>Products</span>
            </div>
            <div class="media-right white text-right">
              <i class="ft-box font-large-1"></i>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-lg-6 col-md-6 col-12" *ngIf="userData.auth.data.role === 'Super Admin'">
    <div class="card card-size gradient-king-yna">
      <div class="card-content">
        <div class="card-body py-0">
          <div class="media pb-1">
            <div class="media-body white text-left">
              <h3 class="font-large-1 white mb-0">{{this.superAdminUpdates?.totalOrderCounts}}</h3>
              <span> Total orders</span>
            </div>
            <div class="media-right white text-right">
              <i class="ft-calendar font-large-1"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-lg-6 col-md-6 col-12" *ngIf="userData.auth.data.role === 'Super Admin'">
    <div class="card card-size gradient-king-yna">
      <div class="card-content">
        <div class="card-body py-0">
          <div class="media pb-1">
            <div class="media-body white text-left">
              <h3 class="font-large-1 white mb-0">{{this.superAdminUpdates?.totalRevenue}}</h3>
              <span>Total Revenue</span>
            </div>
            <div class="media-right white text-right">
              <i class="ft-dollar-sign font-large-1"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Statistics cards Ends-->

<!--Line with Area Chart 1 Starts-->
<!--<div class="row">-->
<!--  <div class="col-12">-->
<!--    <div class="card">-->
<!--      <div class="card-header">-->
<!--        <h4 class="card-title">PRODUCTS SALES</h4>-->
<!--      </div>-->
<!--      <div class="card-content">-->
<!--        <div class="card-body">-->
<!--          <div class="chart-info mb-3 ml-3">-->
<!--            <span class="gradient-purple-bliss d-inline-block rounded-circle mr-1"-->
<!--                  style="width:15px; height:15px;"></span>-->
<!--            <span>Sales</span>-->
<!--            <span class="gradient-mint d-inline-block rounded-circle mr-1 ml-2" style="width:15px; height:15px;"></span>-->
<!--            <span>Visits</span>-->
<!--          </div>-->
<!--          <div id="line-area" class="lineArea">-->
<!--            <x-chartist class="height-350" [data]="lineArea.data" [type]="lineArea.type" [options]="lineArea.options"-->
<!--                        [responsiveOptions]="lineArea.responsiveOptions" [events]="lineArea.events">-->
<!--            </x-chartist>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<!--Line with Area Chart 1 Ends-->

<!--<div class="row match-height">-->
<!--  <div class="col-xl-8 col-lg-12 col-12">-->
<!--    <div class="card">-->
<!--      <div class="card-header">-->
<!--        <h4 class="card-title mb-0">Visit & Sales Statistics</h4>-->
<!--      </div>-->
<!--      <div class="card-content">-->
<!--        <div class="card-body">-->
<!--          <div class="chart-info mb-2">-->
<!--            <span class="text-uppercase mr-3"><i class="fa fa-circle success font-small-2 mr-1"></i> Sales</span>-->
<!--            <span class="text-uppercase"><i class="fa fa-circle info font-small-2 mr-1"></i> Visits</span>-->
<!--          </div>-->
<!--          <div id="line-area2" class="lineArea2">-->
<!--            <x-chartist class="height-400" [data]="lineArea2.data" [type]="lineArea2.type" [options]="lineArea2.options"-->
<!--                        [responsiveOptions]="lineArea2.responsiveOptions" [events]="lineArea2.events">-->
<!--            </x-chartist>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="col-xl-4 col-lg-12 col-12">-->
<!--    <div class="card gradient-purple-bliss">-->
<!--      <div class="card-content">-->
<!--        <div class="card-body">-->
<!--          <h4 class="card-title white">Statistics</h4>-->
<!--          <div class="p-2 text-center">-->
<!--            <a class="white font-medium-1">Month</a>-->
<!--            <a class="btn round bg-light-info mx-3 px-3">Week</a>-->
<!--            <a class="white font-medium-1">Day</a>-->
<!--          </div>-->
<!--          <div class="my-3 text-center white">-->
<!--            <div class="font-large-2 d-block mb-1">-->
<!--              <span>$78.89</span>-->
<!--              <i class="ft-arrow-up font-large-2"></i>-->
<!--            </div>-->
<!--            <span class="font-medium-1">Week2 +15.44</span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div id="lineChart" class="lineChart lineChartShadow">-->
<!--          <x-chartist class="height-250" [data]="lineChart.data" [type]="lineChart.type" [options]="lineChart.options"-->
<!--                      [responsiveOptions]="lineChart.responsiveOptions" [events]="lineChart.events">-->
<!--          </x-chartist>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
